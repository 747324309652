
import { FC, useEffect } from 'react';
import { AlertColor, Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes as Switch, useNavigate } from 'react-router-dom';
import Notification from '../components/Notification';
import { routes } from '../utils/constants/routes';
import {
  NotificationDisplay,
  NotificationMessage,
  NotificationSeverity,
  setNotificationMessage,
} from '../utils/redux/reducer/Notification-details-slice';
import { Authenticated } from '../utils/redux';
import HomePage from '../screens/HomePage';
import OrganisationDetails from '../screens/OrganisationDetails';
import NotFound from '../screens/NotFound';
import { setOpenAuthenticationDrawer } from '../utils/redux/reducer/authentication-slice';
import UserProfile from '../screens/AuthenticatedScreens/UserProfile';
import OrderSummary from 'screens/OrderSummary';
import PaymentSummary from 'screens/AuthenticatedScreens/PaymentSummary';
import { clearStorage, fetchFromStorage } from 'utils/storage';
import { storageSchema } from 'utils/constants/storageSchem';
import Ajv from "ajv"
import NotAuthenticatedPage from 'screens/NotAuthenticatedPage';
import Header from './AuthenticatedLayout';
const ajv = new Ajv()

const AuthenticatedScreens: FC<{ Component: FC }> = ({ Component }) => {
  const dispatch = useDispatch();
  const isLogin = useSelector(Authenticated);
  !isLogin && dispatch(setOpenAuthenticationDrawer(true))
  return isLogin ? <Component /> : <NotAuthenticatedPage />
}

const Routes: FC = () => {
  const dispatch = useDispatch();
  const notificationDisplay = useSelector(NotificationDisplay);
  const notificationSeverity = useSelector(NotificationSeverity);
  const notificationMessage = useSelector(NotificationMessage);

  const navigate = useNavigate();

  useEffect(() => {
    const validate = ajv.compile(storageSchema);
    const data = {
      access_token: fetchFromStorage('access_token'),
      privilegeList: JSON.parse(fetchFromStorage('privilegeList') as string),
      refresh_token: fetchFromStorage('refresh_token'),
      preview: fetchFromStorage('preview'),
      userDetails: JSON.parse(fetchFromStorage('userDetails') as string),
      languages: JSON.parse(fetchFromStorage('languages') as string),
      theme: JSON.parse(fetchFromStorage('theme') as string),
      selectedLanguage: JSON.parse(fetchFromStorage('selectedLanguage') as string),
      initialData: JSON.parse(fetchFromStorage('initialData') as string),
      selectedVenuesForCosting: JSON.parse(fetchFromStorage('selectedVenuesForCosting', true) as string),
      selectedRoomsForCosting: JSON.parse(fetchFromStorage('selectedRoomsForCosting', true) as string),
      searchedData: JSON.parse(fetchFromStorage('searchedData', true) as string),
      searchFormData: JSON.parse(fetchFromStorage('searchFormData', true) as string),
      venueList: JSON.parse(fetchFromStorage('venueList', true) as string),
      selectedOrg: fetchFromStorage('selectedOrg', true),
      eventTypeList: JSON.parse(fetchFromStorage('eventTypeList', true) as string),
      timeSlotList: JSON.parse(fetchFromStorage('timeSlotList', true) as string),
    }
    if (!(validate(data))) {
      console.log("#err2: ", validate.errors)
      clearStorage("all");
      navigate('/')
    } else {
      console.log();
    }
  }, []);

  return (
    <Grid item container flexDirection="column">
      <Grid className="pageWrapper" item sx={{ width: '100%', height: '100vh' }}>
        <Paper elevation={0} square={false} className="paperWrapper">
          <Notification
            isOpen={notificationDisplay}
            message={notificationMessage}
            severity={notificationSeverity as AlertColor}
            autoHideDuration={6000}
            closeNotification={value =>
              dispatch(
                setNotificationMessage({
                  display: value,
                  severity: notificationSeverity,
                  message: notificationMessage,
                }),
              )
            }
          />
          <Header>
            <Switch>
              <Route path={routes.root} element={<HomePage />} />
              <Route path={routes.available_venues} element={<OrganisationDetails />} />
              <Route path={routes.order_summary} element={<OrderSummary />} />
              <Route path={routes.user_profile} element={<AuthenticatedScreens Component={UserProfile} />} />
              <Route path={routes.payment_summary} element={<AuthenticatedScreens Component={PaymentSummary} />} />
              <Route
                path={`${routes.payment_summary}/:id`}
                element={<AuthenticatedScreens Component={PaymentSummary} />}
              />
              <Route path="*" element={<NotFound />} />
            </Switch>
          </Header>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Routes;
