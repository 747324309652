import React from "react";
import ErrorIcon from '@mui/icons-material/Error';
import { Link, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import strings from "../../common/Translation/Translate";

const NotFound = () => {
    // useEffect(() => ScrollTop(0),[])
    const theme = useTheme();
    const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    return(
    <Box 
        height={'70vh'} 
        display={"flex"} 
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={1}
        // position={'sticky'}
    >
        <ErrorIcon color={'error'} sx={{ fontSize: screenSizeDownSm ? '4em' : '5em' }}/>
        <Typography variant={screenSizeDownSm ? "h5" : "h4"} fontWeight={'bold'}>{strings.somethingWentWrongText}</Typography>
        <Link href="/" underline="hover">
            <Typography fontStyle={screenSizeDownSm ? "14px" : "20px"} fontWeight={500}>{strings.returnToHomePageText}</Typography>
        </Link>     
    </Box>
);
}
export default NotFound;
