import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getBaseUrl } from 'utils/validators/HelperFunctions';

type RoomLayoutCardProps = {
  layoutItem: any;
  selectedItem: any;
  handleClick: (id: any) => void;
};
export const RoomLayoutCard = ({ layoutItem, selectedItem, handleClick }: RoomLayoutCardProps) => {
  const theme = useTheme();
  const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      {/* <Card
                sx={{ p: 1, minHeight: '90px', '&:hover': { cursor: 'pointer' } }}
                onClick={() => handleClick(layoutItem.id)}
                elevation={4}>
                <Grid container px={1} mb={1}
                    // alignItems='center'
                    justifyContent={'space-between'}
                    color={selectedItem === layoutItem.id ? `${theme.palette.primary.main}` : 'gray'}
                >
                    <Grid itemScope>
                        <Typography fontSize={'0.75rem'} fontWeight={700}>
                            {layoutItem.name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CheckCircleIcon fontSize='small'/>
                    </Grid>
                </Grid>
                <Grid container px={1} alignItems='center' gap={1}>
                    <Grid item>
                        <img src={getBaseUrl() + layoutItem.imgUrl} alt='alt'  height={'30vh'} 
                    width={screenSizeUpMd ? '50vw': '60vw'}/>
                    </Grid>
                    <Grid item>
                        <Typography fontSize={'0.65rem'} fontWeight={500}>
                           {layoutItem.maxCapacity}
                        </Typography>
                    </Grid>
                </Grid>
            </Card> */}
      <Card
        sx={{ p: 1, pl: 2, height: '70px', '&:hover': { cursor: 'pointer' } }}
        onClick={() => handleClick(layoutItem.id)}
        elevation={4}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6} lg={7} color={selectedItem === layoutItem.id ? `${theme.palette.primary.main}` : 'gray'}>
            <CheckCircleIcon fontSize="small" sx={{ position: 'absolute' }} />
            <Typography fontSize={'0.75rem'} fontWeight={700}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{layoutItem.name}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={5}>
            <Grid container alignItems="center" spacing={1} justifyContent={'end'}>
              <Grid item>
                <img
                  src={getBaseUrl() + layoutItem.imgUrl}
                  alt=""
                  height={'45vh'}
                  width={screenSizeUpMd ? '50vw' : '60vw'}
                />
              </Grid>
              <Grid item>
                <Typography fontSize={'0.65rem'} fontWeight={500}>
                  {layoutItem.maxCapacity}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
