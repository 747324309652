import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  AlertColor,
  Breadcrumbs,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import Notification from 'components/Notification';
import PageLoader from 'components/PageLoader';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AddEditBookingRooms } from 'screens/OrderSummary/AddEditBookingRooms/AddEditBookingRooms';
import { VenueDescription } from 'screens/OrganisationDetails/VenueDetails';
import { routes } from 'utils/constants/routes';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/redux/axios';
import { setCostingSliceInitState } from 'utils/redux/reducer/costing-slice';
import { setSearchDataSliceInitState } from 'utils/redux/reducer/searched-data-slice';
import { fetchFromStorage } from 'utils/storage';
import { getBaseUrl } from 'utils/validators/HelperFunctions';
import AirPayForm from './AirPayForm';
import CcAvenueForm from './CCAvenue';
import Razorpay from './RazorPay';

const PaymentGateway = ({
  setSuccessModalStatus,
  provider,
  termsAgreed,
  summaryResponse,
  razorPayCallback
}: any) => {
  setSuccessModalStatus('PAYMENT');
  switch (provider) {
    case 'AIRPAY':
      return <AirPayForm isChecked={termsAgreed} responseValue={summaryResponse} />;
    case 'CCAVENUE':
      return <CcAvenueForm isChecked={termsAgreed} responseValue={summaryResponse} />;
    case 'HDFC':
      return <CcAvenueForm isChecked={termsAgreed} responseValue={summaryResponse} />;
    case 'RAZORPAY':
      return (
        <Razorpay
          isChecked={termsAgreed}
          responseValue={
            summaryResponse.paymentGatewayInfo.json && JSON.parse(summaryResponse.paymentGatewayInfo.json)
          }
          callback={razorPayCallback}
        />
      );
    default:
      return (
        <>
          <PrimaryButton onClick={() => { }} disabled={!termsAgreed}>
            {strings.makePayment}
          </PrimaryButton>
        </>
      );
  }
};

const PaymentSummary = () => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [queryStringInfo, setQueryStringInfo] = useState<any>({});
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [successModalStatus, setSuccessModalStatus] = useState<string>('');
  const [costingResponse, setCostingResponse] = useState<any>({});
  const [summaryResponse, setSummaryResponse] = useState<any>({});
  const [address, setAddress] = useState<string>('');
  const [paymentFlag, setPaymentFlag] = useState<any>(null);
  const [paymentId, setPaymentId] = useState<string>('');
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [isPayment, setPayment] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const provider = summaryResponse.paymentGatewayInfo && summaryResponse.paymentGatewayInfo.paymentProvider;

  useEffect(() => {
    const values = queryString.parse(location.search);
    console.log('location: ', location);
    console.log('values: ', values);
    getPaymentData(values);
    setQueryStringInfo(values);
  }, []);


  const getPaymentData = (paymentStatus: any) => {
    if (paymentStatus.r === 'f') {
      setPaymentFlag(false);
    } else if (paymentStatus.r === 's') {
      setPaymentFlag(true);
      dispatch(setSearchDataSliceInitState({}));
      dispatch(setCostingSliceInitState({}));
      setPayment(true);
    } else {
      setPaymentFlag(null);
    }
  };

  const getAddress = (data: any) => {
    const add = [];
    data.city && add.push(data.city);
    data.state && add.push(data.state);
    data.country && add.push(data.country);
    data.pinCode && add.push(data.pinCode);
    return add.join(', ');
  };

  const getInitialData = useCallback(async () => {
    try {
      setLoading(true);
      let constingRes, summaryRes;
      if (id) {
        constingRes = await axiosInstance.get(URLS.getCostingDetailsById(id));
        summaryRes = await axiosInstance.get(URLS.getPaymentSummaryById(id));
      } else {
        const selectedVenuesFromStorage = JSON.parse(fetchFromStorage('selectedVenuesForCosting', true) as string);
        const selectedRooms = JSON.parse(fetchFromStorage('selectedRoomsForCosting', true) as string);
        const searchData = JSON.parse(fetchFromStorage('searchFormData', true) as string);
        const selectedOrgId = JSON.parse(fetchFromStorage('selectedOrg', true) as string);
        if (!(selectedVenuesFromStorage && selectedOrgId && searchData)) {
          navigate(routes.root);
        }
        const requestBody = {
          orgId: selectedOrgId,
          eventType: searchData.eventType,
          bookingStartDate: dayjs(searchData.startDate as string).format('DD-MM-YYYY'),
          bookingEndDate: dayjs(searchData.endDate as string).format('DD-MM-YYYY'),
          guests: searchData.guests,
          rooms: selectedRooms,
          venues: selectedVenuesFromStorage
            .map((venue: any) =>
              venue.venues.map((item: any) => ({
                id: item.id,
                bookingDate: item.bookingDate,
                layout: item.layout,
                guaranteedGuests: item.guaranteedGuests,
                addOns: item.addOns,
                timeSlot: searchData.timeSlot,
                pricePackageId: item.pricePackageId,
              })),
            )
            .flat(),
        };
        constingRes = await axiosInstance.post(URLS.getCostingDetails(selectedOrgId), requestBody);
        summaryRes = await axiosInstance.post(URLS.getPaymentSummary, requestBody);
      }
      setCostingResponse(constingRes.data);
      setSummaryResponse(summaryRes.data);
      summaryRes.data && setAddress(getAddress(summaryRes.data));
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response?.data?.error_description
          ? error.response.data.error_description
          : error.response.data.message,
      });
    }
  }, []);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const getSuccessMessage = (statusVal: string) => {
    switch (statusVal) {
      case 'CONFIRMED':
        return strings.confirmBookingSuccessMessage;
      case 'TENTATIVE':
        return strings.tentativeSuccessMessage;
      case 'WAITLISTED':
        return strings.waitlistedSuccessMessage;
    }
  };

  const completeBooking = async (id: any, statusVal: string) => {
    try {
      setLoading(true);
      const statusValue = {
        status: statusVal,
      };
      await axiosInstance.put(URLS.completeBooking(id), statusValue);
      setLoading(false);
      setPayment(true);
      setOpenSuccessModal(true);
      setSuccessModalStatus(statusVal);
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.message,
      });
    }
  };

  const handleSuccessModalClose = () => {
    setOpenSuccessModal(false);
    setSuccessModalStatus('');
    dispatch(setSearchDataSliceInitState({}));
    dispatch(setCostingSliceInitState({}));
    navigate('/');
  };

  const breadcrumbs = [
    <Link key="1" href="/">
      <HomeRoundedIcon color="action" />
    </Link>,
    <Box key="2" sx={{ p: 1, cursor: 'pointer' }} onClick={() => navigate(routes.available_venues)}>
      <Typography fontWeight="400" sx={{ [theme.breakpoints.down('sm')]: { fontSize: '11px' } }}>
        {' '}
        {strings.breadcrumb_text_venueList}{' '}
      </Typography>
    </Box>,
    <Box key="3" sx={{ p: 1, cursor: 'pointer' }} onClick={() => navigate(routes.order_summary)}>
      <Typography fontWeight="400" sx={{ [theme.breakpoints.down('sm')]: { fontSize: '11px' } }}>
        {' '}
        {strings.breadcrumb_text_orderSummary}
      </Typography>
    </Box>,
    <Box key="4">
      <Typography fontWeight="bold" sx={{ [theme.breakpoints.down('sm')]: { fontSize: '11px' } }} color={'primary'}>
        {' '}
        {strings.breadcrumb_text_payment}
      </Typography>
    </Box>,
  ];

  const getWidth = () => {
    if (screenSizeDownMd) return '70vw'
    else if (screenSizeDownSm) return '90vw'
    else return '40vw'
  }

  const razorPayCallback = (flag: any, paymentId: any) => {
    setPaymentId(paymentId);
    setOpenSuccessModal(true);
  };

  return (
    <>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      {Object.keys(summaryResponse).length > 0 ? (
        <>
          <Box
            sx={{
              paddingLeft: '4%',
              paddingRight: '3%',
              position: 'relative',
            }}>
            {(paymentFlag || id) && (
              <Box sx={{ mb: 1.5, mt: 3 }}>
                <Link key="1" href="/">
                  <HomeRoundedIcon color="action" fontSize="large" />
                </Link>
              </Box>
            )}
            {!paymentFlag && !id && (
              <Breadcrumbs
                sx={{ mb: 1.5, mt: 3 }}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            )}
            <Grid container spacing={4}>
              <Grid item xs={12} lg={8.5}>
                {paymentFlag && (
                  <Box
                    display="flex"
                    alignItems={'center'}
                    sx={{
                      backgroundColor: 'rgba(0, 128, 0, 0.2)',
                      padding: 2,
                      marginY: 1,
                      border: '1px solid #008000',
                      borderRadius: '3px',
                    }}>
                    <CheckCircleIcon sx={{ color: '#008000', fontSize: '40px', marginRight: 2 }} />
                    <Box display="flex" flexDirection={'column'} gap={1}>
                      <Typography variant="h5" fontWeight={500}>
                        {strings.paymentSuccessMessage}
                      </Typography>
                      <Typography>
                        {strings.orderIdText}
                        <b>{queryStringInfo.oId}</b>
                      </Typography>
                      <Typography>
                        {strings.transactionIdText}
                        <b>{queryStringInfo.apTxId}</b>
                      </Typography>
                    </Box>
                  </Box>
                )}
                {paymentFlag !== null && !paymentFlag && (
                  <Box
                    display="flex"
                    alignItems={'center'}
                    sx={{
                      backgroundColor: 'rgba(255, 0, 0, 0.2)',
                      padding: 2,
                      marginY: 1,
                      border: '1px solid #AA0000',
                      borderRadius: '3px',
                    }}>
                    <InfoIcon sx={{ color: '#AA0000', fontSize: '40px', marginRight: 2 }} />
                    <Box display="flex" flexDirection={'column'} gap={1}>
                      <Typography variant="h5" fontWeight={500}>
                        {strings.paymentFailedMessage}
                      </Typography>
                      <Typography>
                        {strings.orderIdText}
                        <b>{queryStringInfo.oId}</b>
                      </Typography>
                      <Typography>
                        {strings.transactionIdText}
                        <b>{queryStringInfo.apTxId || '-'} </b>
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: theme.palette.secondary.light,
                  }}>
                  <Typography sx={{ mb: 1 }} fontWeight={500} variant={'h6'}>
                    {summaryResponse.customerFirstName}&nbsp;{summaryResponse.customerLastName}
                  </Typography>
                  <Grid container alignItems={'center'} gap={2}>
                    {(summaryResponse.city ||
                      summaryResponse.state ||
                      summaryResponse.country ||
                      summaryResponse.pinCode) && (
                        <Typography display="flex" alignItems={'center'} fontSize={'0.75rem'}>
                          <LocationOnOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />
                          {address}
                        </Typography>
                      )}
                    {summaryResponse.email && (
                      <Typography display="flex" alignItems={'center'} fontSize={'0.75rem'}>
                        <EmailOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />
                        {summaryResponse.email}
                      </Typography>
                    )}
                    {summaryResponse.phoneNumber && (
                      <Typography display="flex" alignItems={'center'} fontSize={'0.75rem'}>
                        <LocalPhoneOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />
                        {summaryResponse.phoneNumber}
                      </Typography>
                    )}
                    {summaryResponse.eventTypeName && (
                      <Typography display="flex" alignItems={'center'} fontSize={'0.75rem'}>
                        <EventAvailableOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />
                        {summaryResponse.eventTypeName}
                      </Typography>
                    )}
                  </Grid>
                </Box>
                {!isPayment && (
                  <>
                    {queryStringInfo.r !== 's' && summaryResponse.bookingText && (
                      <Box
                        sx={{
                          my: 2,
                          py: 0.5,
                          px: 2,
                          backgroundColor: theme.palette.secondary.light,
                          border: `1px solid ${theme.palette.secondary.main}`,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}>
                        <InfoOutlinedIcon fontSize="small" />
                        <Typography
                          dangerouslySetInnerHTML={{ __html: summaryResponse.bookingText }}
                          fontSize={'0.75rem'}></Typography>
                      </Box>
                    )}
                    {!window.location.hostname.startsWith('preview') &&
                      (summaryResponse.showTentativeOption ||
                        summaryResponse.showPaymentOption ||
                        summaryResponse.showWaitListOption ||
                        (summaryResponse.isCompanyCredit && summaryResponse.bookingStatus !== 'CONFIRMED')) && (
                        <Box mb={4}>
                          <FormControlLabel
                            control={<Checkbox onChange={(e: any) => setTermsAgreed(e.target.checked)} />}
                            label={<Typography fontSize={'0.75rem'}>{strings.termsText}</Typography>}
                          />
                          <Grid container alignContent={'center'} justifyContent="flex-end" spacing={2}>
                            {summaryResponse.showTentativeOption && (
                              <Grid item xs={12} sm={6} md={4} lg={2.5}>
                                <PrimaryButton
                                  onClick={() => completeBooking(summaryResponse.id, 'TENTATIVE')}
                                  disabled={!termsAgreed}>
                                  {strings.bookTentativeButton}
                                </PrimaryButton>
                              </Grid>
                            )}
                            {summaryResponse.isCompanyCredit &&
                              summaryResponse.bookingStatus !== 'CONFIRMED' &&
                              !summaryResponse.showWaitListOption && (
                                <Grid item xs={12} sm={6} md={4} lg={2.5}>
                                  <PrimaryButton
                                    onClick={() => completeBooking(summaryResponse.id, 'CONFIRMED')}
                                    disabled={!termsAgreed}>
                                    {strings.bookButton}
                                  </PrimaryButton>
                                </Grid>
                              )}
                            {queryStringInfo.r !== 's' &&
                              summaryResponse.paymentGatewayInfo &&
                              provider === 'RAZORPAY' ? (
                              <PaymentGateway
                                setSuccessModalStatus={setSuccessModalStatus}
                                provider={provider}
                                termsAgreed={termsAgreed}
                                summaryResponse={summaryResponse}
                                razorPayCallback={razorPayCallback}
                              />
                            ) : (
                              summaryResponse.showPaymentOption &&
                              !summaryResponse.isCompanyCredit && (
                                <Grid item xs={12} sm={6} md={4} lg={2.5}>
                                  <PaymentGateway
                                    setSuccessModalStatus={setSuccessModalStatus}
                                    provider={provider}
                                    termsAgreed={termsAgreed}
                                    summaryResponse={summaryResponse}
                                    razorPayCallback={razorPayCallback}
                                  />
                                </Grid>
                              )
                            )}
                            {summaryResponse.showWaitListOption && (
                              <Grid item xs={12} sm={6} md={4} lg={2.5}>
                                <PrimaryButton
                                  onClick={() => completeBooking(summaryResponse.id, 'WAITLISTED')}
                                  disabled={!termsAgreed}>
                                  {strings.addToWaitlistText}
                                </PrimaryButton>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      )}
                  </>
                )}
                {summaryResponse.bookedRooms && summaryResponse.bookedRooms.length >= 1 && (
                  <AddEditBookingRooms
                    rooms={summaryResponse.bookedRooms.map((item: any) => ({
                      roomTypeId: item.roomTypeName,
                      from: dayjs(item.bookingFrom).format('DD/MM/YYYY'),
                      to: dayjs(item.bookingTo).format('DD/MM/YYYY'),
                      numberOfRooms: item.numberOfRooms,
                      mealPlanName: item.mealPlanName,
                    }))}
                    setRooms={() => { }}
                    roomsList={[]}
                  />
                )}
                {summaryResponse.bookingLineItems &&
                  summaryResponse.bookingLineItems.length >= 1 &&
                  summaryResponse.bookingLineItems.map((venueItem: any) => (
                    <Card sx={{ my: 3 }} key={venueItem.id}>
                      <CardContent>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{
                              'img[class=image-gallery-image]': {
                                width: '-webkit-fill-available',
                              },
                            }}>
                            {venueItem.media.otherImageUrls.length >= 1 ? (
                              <ImageGallery
                                showThumbnails={false}
                                showPlayButton={false}
                                autoPlay
                                showNav={false}
                                slideInterval={10000}
                                items={venueItem.media.otherImageUrls.map((item: any) => ({
                                  original: getBaseUrl() + item,
                                  thumbnail: getBaseUrl() + item,
                                }))}
                              />
                            ) : (
                              <Box
                                sx={{
                                  backgroundColor: 'lightgrey',
                                  height: '160px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography>{strings.noImageAvailableText}</Typography>
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Box paddingTop={2} paddingX={1}>
                              <Grid container>
                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                  {venueItem.venueName}&nbsp;&nbsp;|&nbsp;&nbsp;
                                </Typography>
                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                  {dayjs(venueItem.startDate).format('DD-MM-YYYY')}&nbsp;&nbsp;|&nbsp;&nbsp;
                                </Typography>
                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                  {venueItem.timeSlot.startTime} - {venueItem.timeSlot.endTime}
                                </Typography>
                              </Grid>
                              <Grid container my={1}>
                                <Typography fontSize={'0.75rem'}>
                                  {strings.orderSummaryCustomerGuaranteedGuests} :{' '}
                                  <span style={{ fontWeight: 500 }}>{venueItem.guaranteedGuests}</span>
                                  &nbsp;&nbsp;|&nbsp;&nbsp;
                                </Typography>
                                <Typography fontSize={'0.75rem'}>
                                  {strings.orderSummaryCustomerExpectedGuests} :{' '}
                                  <span style={{ fontWeight: 500 }}>{venueItem.expectedGuests}</span>
                                </Typography>
                              </Grid>
                              <Typography fontSize={'0.75rem'}>
                                {strings.pricePerPerson} :{' '}
                                <span style={{ fontWeight: 500 }}>{venueItem.formattedPricePerPerson}</span>
                              </Typography>
                              <VenueDescription
                                description={venueItem.description}
                                width={venueItem.width}
                                length={venueItem.length}
                                height={venueItem.height}
                                area={venueItem.area}
                                layouts={venueItem.layouts}
                                floorPlan={venueItem.floorPlan}
                              />
                              {venueItem.terms ? (
                                <Link
                                  href={getBaseUrl() + venueItem.terms}
                                  underline="hover"
                                  alignItems="center"
                                  color="secondary">
                                  <Grid container alignItems={'flex-start'} mt={1}>
                                    <Grid item mr={0.5}>
                                      <DescriptionOutlinedIcon fontSize="small" />
                                    </Grid>
                                    <Grid item>
                                      <Typography fontSize={'0.75rem'}>{strings.termsConditionText}</Typography>
                                    </Grid>
                                  </Grid>
                                </Link>
                              ) : null}
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))}
              </Grid>
              <Grid item xs={12} lg={3.5}>
                <Box position={'sticky'} top="0px" width={'100%'}>
                  <Typography fontSize={'16px'} fontWeight="500" color={'primary'} mb={1} ml={1}>
                    {strings.chargesSummaryText}
                  </Typography>
                  <Card>
                    <CardContent sx={{ px: 3.5 }}>
                      {costingResponse?.lineItems?.map((item: any, idx: any) => (
                        <>
                          {item.header ? (
                            <>
                              {idx !== 0 && (
                                <Divider
                                  sx={{
                                    my: 2,
                                    borderStyle: 'dashed',
                                    borderColor: theme.palette.secondary.main,
                                  }}
                                />
                              )}
                              <Typography fontWeight={500} mb={1}>
                                {item.text}
                              </Typography>
                            </>
                          ) : (
                            <Grid container mb={0.5}>
                              <Grid item xs={0.8} sm={0.5} md={0.3} lg={0.8}>
                                <CircleIcon sx={{ fontSize: '8px' }} />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography mr={1}>{item.text}</Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography flex={4} textAlign={'end'}>
                                  {item.amount}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      ))}
                      <Divider
                        sx={{
                          my: 2,
                          borderStyle: 'dashed',
                          borderColor: theme.palette.secondary.main,
                        }}
                      />
                      <Box display={'flex'} justifyContent="space-between">
                        <Typography color={'primary'} fontWeight={600} flex={5} mr={1}>
                          {strings.totalText}
                        </Typography>
                        <Typography color={'primary'} fontWeight={600} flex={4} textAlign={'end'}>
                          {costingResponse.total}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Modal
            open={openSuccessModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => setOpenSuccessModal(false)}>
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: getWidth(),
                bgcolor: '#339c70',
                boxShadow: 24,
                ':focus': { border: 0 },
              }}>
              <Box display={'flex'} justifyContent="center" alignItems={'center'} height="25vh" width={'100%'}>
                <CheckCircleOutlineIcon sx={{ fontSize: '80px', color: 'white' }} />
              </Box>
              <ClearOutlinedIcon
                onClick={() => handleSuccessModalClose()}
                sx={{
                  ':hover': { cursor: 'pointer' },
                  top: '15px',
                  right: '15px',
                  position: 'absolute',
                  color: 'white',
                }}
              />
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 3,
                }}>
                {paymentId === '' ? (
                  <>
                    <Typography fontSize={'1.5rem'} fontWeight={'600'}>
                      {strings.successText}
                    </Typography>
                    <Typography fontSize={'14px'} fontWeight="500" sx={{ p: 1, textAlign: 'center' }}>
                      {getSuccessMessage(successModalStatus)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography fontSize={'1.5rem'} fontWeight={'600'}>
                      {strings.paymentSuccessMessage}
                    </Typography>
                    <Typography fontSize={'14px'} fontWeight="500" sx={{ p: 1, textAlign: 'center' }}>
                      {strings.paymentIdText}: {paymentId}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <Box height={'80vh'} width={'100vw'}></Box>
      )}
    </>
  );
};

export default PaymentSummary;
