import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox, FormControl, FormHelperText, useTheme } from '@mui/material';
import strings from 'common/Translation/Translate';
import { FC, useState } from 'react';
import Select, { StylesConfig, components } from 'react-select';
import { validateInput } from '../../utils/validators/Validators';
import { FormValidators } from '../Form';
import Paragraph from '../Typography/Typography';
import './styles.scss';

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props} >
        <Checkbox
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


interface OwnProps {
  field?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  typeValue?: string;
  onChange: (value: string, field: string, error?: { error: boolean; message: string }) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: string | boolean;
  isSearchable?: boolean;
  label?: string;
  disableFuture?: boolean;
  labelId?: string;
  inputValue: any;
  options: any[];
  inputProps?: any;
  className?: string;
  style?: any;
  isMulti?: boolean;
  isClearable?: boolean;
  id: any;
}
const MultiSelect: FC<OwnProps> = ({
  hasError,
  validators,
  onChange,
  field,
  inputValue,
  options,
  fieldError,
  disabled,
  className,
  label,
  id,
  style,
  isSearchable = true,
  isMulti,
}) => {
  const theme = useTheme();
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const handleChange = (selectedOption: any) => {
    const inputError = validateInput(validators as FormValidators[], selectedOption);
    setError(inputError);
    onChange(selectedOption, field as string, inputError);
  };
  const arrowDownIcon = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    );
  };
  const colourStyles: StylesConfig<any> = {
    valueContainer: base => ({
      ...base,
      maxHeight: '37px',
      overflowY: 'auto',
    }),
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: 'white',
        color: 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover': {
          ...styles[':hover'],
          backgroundColor: 'lightgrey',
        },
      };
    },
  };

  const customMessage = () => (!error && hasError && fieldError ? strings.requiredField : '');

  return (
    <>
      {label ? <Paragraph sx={{ fontSize: '0.75rem', marginBottom: '6px' }}>{label}</Paragraph> : null}
      <FormControl
        sx={{
          '.select': {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
          },
          "span[class$='indicatorSeparator']": {
            display: 'none',
          },
          '&.mainError': {
            "div[class$='control']": {
              borderColor: '#d32f2f',
            },
            "div[class$='placeholder']": {
              color: '#d32f2f',
            },
          },
          "div[class$='listbox'] > input:active": {
            color: 'white',
          },
          "div[class$='control']": {
            color: !disabled ? theme.palette.primary.main : 'gray',
            borderColor: !disabled ? theme.palette.secondary.main : 'lightgray',
            minHeight: '1.4375em',
            backgroundColor: !disabled ? 'white' : '#d8d8d8',
            borderRadius: '0%',
            height: '39px',
          },
          "div[class$='multiValue']": {
            backgroundColor: !disabled ? theme.palette.secondary.dark : 'white',
            paddingRight: !disabled ? 0 : 1,
          },
          "div[class$='MultiValueGeneric']": {
            fontSize: '12px !important',
          },
          "div[class$='MultiValueRemove']": {
            display: !disabled ? 'flex' : 'none',
          },
          '.MuiSvgIcon-root': {
            color: theme.palette.primary.main,
            visibility: !disabled ? 'visible' : 'hidden',
          },

          ...style,
        }}
        error={!!((error && error.error) || (!error && hasError && fieldError))}
        className={`dropdown ${className} ${(error && error.error) || (!error && hasError && fieldError) ? 'mainError' : ''
          }`}
        fullWidth>
        <Select
          className="select"
          options={options}
          styles={colourStyles}
          theme={(tm: any) => ({
            ...tm,
            borderRadius: 0,
            colors: {
              ...tm.colors,
              primary25: theme.palette.primary.main,
            },
          })}
          isMulti={isMulti}
          components={{ Option, DropdownIndicator: arrowDownIcon }}
          isDisabled={disabled}
          isSearchable={isSearchable}
          id={id}
          hideSelectedOptions={false}
          isClearable={false}
          value={inputValue}
          placeholder={''}
          closeMenuOnSelect={false}
          onChange={value => {
            handleChange(value);
          }}
          classNamePrefix="react-select"
        />
        <FormHelperText>{error && error.error ? error.message : customMessage()}</FormHelperText>
      </FormControl>
    </>
  );
};
export default MultiSelect;
