import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Divider, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import strings from 'common/Translation/Translate';
import * as React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type ModalProps = {
  open: boolean;
  setOpen: any;
  addOns: any;
};

export const AddOnsModal: React.FC<ModalProps> = ({ open, setOpen, addOns }) => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: screenSizeDownMd ? (screenSizeDownSm ? '90vw' : '70vw') : '40vw',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 0,
          maxHeight: '80vh',
          ':focus': { border: 0 },
        }}>
        <Box display={'flex'} alignItems="center" justifyContent={'space-between'} p={3}>
          <Typography id="modal-modal-title" fontSize={'20px'} fontWeight={600} color={'primary'}>
            {strings.addOnsHeaderText}
          </Typography>
          <ClearOutlinedIcon
            onClick={onClose}
            sx={{
              ':hover': { cursor: 'pointer' },
              color: theme.palette.primary.main,
            }}
          />
        </Box>
        <Divider
          sx={{
            mx: 3,
            borderStyle: 'dashed',
            borderColor: theme.palette.secondary.main,
          }}
        />
        <Scrollbar style={{ width: '100%', height: '50vh' }}>
          {addOns.map((item: any, index: number) => (
            <>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} px={3} py={2}>
                <Typography fontSize={'0.68rem'}>{item.name}</Typography>
                {item.addOnType === 'PER_QUANTITY' ? (
                  <Typography fontSize={'0.68rem'}>{item.addOnQuantity}</Typography>
                ) : (
                  <Typography fontSize={'0.68rem'}>{strings.perGuestText}</Typography>
                )}
              </Box>
              {index !== addOns.length - 1 && <Divider sx={{ mx: 3 }} />}
            </>
          ))}
        </Scrollbar>
      </Box>
    </Modal>
  );
};
