import { FormModel } from "components/Form";
import { Validators } from "utils/validators";

export const ChangePasswordModalForm = (strings: any): FormModel[] => {
    return [
        {
            label: `${strings.oldPasswordText}`,
            value: '',
            typeValue: 'password',
            type: 'text',
            placeholder: '',
            field: 'oldPassword',
            variant: 'outlined',
            validators: [
                { check: Validators.required, message: strings.requiredField},
                { check: Validators.password, message: strings.required_password_msg }
              ],
            required: true,
            responsive: { xs: 12 },
            size: 'medium',
        },
        {
            label: `${strings.newPasswordPlaceholderText}`,
            value: '',
            typeValue: 'password',
            type: 'text',
            placeholder: '',
            field: 'newPassword',
            variant: 'outlined',
            validators: [
                { check: Validators.required, message: strings.requiredField
},
                { check: Validators.password, message: strings.required_password_msg }
              ],
            required: true,
            responsive: { xs: 12 },
            size: 'medium',
        },  
        {
            label: `${strings.confirmPasswordPlaceholderText}`,
            value: '',
            typeValue: 'password',
            type: 'text',
            placeholder: '',
            field: 'confirmPassword',
            variant: 'outlined',
            validators: [
                { check: Validators.required, message: strings.requiredField
},
                { check: Validators.password, message: strings.required_password_msg }
              ],
            required: true,
            responsive: { xs: 12 },
            size: 'medium',
        }, 
    ];
}
