import { Typography } from "@mui/material";
import React from "react";

type ParagraphProps = {
    variant?: 'body1' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit';
    children: any,
    sx?: any,
    fontSize?: string,
    color?: string,
    fontWeight?: string,
    component?:any
}

const Paragraph = ({ variant='body1', children, sx, fontSize, color, fontWeight, component}: ParagraphProps) => <Typography component={component} color={color} fontWeight={fontWeight} fontSize={fontSize} sx={sx} variant={variant}>{children}</Typography>

export default Paragraph