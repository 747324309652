import { InfoOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
  AlertColor,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import strings from 'common/Translation/Translate';
import DrawerMenu from 'components/DrawerMenu';
import Notification from 'components/Notification';
import Paragraph from 'components/Typography/Typography';
import { useState } from 'react';
import { createDropdownData } from 'utils/validators/HelperFunctions';
import { AddOnsTable } from './AddOnsTable';
import { RoomLayout } from './RoomLayout';

const AddOnsAccordians = ({ item, setDrawerContent, selectedVenues, setMessage, setSelectedVenues, setOpenDrawer }: any) => {
  const theme = useTheme();
  const { addOnList } = item;
  return (
    <Box
      mb={2}
      sx={{
        '& .MuiAccordion-gutters': {
          '::before': {
            display: 'none',
          },
        },
        '.MuiAccordionDetails-root': {
          p: 0,
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
          translate: '0 0.25em',
        },
      }}>
      {addOnList.FOOD && addOnList.FOOD.length > 0 && (
        <Box
          sx={{ backgroundColor: theme.palette.secondary.light, p: 1.5, mb: 1 }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          onClick={() => {
            setDrawerContent(
              <AddOnsTable
                addOnList={addOnList.FOOD}
                item={item}
                type={'FOOD'}
                selectedVenues={selectedVenues}
                setMessage={setMessage}
                setSelectedVenues={setSelectedVenues}
                setOpenDrawer={setOpenDrawer}
              />,
            );
            setOpenDrawer(true);
          }}>
          <Typography sx={{ width: '33%', flexShrink: 0 }} fontSize={'15px'} fontWeight={600}>
            {strings.addOnCategoryFood}
          </Typography>
          <AddCircleOutlineIcon color={'primary'} fontSize="large" />
        </Box>
      )}
      {addOnList.ACCESSORIES && addOnList.ACCESSORIES.length > 0 && (
        <Box
          sx={{ backgroundColor: theme.palette.secondary.light, p: 1.5, mb: 1 }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          onClick={() => {
            setDrawerContent(
              <AddOnsTable
                addOnList={addOnList.ACCESSORIES}
                item={item}
                type={'ACCESSORIES'}
                selectedVenues={selectedVenues}
                setMessage={setMessage}
                setSelectedVenues={setSelectedVenues}
                setOpenDrawer={setOpenDrawer}
              />,
            );
            setOpenDrawer(true);
          }}>
          <Typography sx={{ width: '33%', flexShrink: 0 }} fontSize={'15px'} fontWeight={600}>
            {strings.addOnCategoryAccessories}
          </Typography>
          <AddCircleOutlineIcon color={'primary'} fontSize="large" />
        </Box>
      )}
      {addOnList.LIQUOR && addOnList.LIQUOR.length > 0 && (
        <Box
          sx={{ backgroundColor: theme.palette.secondary.light, p: 1.5, mb: 1 }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          onClick={() => {
            setDrawerContent(
              <AddOnsTable
                addOnList={addOnList.LIQUOR}
                item={item}
                type={'LIQUOR'}
                selectedVenues={selectedVenues}
                setMessage={setMessage}
                setSelectedVenues={setSelectedVenues}
                setOpenDrawer={setOpenDrawer}
              />,
            );
            setOpenDrawer(true);
          }}>
          <Typography sx={{ width: '33%', flexShrink: 0 }} fontSize={'15px'} fontWeight={600}>
            {strings.addOnCategoryLiquor}
          </Typography>
          <AddCircleOutlineIcon color={'primary'} fontSize="large" />
        </Box>
      )}
      {addOnList.OTHERS && addOnList.OTHERS.length > 0 && (
        <Box
          sx={{ backgroundColor: theme.palette.secondary.light, p: 1.5, mb: 1 }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          onClick={() => {
            setDrawerContent(
              <AddOnsTable
                addOnList={addOnList.OTHERS}
                item={item}
                type={'OTHERS'}
                selectedVenues={selectedVenues}
                setMessage={setMessage}
                setSelectedVenues={setSelectedVenues}
                setOpenDrawer={setOpenDrawer}
              />,
            );
            setOpenDrawer(true);
          }}>
          <Typography sx={{ width: '33%', flexShrink: 0 }} fontSize={'15px'} fontWeight={600}>
            {strings.addOnCategoryOthers}
          </Typography>
          <AddCircleOutlineIcon color={'primary'} fontSize="large" />
        </Box>
      )}
    </Box>
  );
};

type VenueWiseAddOnsProps = {
  venueItem: any;
  selectedVenues: any;
  setSelectedVenues: any;
  setCallAPi: any;
};

export const VenueWiseAddOns = ({ venueItem, selectedVenues, setSelectedVenues, setCallAPi }: VenueWiseAddOnsProps) => {
  const theme = useTheme();
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<any>(null);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const getVenueArray = (item: any) => {
    const idx1 = selectedVenues.findIndex((ele: any) => ele.date === item.bookingDate);
    const idx2 = selectedVenues[idx1].venues.findIndex((ele: any) => ele.id === item.id);
    const array = [...selectedVenues];
    const venuesArray = [...array[idx1].venues];
    return { array, venuesArray, idx1, idx2 };
  };

  const handleGuestChangeHelper = (value: string, item: any) => {
    let val = parseInt(value, 10);
    if (val < 0) value = '0';
    if (!value.includes('.')) {
      const { array, venuesArray, idx1, idx2 } = getVenueArray(item);

      const expectedGuests = Math.round(
        parseInt(value == '' ? '0' : value) * (1 + venuesArray[idx2].excessGuestsPercentage / 100),
      );
      venuesArray[idx2] = { ...venuesArray[idx2], guaranteedGuests: value, expectedGuests };
      array[idx1] = {
        date: selectedVenues[idx1].date,
        venues: venuesArray,
      };
      setSelectedVenues(array);
    }
  };

  const handleGuestChange = (value: string, item: any) => {
    if (!Number.isNaN(Number(value))) {
      handleGuestChangeHelper(value, item);
    }
  };

  const setAdditionCooment = (value: string, item: any) => {
    const { array, venuesArray, idx1, idx2 } = getVenueArray(item);

    venuesArray[idx2] = { ...venuesArray[idx2], additionalComments: value };
    array[idx1] = {
      date: selectedVenues[idx1].date,
      venues: venuesArray,
    };
    setCallAPi(false);
    setSelectedVenues(array);
  };

  const extraGuestText = (item: any) => {
    const { venuesArray, idx2 } = getVenueArray(item);
    return venuesArray[idx2].extraGuestText;
  };
  const getWidth = () => {
    if (screenSizeUpLg) return '80vw'
    else if (screenSizeDownSm) return '100vw'
    else return '90vw'
  }


  const getMargin = () => {
    if (screenSizeDownMd) return 5
    else if (screenSizeDownSm) return 3
    else return 8
  }

  return (
    <>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
          autoHideDuration={3000}
        />
      )}
      {venueItem.venues.map((item: any, index: any) => (
        <Box pb={2} key={item.id}>
          <Grid container alignItems={'center'} sx={{ mb: 2 }}>
            <Grid item xs={12} md={9} lg={10}>
              <Typography color={'primary'} fontSize={'22px'} fontWeight={600}>
                {item.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <FormControl
                size={'small'}
                sx={{
                  width: '100%',
                }}>
                <Select
                  sx={{
                    '& svg.MuiSelect-icon': {
                      color: 'white',
                    },
                    '&.MuiInputBase-root': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '0%',
                      height: '44px',
                      color: 'white',
                    },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item.pricePackageId}
                  onChange={(event: SelectChangeEvent) => {
                    const idx1 = selectedVenues.findIndex((ele: any) => ele.date === item.bookingDate);
                    const idx2 = selectedVenues[idx1].venues.findIndex((ele: any) => ele.id === item.id);
                    const array = [...selectedVenues];
                    array[idx1].venues[idx2].pricePackageId = event.target.value;
                    setSelectedVenues(array);
                  }}>
                  {createDropdownData(item.prices, ['pricePackageId', 'pricePackageName']).map((i: any) => (
                    <MenuItem
                      sx={{
                        '&.Mui-selected': {
                          color: 'white',
                          backgroundColor: theme.palette.primary.main,
                          ':hover': {
                            color: 'white',
                            backgroundColor: theme.palette.action.hover,
                          },
                        },
                        ':hover': {
                          color: 'white',
                        },
                      }}
                      key={i.value}
                      value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box display={'flex'} alignItems={'center'} gap={1} mb={1.5}>
            <InfoOutlined fontSize="small" />
            <Typography>
              {`Please select add on's for`}{' '}
              <b>
                {item.name} - {venueItem.date}
              </b>
            </Typography>
          </Box>
          <AddOnsAccordians
            item={item}
            setDrawerContent={setDrawerContent}
            selectedVenues={selectedVenues}
            setMessage={setMessage}
            setSelectedVenues={setSelectedVenues}
            setOpenDrawer={setOpenDrawer}
          />
          <Divider
            sx={{
              my: 2,
              border: `1px dashed ${theme.palette.secondary.main}`,
            }}
          />
          {item.layouts.length > 0 && (
            <RoomLayout
              selectedVenues={selectedVenues}
              venueItem={venueItem}
              setSelectedVenues={setSelectedVenues}
              item={item}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Paragraph
                    sx={{
                      fontSize: '0.75rem',
                      marginBottom: '6px',
                      whiteSpace: screenSizeUpLg ? 'noWrap' : 'wrap',
                    }}>
                    {strings.GuestPercentageGuaranteedGuestText}
                  </Paragraph>
                  <TextField
                    fullWidth
                    sx={{
                      mx: 0,
                      '& fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.secondary.main,
                        borderRadius: '0%',
                      },
                      '.MuiInputBase-root': {
                        backgroundColor: theme.palette.secondary.light,
                      },
                      'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                      },
                    }}
                    type="text"
                    size="small"
                    required
                    id={''}
                    value={item.guaranteedGuests}
                    onChange={(e: any) => handleGuestChange(e.target.value, item)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Paragraph
                    sx={{
                      fontSize: '0.75rem',
                      marginBottom: '6px',
                      whiteSpace: screenSizeUpLg ? 'noWrap' : 'wrap',
                    }}>
                    {strings.GuestPercentageExpectedGuestText}
                  </Paragraph>
                  <TextField
                    fullWidth
                    sx={{
                      mx: 0,
                      '& fieldset.MuiOutlinedInput-notchedOutline': {
                        borderRadius: '0%',
                      },
                      '.MuiInputBase-root.Mui-disabled, .MuiInputBase-input.Mui-disabled': {
                        backgroundColor: '#E7E7E7',
                        borderRadius: 0,
                        borderColor: '#9B9B9B',
                        color: '#151515',
                      },
                    }}
                    size="small"
                    id={''}
                    disabled={true}
                    value={item.expectedGuests}
                  />
                </Grid>
              </Grid>
              <Typography mt={3} fontSize={'0.68rem'}>
                <b>
                  <u>{strings.noteText}: </u>
                </b>
                *{extraGuestText(item)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paragraph
                sx={{
                  fontSize: '15px',
                  marginBottom: '6px',
                  marginLeft: screenSizeDownMd ? '0px' : '16px',
                }}>
                {strings.additional_comments_text}
              </Paragraph>
              <TextField
                sx={{
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                    boxShadow: '0px 4px 62px rgba(0, 0, 0, 0.08)',
                    borderRadius: '0%',
                  },
                  '.MuiInputBase-root': {
                    backgroundColor: 'white',
                  },
                  ml: screenSizeDownMd ? 0 : 2,
                }}
                onChange={(e: any) => setAdditionCooment(e.target.value, item)}
                placeholder={strings.additional_comments_placerholder_text}
                fullWidth
                multiline={true}
                rows={5}
              />
            </Grid>
          </Grid>
          {index !== venueItem.venues.length - 1 ? <Divider sx={{ marginBottom: 2, marginTop: 4 }} /> : null}
        </Box>
      ))}
      <DrawerMenu
        open={openDrawer}
        handleClose={() => setOpenDrawer(false)}
        width={getWidth()}>
        <>
          <Box display={'flex'} justifyContent={'flex-end'} px={5} pt={2}>
            <ClearOutlinedIcon
              onClick={() => setOpenDrawer(false)}
              sx={{
                ':hover': { cursor: 'pointer' },
                color: theme.palette.primary.main,
              }}
            />
          </Box>
          <Typography
            fontWeight={'500'}
            ml={getMargin()}
            mb={1}
            color={'primary'}
            fontSize={'24px'}>
            {strings.selectAddons}
          </Typography>
          <Box mx={getMargin()} bgcolor={theme.palette.secondary.light}>
            {drawerContent}
          </Box>
        </>
      </DrawerMenu>
    </>
  );

};
