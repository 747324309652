import { useTheme } from "@mui/material";


const Others = () => {
    const theme = useTheme();
    return (
        <svg width="50" height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="27" fill={theme.palette.primary.main} />
            <circle cx="16.103" cy="29.603" r="4.36765" fill="#D9D9D9" />
            <circle cx="29.603" cy="29.603" r="4.36765" fill="#D9D9D9" />
            <circle cx="43.103" cy="29.603" r="4.36765" fill="#D9D9D9" />
        </svg>

    )
}

export default Others;
