import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { CircularProgress, Divider, Grid, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paragraph from 'components/Typography/Typography';
import * as React from 'react';
import PrimaryButton from '../Button/PrimaryButton';

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  children: any;
  title: string;
  contentText: string;
  loading: boolean;
  handleSave: any;
  buttonText: string;
};
export const FormDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  children,
  title,
  contentText,
  loading,
  handleSave,
  buttonText,
}) => {
  const theme = useTheme();

  return (
    <div>
      <Dialog
        open={open}
        sx={{
          px: 3,
        }}>
        <DialogTitle sx={{ color: theme.palette.primary.main }}>
          <Grid container alignItems="flex-end" justifyContent="space-between">
            <Grid item>
              <Typography sx={{ fontSize: '20px', fontWeight: 600 }}> {title}</Typography>
            </Grid>
            <Grid item>
              <ClearOutlinedIcon
                onClick={handleClose}
                sx={{
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                }}
              />
            </Grid>
          </Grid>
          <Divider
            sx={{
              mt: 2,
              borderStyle: 'dashed',
              borderColor: theme.palette.secondary.main,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Paragraph sx={{ fontSize: '0,75rem' }}>{contentText}</Paragraph>
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Grid container>
            <PrimaryButton disabled={loading} variant="contained" onClick={handleSave}>
              {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              {buttonText}
            </PrimaryButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
