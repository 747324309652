import { Typography, Grid, Card, useTheme } from '@mui/material';
import strings from '../../common/Translation/Translate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popup from '../../components/Popup';
import { useState } from 'react';
import axiosInstance from 'utils/redux/axios';
import { URLS } from 'utils/constants/urls';
import PageLoader from 'components/PageLoader';

type PricePackageCardProps = {
  priceItem: any;
  selectedItem: any;
  handleClick: (id: any) => void;
};
export const PricePackageCard = ({ priceItem, selectedItem, handleClick }: PricePackageCardProps) => {
  const theme = useTheme();
  const [pricePackageDescription, setPricePackageDescription] = useState<any>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handlePricePackageInfoIconClick = async () => {
    try {
      setIsLoading(true)
      const { status, data } = await axiosInstance.get(URLS.getPricePackgeDesc(priceItem.pricePackageId))
      if (status === 200) {
        setIsLoading(false)
        setPricePackageDescription(data?.description)
      }
    } catch {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <Grid item xs={12} sm={6} lg={4}>
        <Card
          sx={{ p: 1.5, minHeight: '90px', '&:hover': { cursor: 'pointer' } }}
          onClick={() => handleClick(priceItem.pricePackageId)}
          elevation={4}>
          <Grid container justifyContent={'space-between'} mb={0.5}>
            <Grid
              item
              display={'flex'}
              color={selectedItem === priceItem.pricePackageId ? `${theme.palette.primary.main}` : 'gray'}>
              <CheckCircleIcon fontSize='small' />
              <Typography variant="subtitle2" ml={0.75} fontWeight={'700'}>
                {priceItem.pricePackageName}
              </Typography>
            </Grid>
            <Grid item color={'gray'}>
              <Popup
                onClick={handlePricePackageInfoIconClick}
                Component={InfoOutlinedIcon}
                text={pricePackageDescription}
                tooltipText={''}
              />
            </Grid>
          </Grid>
          {priceItem.pricePerPerson !== 0 ? <Typography mb={1} fontSize={'0.65rem'} fontWeight={'500'}>
            {strings.perPersonText}: &nbsp;{priceItem.formattedPricePerPerson}
          </Typography>
            : <Typography mb={3} fontSize={'0.65rem'} fontWeight={'500'}>
            </Typography>}
          {priceItem.hallRental > 0 && (
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography fontSize={'0.65rem'} fontWeight={'500'}>
                  {strings.hallRental}: &nbsp;{priceItem.formattedHallRental}
                </Typography>
              </Grid>
              <Grid item color={'gray'}>
                <Popup
                  Component={InfoOutlinedIcon}
                  text={priceItem.hallRentalDetails}
                  tooltipText={''}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </>
  );
};
