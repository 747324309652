import { useTheme } from "@mui/material";


const Liquor = () => {
    const theme = useTheme();
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.554 4.25154C16.554 2.63449 16.5635 2.57067 16.8441 2.29013L17.1342 2.11817L20.8107 2C24.442 2 24.8475 2.03808 25.0903 2.40184C25.1381 2.47327 25.1771 3.30292 25.1771 4.24531C25.1771 4.78575 25.1748 5.28914 25.1578 5.63374C25.1426 5.94164 24.9952 6.22334 24.7225 6.36702C24.5502 6.45784 24.4443 6.63833 24.449 6.83291L24.503 9.07314C24.5536 11.17 24.6101 11.8016 24.8163 12.5766C25.3306 14.5083 26.113 15.8013 27.8196 17.5389C29.6217 19.3737 30.5152 20.9015 31.1282 23.1967C31.1282 23.1967 31.2774 23.5077 31.3849 24.1576C31.4924 24.8076 31.3849 39.6975 31.3849 39.6975V55.2374L31.0533 55.9127C30.663 56.7076 29.8834 57.4373 29.1035 57.7379C28.5947 57.9341 27.9677 57.9548 21.5375 57.9876C13.7986 58.0272 13.6247 58.0138 12.5911 57.3026C12.3046 57.1055 11.9096 56.7108 11.7133 56.4255C11.6276 56.301 11.5522 56.1995 11.4859 56.0922C10.9783 55.2724 10.9999 54.1249 11.0002 39.8795C11.0004 29.9588 11.0425 24.8774 11.1303 24.1576C11.437 21.6451 12.4214 19.5879 14.2872 17.5603C15.9269 15.7785 16.8281 14.0427 17.1316 12.0812C17.2039 11.6138 17.2631 10.1861 17.2631 8.90845V7.07052C17.2631 6.76101 17.1347 6.46546 16.9086 6.25427C16.6824 6.04308 16.554 5.74753 16.554 5.43801V4.25154Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.7214 28.3641C48.7214 28.5047 49.0278 31.0155 49.088 31.3675L49.1455 31.7044L49.3805 33.7932C49.5476 35.3929 49.3719 36.4605 48.7449 37.6544C47.9278 39.2104 46.5326 40.2209 44.9884 40.614C43.6734 45.1512 44.2068 48.702 44.5878 50.226C45.23 50.2847 45.8255 50.5899 46.3219 51.0132C46.3655 51.0504 46.4087 51.086 46.4507 51.1189C46.6642 51.2864 46.9504 51.4681 47.224 51.6294C47.4383 51.7557 47.351 52.1064 47.1021 52.1064C46.3942 52.1065 45.2706 52.1065 43.6174 52.1061C41.8446 52.1056 40.7055 52.1055 39.9956 52.1057C39.7431 52.1057 39.6586 51.7469 39.8806 51.6266C40.1777 51.4657 40.49 51.285 40.7285 51.1189C40.8316 51.0471 40.9425 50.9631 41.0527 50.8756C41.5583 50.4744 42.172 50.2212 42.8168 50.2158C43.1956 48.658 43.6929 45.1547 42.4057 40.6956C41.3563 40.4963 40.3442 40.007 39.4953 39.2067C38.2503 38.0329 37.6192 36.6187 37.6049 34.9702C37.6005 34.457 37.6314 33.8388 37.6734 33.5965L37.9378 31.5749C37.9354 31.3732 38.266 28.5584 38.3091 28.4128C38.3391 28.3114 39.4745 28.2832 43.5345 28.2832C46.3872 28.2832 48.7214 28.3196 48.7214 28.3641ZM43.6953 48.6467L43.7018 48.6645C43.7003 48.6529 43.6988 48.6411 43.6974 48.6294C43.6967 48.6352 43.696 48.6409 43.6953 48.6467Z" fill="white" />
            <path d="M48.7777 37.6061C47.9605 39.1621 46.5086 40.2501 44.9645 40.6432C44.9645 40.6432 44.2356 40.7462 43.7232 40.7614C43.2197 40.7762 42.4238 40.7023 42.4238 40.7023C41.3744 40.5029 40.3183 40.0254 39.4694 39.2251C38.2244 38.0513 37.5929 36.5704 37.5786 34.9219H49.4718C49.4549 35.922 49.2378 36.7301 48.7777 37.6061Z" fill={theme.palette.primary.main} />
            <rect x="11.8755" y="28.2344" width="18.6715" height="14.8899" rx="1.41809" fill={theme.palette.primary.main} />
            <path d="M17.2524 10.3896H24.5202L24.6383 11.5714H17.1934L17.2524 10.3896Z" fill={theme.palette.primary.main} />
        </svg>

    )
}

export default Liquor;
