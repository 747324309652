import LoginIcon from '@mui/icons-material/Login';
import {
  Box,
  CssBaseline,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';
import { identifiers } from 'utils/constants/identifiers';
import { clearStorage, fetchFromStorage } from 'utils/storage';
import BBRLogo from '../../assets/images/BBRLogo';
import LogoutIcon from '../../assets/images/LogoutIcon';
import strings from '../../common/Translation/Translate';
import PrimaryButton from '../../components/Button/PrimaryButton';
import AuthenticationDrawer from '../../screens/Authentication/AuthenticationDrawer/AuthenticationDrawer';
import { routes } from '../../utils/constants/routes';
import { URLS } from '../../utils/constants/urls';
import axiosInstance from '../../utils/redux/axios';
import {
  Authenticated,
  User,
  defaultLanguage,
  handleLanguageChange,
  initData,
  languages,
  setAuthentication,
  setIniatalData,
  setLanguageList,
  setOpenAuthenticationDrawer,
  setTheme,
} from '../../utils/redux/reducer/authentication-slice';
import { createDropdownData, getBaseUrl } from '../../utils/validators/HelperFunctions';

const BeforeLoggedInComponent = ({ handleDrodownChange }: any) => {
  const lang = useSelector(defaultLanguage);
  const dispatch = useDispatch();
  const defaultTheme = useTheme();
  const languageData = createDropdownData(useSelector(languages), ['value', 'text']) || [];
  const screenSizeDownMd = useMediaQuery(defaultTheme.breakpoints.down('md'));
  return (
    <>
      <Select
        sx={{
          height: '35px',
          flex: 1,
          width: screenSizeDownMd ? '20vw' : '10vw',
          '& svg.MuiSelect-icon': {
            color: defaultTheme.palette.primary.main,
          },
          '&.MuiOutlinedInput-root': {
            borderColor: defaultTheme.palette.secondary.main,
          },
          '&.MuiInputBase-root': {
            backgroundColor: 'white',
            borderRadius: '0%',
            borderWidth: '5px',
          },
        }}
        value={lang.value}
        onChange={async value => {
          await handleDrodownChange(value);
        }}>
        {languageData?.map((item: any) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {screenSizeDownMd ? (
        <LoginIcon
          sx={{ color: defaultTheme.palette.primary.main }}
          onClick={() => dispatch(setOpenAuthenticationDrawer(true))}
        />
      ) : (
        <PrimaryButton
          variant="contained"
          fullWidth
          sx={{ backgroundColor: defaultTheme.palette.primary.main, flex: 2, width: '10vw' }}
          onClick={() => dispatch(setOpenAuthenticationDrawer(true))}>
          <Typography fontSize={'14px'} fontWeight={600}>
            {strings.loginText}
          </Typography>
        </PrimaryButton>
      )}
    </>
  )
}

const AfterLoggedInComponent = ({ handleDrodownChange, handleLogout }: any) => {
  const navigate = useNavigate();
  const lang = useSelector(defaultLanguage);
  const defaultTheme = useTheme();
  const languageData = createDropdownData(useSelector(languages), ['value', 'text']) || [];
  const user = useSelector(User);
  const screenSizeDownMd = useMediaQuery(defaultTheme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const openPop = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return useMediaQuery(defaultTheme.breakpoints.up('md')) ? (
    <Box display={'flex'} gap={2} alignItems={'center'} height={'10vh'}>
      <Select
        sx={{
          height: '35px',
          flex: 1,
          width: screenSizeDownMd ? '20vw' : '10vw',
          '& svg.MuiSelect-icon': {
            color: defaultTheme.palette.primary.main,
          },
          '&.MuiOutlinedInput-root': {
            borderColor: defaultTheme.palette.secondary.main,
          },
          '&.MuiInputBase-root': {
            backgroundColor: 'white',
            borderRadius: '0%',
            borderWidth: '5px',
          },
        }}
        value={lang.value}
        onChange={async value => {
          await handleDrodownChange(value);
        }}>
        {languageData.map((item: any) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Box
        display={'flex'}
        gap={1}
        alignItems={'center'}
        onClick={() => navigate(routes.user_profile)}
        sx={{
          ':hover': {
            cursor: 'pointer',
          },
        }}>
        <Avatar
          sx={{
            bgcolor: defaultTheme.palette.primary.main,
            color: 'white',
            width: '34px',
            height: '34px',
            fontSize: '0.87rem',
            fontWeight: 500,
            textTransform: 'uppercase',
          }}>
          {user?.username && user.username.split(' ')[0][0]}
          {user.username.split(' ')[1][0]}
        </Avatar>
        <Box display={'flex'} flexDirection={'column'} color={'black'}>
          <Typography fontWeight={'500'} fontSize={'0.87rem'}>
            {user.username}
          </Typography>
          <Typography fontSize={'0.68rem'}>{user.email}</Typography>
        </Box>
      </Box>
      <IconButton size="small" onClick={handleLogout}>
        <LogoutIcon />
      </IconButton>
    </Box>
  ) : (
    <>
      <Avatar
        sx={{
          bgcolor: defaultTheme.palette.primary.main,
          color: 'white',
          width: '34px',
          height: '34px',
          fontSize: '0.87rem',
          fontWeight: 500,
          textTransform: 'uppercase',
        }}
        onClick={(e: any) => openPopOver(e)}>
        {user?.username && user.username.split(' ')[0][0]}
        {user.username.split(' ')[1][0]}
      </Avatar>
      <Popover
        id="spring-popper"
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Grid container flexDirection={'column'} gap={1} py={2} px={2}>
          <Grid item>
            <Select
              fullWidth
              sx={{
                height: '50%',
                flex: 1,
                '& svg.MuiSelect-icon': {
                  color: defaultTheme.palette.primary.main,
                },
                '&.MuiOutlinedInput-root': {
                  borderColor: defaultTheme.palette.secondary.main,
                },
                '&.MuiInputBase-root': {
                  backgroundColor: 'white',
                  borderRadius: '0%',
                  borderWidth: '5px',
                },
              }}
              value={lang.value}
              onChange={async value => {
                await handleDrodownChange(value);
              }}>
              {languageData?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Grid container alignItems={'center'}>
              <Grid item>
                <Avatar
                  sx={{
                    bgcolor: defaultTheme.palette.primary.main,
                    color: 'white',
                    width: '34px',
                    height: '34px',
                    fontSize: '0.87rem',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                  }}>
                  {user?.username && user.username.split(' ')[0][0]}
                  {user.username.split(' ')[1][0]}
                </Avatar>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  onClick={() => {
                    handleClose();
                    navigate(routes.user_profile);
                  }}
                  className={'cursor-pointer'}
                  sx={{ mx: 2, fontWeight: 600 }}>
                  {strings.updateProfile_title_text}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={'center'} justifyContent={'start'}>
              <IconButton size="small" sx={{ p: 0 }} onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
              <Typography
                variant="body2"
                onClick={handleLogout}
                className={'cursor-pointer'}
                sx={{ mx: 2, fontWeight: 600 }}>
                {strings.logoutText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

type HeaderProps = {
  children: any;
};

export const Header = ({ children }: HeaderProps) => {
  const defaultTheme = useTheme();
  const screenSizeDownMd = useMediaQuery(defaultTheme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  const lang = useSelector(defaultLanguage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialData = useSelector(initData);
  const location = useLocation();
  const isLogin = useSelector(Authenticated);

  const handleLogout = async () => {
    const body: { refreshToken: string | null } = {
      refreshToken: fetchFromStorage(identifiers.refresh_token),
    };
    try {
      const { status }: { status: number } = await axiosInstance.post(URLS.logout, body);
      if (status === 200) {
        clearStorage('local');
        dispatch(setAuthentication(null));
      }
    } catch (e) {
      navigate('/');
      clearStorage('local');
      dispatch(setAuthentication(null));
    } finally {
      getInitialData();
      getTheme();
      getLanguageList();
    }
  };

  const handleDrodownChange = async (event: any) => {
    try {
      dispatch(handleLanguageChange(event.target) as any);
      navigate(routes.root);
    } catch (e) {
      console.log(e);
    }
  };

  const checkToken = useCallback(async () => {
    try {
      const accessToken = fetchFromStorage(identifiers.access_token);
      if (accessToken) {
        await axiosInstance.get('oauth/check_token?token=' + accessToken);
      }
    } catch (e: any) {
      console.error(e?.response?.data?.error_description);
      if (e?.response?.data?.error) {
        //  we can call refresh token infuture, if required
        dispatch(setAuthentication(null));
      }
    }
  }, [dispatch]);

  const getTheme = useCallback(async () => {
    try {
      const { status, data } = await axiosInstance(URLS.getTheme);
      if (status === 200) {
        dispatch(setTheme(data));
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);



  const getLanguageList = useCallback(async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.getLanguageList);
      if (status === 200) {
        const languages = data.map((item: any) => ({
          text: item.text,
          value: item.value,
        }));
        dispatch(setLanguageList(languages));
        if (lang.value === '') {
          dispatch(handleLanguageChange({ value: data[0].value }) as any);
        } else {
          dispatch(handleLanguageChange(lang) as any);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await getLanguageList();
    })();
  }, [getLanguageList]);

  const getInitialData = useCallback(async () => {
    try {
      const selectedOrg = JSON.parse(fetchFromStorage('selectedOrg', true) as string);
      const values = queryString.parse(location.search);
      let url;
      const id = values.oid || selectedOrg;
      if (id && location.pathname == routes.root && location.search.startsWith('?oid')) {
        url = `${URLS.getInitialData}?oid=${id}`;
      } else {
        url = URLS.getInitialData;
      }
      const { status, data } = await axiosInstance(url);
      if (status === 200) {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = data.faviconUrl === null ? '' : getBaseUrl() + data.faviconUrl;
        document.title = data.title ? data.title : 'Book Banquet Room';
        dispatch(setIniatalData(data));
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, location]);

  useEffect(() => {
    (async () => {
      await checkToken();
      await getTheme();
      await getInitialData();
    })();
  }, [getTheme, getInitialData, lang]);



  return (
    <Scrollbar style={{ width: '100vw', height: '100vh' }}>
      <>
        {/*useMediaQuery(defaultTheme.breakpoints.up(1025)) &&*/ initialData?.htmlHeader && (
          <Box dangerouslySetInnerHTML={{ __html: initialData?.htmlHeader }}></Box>
        )}
        <Box sx={{ display: 'flex', height: '100%' }}>
          <CssBaseline />
          <Box sx={{ width: '100vw' /*position: 'relative'*/ }}>
            <MuiAppBar
              sx={{
                height: '60px',
                position: 'static',
              }}
              elevation={0}>
              <Toolbar>
                <Box
                  sx={{
                    height: '90px',
                    paddingRight: '1%',
                    marginY: '1%',
                    display: 'flex',
                    gap: '10px',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 2,
                      alignItems: 'center',
                      color: defaultTheme.palette.primary.main,
                      paddingLeft: screenSizeDownSm ? 0 : 4,
                    }}>
                    {useMediaQuery(defaultTheme.breakpoints.down(1025)) ? (
                      <span style={{ cursor: 'pointer' }} onClick={() => navigate(routes.root)}>
                        {initialData?.logoUrl ? (
                          <img
                            src={getBaseUrl() + initialData.logoUrl}
                            alt=""
                            style={{ maxHeight: '50px', maxWidth: screenSizeDownSm ? '50vw' : '350px' }}
                          />
                        ) : (
                          <BBRLogo width={screenSizeDownMd ? '50vw' : '350px'} />
                        )}
                      </span>
                    ) : (
                      !initialData?.htmlHeader && (
                        <span style={{ cursor: 'pointer' }} onClick={() => navigate(routes.root)}>
                          {initialData?.logoUrl ? (
                            <img
                              src={getBaseUrl() + initialData.logoUrl}
                              alt=""
                              style={{ maxHeight: '50px', maxWidth: screenSizeDownSm ? '280vw' : '350px' }}
                            />
                          ) : (
                            <BBRLogo width={screenSizeDownMd ? '50vw' : '350px'} />
                          )}
                        </span>
                      )
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 0.5,
                      gap: '10px',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}>
                    {isLogin ?
                      <AfterLoggedInComponent
                        handleDrodownChange={handleDrodownChange}
                        handleLogout={handleLogout} /> :
                      <BeforeLoggedInComponent
                        handleDrodownChange={handleDrodownChange} />}
                  </Box>
                </Box>
              </Toolbar>
            </MuiAppBar>
            {children}
          </Box>
          <AuthenticationDrawer />
        </Box>
        {/*useMediaQuery(defaultTheme.breakpoints.up(1025)) &&*/ initialData?.htmlFooter && (
          <Box dangerouslySetInnerHTML={{ __html: initialData?.htmlFooter }} mt={1}></Box>
        )}
      </>
    </Scrollbar>
  );
};

export default Header;
