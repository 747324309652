import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { AlertColor, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import Form, { FormDataModel } from 'components/Form';
import Notification from 'components/Notification';
import * as React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/redux/axios';
import { removeErrorFieldsFromValues } from 'utils/validators';
import { ChangePasswordModalForm } from './ChangePasswordModalForm';

type ModalProps = {
  open: boolean;
  setOpen: any;
};

export const ChangePasswordModal: React.FC<ModalProps> = ({ open, setOpen }) => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  let formRef: React.RefObject<Form | null | undefined> = React.useRef();

  const handleResetPassword = async (e: any) => {
    e.preventDefault();
    const { getFormData } = formRef.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const bodyData = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        if (bodyData.newPassword !== bodyData.confirmPassword) {
          setMessage({
            display: true,
            severity: 'error',
            message: strings.forgotPasswordLinkmatchPasswordError,
          });
          return;
        }
        setLoading(true);
        const { status } = await axiosInstance.put(URLS.changePassword, bodyData);
        if (status === 200) {
          setLoading(false);
          onClose();
          setMessage({
            display: true,
            severity: 'success',
            message: strings.resetPasswordSuccessText,
          });
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.message,
      });
    }
  };
  const onClose = () => {
    setOpen(false);
    setHasError(false);
    formRef?.current?.resetForm();
  };
  const getWidth = () => {
    if (screenSizeDownMd) return '70vw'
    else if (screenSizeDownSm) return '90vw'
    else return '40vw'
  }
  return (
    <>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: getWidth(),
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 3,
            maxHeight: '90vh',
          }}>
          <Box display={'flex'} alignItems="center" justifyContent={'space-between'}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight={600} color={'primary'}>
              {strings.changePasswordText}
            </Typography>
            <ClearOutlinedIcon
              onClick={onClose}
              sx={{
                ':hover': { cursor: 'pointer' },
                color: theme.palette.primary.main,
              }}
            />
          </Box>
          <Scrollbar style={{ width: '100%', height: '70vh' }}>
            <Grid container mt={1} spacing={1} px={1}>
              <Form
                hasError={hasError}
                ref={formRef as React.RefObject<Form>}
                model={ChangePasswordModalForm(strings)}
                values={{}}
              />
              <Grid item xs={12} md={6}>
                <PrimaryButton disabled={loading} sx={{ mt: 1, py: 1 }} onClick={e => handleResetPassword(e)}>
                  {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                  {strings.changePasswordLinkButton}
                </PrimaryButton>
              </Grid>
            </Grid>
          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
};
