import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  AlertColor,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PageLoader from 'components/PageLoader';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Authenticated } from 'utils/redux';
import { setOpenAuthenticationDrawer } from 'utils/redux/reducer/authentication-slice';
import { getBaseUrl } from 'utils/validators/HelperFunctions';
import strings from '../../common/Translation/Translate';
import PrimaryButton from '../../components/Button/PrimaryButton';
import Notification from '../../components/Notification';
import { routes } from '../../utils/constants/routes';
import { URLS } from '../../utils/constants/urls';
import axiosInstance from '../../utils/redux/axios';
import { setSelectedRoomsCosting, setSelectedVenuesForCosting } from '../../utils/redux/reducer/costing-slice';
import { fetchFromStorage } from '../../utils/storage';
import { AddEditBookingRooms } from './AddEditBookingRooms/AddEditBookingRooms';
import { AddOnAccordian } from './AddOnAccordian';

const OrderSummary = () => {
  const location = useLocation();
  const theme = useTheme();
  const screenSizeDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = useSelector(Authenticated);
  const selectedVenuesFromStorage = JSON.parse(fetchFromStorage('selectedVenuesForCosting', true) as string);
  const selectedRooms = JSON.parse(fetchFromStorage('selectedRoomsForCosting', true) as string);
  const searchData = JSON.parse(fetchFromStorage('searchFormData', true) as string);
  const selectedOrgId = JSON.parse(fetchFromStorage('selectedOrg', true) as string);
  const orgData = JSON.parse(fetchFromStorage('venueList', true) as string);
  // console.log('searchFormData: ', searchFormData)
  useEffect(() => {
    if (!(selectedVenuesFromStorage && selectedOrgId && searchData && orgData)) {
      navigate(routes.root);
    }
  }, []);

  const [loading, setLoading] = useState<boolean>(false);
  const [roomsList, setRoomsList] = useState<any>([]);
  const [selectedVenues, setSelectedVenues] = useState<any>([]);
  const [rooms, setRooms] = useState<any>([]);
  const [selectedEventType, setSelectedEventType] = useState<any>({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>({});
  const [pageResponse, setPageResponse] = useState<any>({});
  const [callAPi, setCallAPi] = useState<boolean>(true);
  const [continueDisabled, setContinueDisabled] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  useEffect(() => {
    setSelectedVenues(selectedVenuesFromStorage);
    selectedRooms !== null && setRooms(selectedRooms);
  }, []);

  const getCostingData = useCallback(async () => {
    try {
      if (callAPi) {
        const requestBody = {
          orgId: selectedOrgId,
          eventType: searchData.eventType,
          bookingStartDate: dayjs(searchData.startDate as string).format('DD-MM-YYYY'),
          bookingEndDate: dayjs(searchData.endDate as string).format('DD-MM-YYYY'),
          guests: searchData.guests,
          rooms: rooms,
          venues: (selectedVenues.length > 0 ? selectedVenues : selectedVenuesFromStorage)
            .map((venue: any) =>
              venue.venues.map((item: any) => ({
                id: item.id,
                bookingDate: item.bookingDate,
                layout: item.layout,
                guaranteedGuests: item.guaranteedGuests,
                addOns: item.addOns,
                timeSlot: searchData.timeSlot,
                pricePackageId: item.pricePackageId,
                // "pricePackageIdNew": true
              })),
            )
            .flat(),
        };
        const { data } = await axiosInstance.post(URLS.getCostingDetails(selectedOrgId), requestBody);
        setPageResponse(data);
        setContinueDisabled(false);
      }
    } catch (error: any) {
      setContinueDisabled(true);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response?.data.error_description
          ? error.response?.data.error_description
          : error.response?.data.message,
      });
    } finally {
      setCallAPi(true);
    }
  }, [rooms, selectedVenues]);

  useEffect(() => {
    getCostingData();
  }, [getCostingData]);

  const getInitialData = useCallback(async () => {
    try {
      const requestBody = {
        orgId: selectedOrgId,
        eventType: searchData.eventType,
        bookingStartDate: dayjs(searchData.startDate as string).format('DD-MM-YYYY'),
        bookingEndDate: dayjs(searchData.endDate as string).format('DD-MM-YYYY'),
        guests: searchData.guests,
        rooms: selectedRooms !== null ? selectedRooms : rooms,
        venues: selectedVenuesFromStorage
          .map((venue: any) =>
            venue.venues.map((item: any) => ({
              id: item.id,
              bookingDate: item.bookingDate,
              layout: item.layout,
              guaranteedGuests: item.guaranteedGuests,
              addOns: item.addOns,
              timeSlot: searchData.timeSlot,
              pricePackageId: item.pricePackageId,
              // "pricePackageIdNew": true
            })),
          )
          .flat(),
      };
      setLoading(true);
      const constingRes = await axiosInstance.post(URLS.getCostingDetails(selectedOrgId), requestBody);
      const roomsListRes = await axiosInstance.get(URLS.getRoomsList(selectedOrgId));
      const eventTypeRes = await axiosInstance.get(URLS.getEventType(searchData.eventType));
      const timeSlotRes = await axiosInstance.get(URLS.getTimeSlot(searchData.timeSlot));
      const filter = roomsListRes.data.map((item: any) => {
        return { label: item.name, value: item.id, price: item.price };
      });
      setRoomsList(filter);
      setPageResponse(constingRes.data);
      setSelectedEventType(eventTypeRes.data);
      setSelectedTimeSlot(timeSlotRes.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response?.data?.error_description
          ? error.response?.data.error_description
          : error.response?.data.message,
      });
    }
  }, []);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const handleProceed = () => {
    const arr = selectedVenues.map((item: any) => item.venues.filter((vItem: any) => vItem.guaranteedGuests === ''));
    const arr2 = [...new Set(arr.flat(1).map((i: any) => i.name))];
    if (arr2.length > 0) {
      setMessage({
        display: true,
        severity: 'error',
        message: `${strings.noOfGuestRequiredText} ${arr2.join(', ')}`,
      });
    } else {
      dispatch(setSelectedVenuesForCosting(selectedVenues));
      dispatch(setSelectedRoomsCosting(rooms));
      isLogin ? navigate(routes.payment_summary) : dispatch(setOpenAuthenticationDrawer(true));
    }
  };

  const breadcrumbs = [
    <Link key="1" href="/">
      <Box>
        <HomeRoundedIcon color="action" />
      </Box>
    </Link>,
    <Box
      key="2"
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
        p: 2,
      }}
      onClick={() => navigate(routes.available_venues)}>
      <Typography fontWeight="400" sx={{ [theme.breakpoints.down('sm')]: { fontSize: '11px' } }}>
        {' '}
        {strings.breadcrumb_text_venueList}{' '}
      </Typography>
    </Box>,
    <Link underline="none" key="3" color="primary">
      <Typography fontWeight="bold" sx={{ [theme.breakpoints.down('sm')]: { fontSize: '11px' } }}>
        {' '}
        {strings.breadcrumb_text_orderSummary}
      </Typography>
    </Link>,
    <Typography key="4" fontWeight="400" sx={{ [theme.breakpoints.down('sm')]: { fontSize: '11px' } }}>
      {' '}
      {strings.breadcrumb_text_payment}
    </Typography>,
  ];
  let count = 0;
  return (
    <>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
          autoHideDuration={6000}
        />
      )}
      <Box
        sx={{
          paddingLeft: '4%',
          paddingRight: '3%',
          position: 'relative',
          mt: 2,
        }}>
        {orgData && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={7.5} lg={8.5}>
              <Box display="flex" alignItems={'center'} gap={2} justifyContent={'space-between'}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  {breadcrumbs}
                </Breadcrumbs>
                {screenSizeDownSm ? (
                  <SearchIcon onClick={() => navigate(routes.available_venues)} color="primary" />
                ) : (
                  <PrimaryButton onClick={() => {
                    if (location.search.includes('oid')) {
                      navigate(`${routes.root}${location.search}`);
                    } else {
                      navigate(routes.available_venues);
                    }
                  }}>
                    <Box display="flex" alignItems={'center'} gap={1}>
                      <SearchIcon />
                      {!screenSizeDownLg && <Typography>{strings.bookBanquetModifyText}</Typography>}
                    </Box>
                  </PrimaryButton>
                )}
              </Box>
              <Box position="relative">
                {!orgData.videoBanner ? (
                  <img
                    height={screenSizeDownSm ? '370vh' : '300px'}
                    width={'100%'}
                    src={getBaseUrl() + orgData.imgUrl}
                  />
                ) : (
                  <Box
                    height={screenSizeDownSm ? '370vh' : '479px'}
                    sx={{
                      width: '100%',
                      backgroundColor: 'lightgrey',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Typography>{strings.noImageAvailableText}</Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingX: screenSizeDownSm ? 2 : 5,
                    paddingY: 1,
                    background: 'rgba(0, 0, 0, 0.3)',
                    backdropFilter: 'blur(5px)',
                    alignItems: 'center',
                    bottom: '0em',
                  }}>
                  <Box
                    sx={{
                      width: '100%',
                      color: 'white',
                    }}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1.5}>
                    <Grid container gap={screenSizeDownSm ? 1 : 5}>
                      <Grid item>
                        <Typography display={'flex'} alignItems="center" gap={1} fontSize={'0.75rem'}>
                          <LocationOnOutlinedIcon fontSize="small" />
                          {orgData.location?.label}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography display={'flex'} alignItems="center" gap={1} fontSize={'0.75rem'}>
                          <EventAvailableOutlinedIcon fontSize="small" />
                          {selectedEventType.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography display={'flex'} alignItems="center" gap={1} fontSize={'0.75rem'}>
                          <PeopleAltOutlinedIcon fontSize="small" />
                          {searchData.guests}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container gap={screenSizeDownSm ? 1 : 5}>
                      <Grid item>
                        <Typography display={'flex'} alignItems="center" gap={1} fontSize={'0.75rem'}>
                          <CalendarMonthOutlinedIcon fontSize="small" />
                          {dayjs(searchData.startDate).format('DD/MM/YYYY')} -{' '}
                          {dayjs(searchData.endDate).format('DD/MM/YYYY')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography display={'flex'} alignItems="center" gap={1} fontSize={'0.75rem'}>
                          <ScheduleOutlinedIcon fontSize="small" />
                          {selectedTimeSlot.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              {orgData.roomsAvailable && (
                <AddEditBookingRooms
                  rooms={rooms}
                  setRooms={setRooms}
                  roomsList={roomsList}
                  isEditableDataDrid={true}
                />
              )}
              {orgData.roomReservationText && !orgData.roomsAvailable && (
                <Card sx={{ mt: 2 }}>
                  <CardContent sx={{ py: 3 }}>
                    <Typography sx={{ p: 2 }} dangerouslySetInnerHTML={{ __html: orgData.roomReservationText }} />
                  </CardContent>
                </Card>
              )}
              <AddOnAccordian
                selectedVenues={selectedVenues}
                setSelectedVenues={setSelectedVenues}
                setCallAPi={setCallAPi}
              />
            </Grid>
            <Grid item xs={12} md={4.5} lg={3.5} mt={1}>
              <Box position={'sticky'} top="0px" width={'100%'}>
                <Box display="flex" alignItems="center" mb={1} mx={1} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'primary'}>
                    {strings.chargesSummaryText}
                  </Typography>
                  <PrimaryButton onClick={() => handleProceed()} disabled={continueDisabled}>
                    {strings.continueText}
                  </PrimaryButton>
                  {/* <Typography variant='h6' color={'primary'} onClick={() => handleProceed()} sx={{cursor: 'pointer'}}>{strings.continueText}</Typography> */}
                </Box>
                <Card>
                  <CardContent sx={{ px: 3.5 }}>
                    {pageResponse?.lineItems?.map((item: any, idx: any) => (
                      <>
                        {item.header ? (
                          <>
                            {idx !== 0 && (
                              <Divider
                                sx={{
                                  my: 2,
                                  borderStyle: 'dashed',
                                  borderColor: theme.palette.secondary.main,
                                }}
                              />
                            )}
                            <Typography fontSize={'0.75rem'} fontWeight={500} mb={1}>
                              {item.text}
                            </Typography>
                          </>
                        ) : (
                          <Grid container mb={0.5}>
                            <Grid item xs={0.8}>
                              <CircleIcon sx={{ fontSize: '8px' }} />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography mr={1} fontSize={'0.75rem'}>
                                {item.text}
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography flex={4} textAlign={'end'} fontSize={'0.75rem'}>
                                {item.amount}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    ))}
                    <Divider
                      sx={{
                        my: 2,
                        borderStyle: 'dashed',
                        borderColor: theme.palette.secondary.main,
                      }}
                    />
                    <Box display={'flex'} justifyContent="space-between" mb={3}>
                      <Typography color={'primary'} fontWeight={600} flex={5} mr={1}>
                        {strings.totalText}
                      </Typography>
                      <Typography color={'primary'} fontWeight={600} flex={4} textAlign={'end'}>
                        {pageResponse.total}
                      </Typography>
                    </Box>
                    {/* <PrimaryButton fullWidth onClick={() => handleProceed()}>{strings.continueText}</PrimaryButton> */}
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default OrderSummary;