import { useState } from 'react';
import {
    Grid,
    Typography,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import strings from 'common/Translation/Translate';
import { RoomLayoutCard } from './RoomLayoutCard';

type VenueWiseAddOnsProps = {
    venueItem: any;
    selectedVenues: any;
    setSelectedVenues: any;
    item: any;
};

export const RoomLayout = ({ item, venueItem, selectedVenues, setSelectedVenues }: VenueWiseAddOnsProps) => {
    const theme = useTheme();
    const [selectedLayout, setSelectedLayout] = useState<any>(null);

    const getVenueArray = (item: any) => {
        const idx1 = selectedVenues.findIndex((ele: any) => ele.date === item.bookingDate)
        const idx2 = selectedVenues[idx1].venues.findIndex((ele: any) => ele.id === item.id)
        const array = [...selectedVenues]
        const venuesArray = [...array[idx1].venues]
        return { array, venuesArray, idx1, idx2 };
    }
    
    const handleRoomLayoutCardClick = (id: any) => {
        setSelectedLayout(id);
        const { array, venuesArray, idx1, idx2 } = getVenueArray(item);
        venuesArray[idx2] = { ...venuesArray[idx2], layout: id.toString() };
        array[idx1] = {
            date: selectedVenues[idx1].date,
            venues: venuesArray
        }
        setSelectedVenues(array);
    };

    return (
        <Box pb={2}>
            <Box sx={{ width: '100%', backgroundColor: theme.palette.secondary.light, height: '35px' }} px={2} display='flex' alignItems={'center'}>
                <Typography fontWeight={500} fontSize={'12px'}>{strings.roomLayout}</Typography>                
            </Box>
            <Grid container spacing={2} mt={0.5}>
                {item.layouts.map((layoutItem: any) => 
                    <RoomLayoutCard
                        key={layoutItem.id}
                        layoutItem={layoutItem}
                        selectedItem={selectedLayout}
                        handleClick={handleRoomLayoutCardClick} 
                    />)}
            </Grid>
        </Box>
    )
};