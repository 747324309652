export const languages = [{ text: 'English', value: 'en_IN' }];

export const addonsCategory = (strings: any) => {
  return [
    { value: 'FOOD', label: strings.dropdown_AddOns_Category_food_text },
    { value: 'LIQUOR', label: strings.dropdown_AddOns_Category_liquor_text },
    {
      value: 'ACCESSORIES',
      label: strings.dropdown_AddOns_Category_accessories_text,
    },
    { value: 'OTHERS', label: strings.dropdown_AddOns_Category_others_text },
  ];
};
export const addonsType = (strings: any) => {
  return [
    { value: 'PER_GUEST', label: strings.dropdown_AddOnType_per_guest_text },
    {
      value: 'PER_QUANTITY',
      label: strings.dropdown_AddOnType_per_quantity_text,
    },
  ];
};

export const customerType = (strings: any) => {
  return [
    { label: strings.customermgmt_text_individual, value: 'No' },
    { label: strings.customermgmt_text_Company, value: 'Yes' },
  ];
};

export const PartOfDay = (strings: any) => {
  return [
    { id: 'MORNING', name: strings.dropdown_PartOfDay_morning_text },
    { id: 'EVENING', name: strings.dropdown_PartOfDay_evening_text },
  ];
};
export const dataFlag = (strings: any) => {
  return [
    { id: true, name: strings.dropdown_yes_text },
    { id: false, name: strings.dropdown_no_text },
  ];
};
export const dataFlag1 = (strings: any) => {
  return [
    { value: true, label: strings.dropdown_yes_text },
    { value: 'false', label: strings.dropdown_no_text },
  ];
};
export const status = (strings: any) => {
  return [
    { id: 'CONFIRMED', name: strings.paymentStatus_Dropdown_confirmedText },
    { id: 'TENTATIVE', name: strings.paymentStatus_Dropdown_tentativeText },
    { id: 'WAITLISTED', name: strings.paymentStatus_Dropdown_waitlistText },
  ];
};

export const paymentMethod = (strings: any) => {
  return [
    { id: 'CASH', name: strings.dropdown_cash_text },
    { id: 'DEBIT_CARD', name: strings.dropdown_debit_card_text },
    { id: 'CREDIT_CARD', name: strings.dropdown_credit_card_text },
    { id: 'NETBANKING', name: strings.dropdown_netBanking_text },
    { id: 'CHEQUE', name: strings.dropdown_cheque_text },
  ];
};

export interface PageProps {
  page?: number;
  size?: number;
  filter?: string[];
  sort?: string[];
}

export const bookingConfirmationBy = (strings: any) => {
  return [
    { id: 'VENUE', name: strings.dropdown_bookingConfirmationBy_venue_text },
    {
      id: 'BLOCK_PAY',
      name: strings.dropdown_bookingConfirmationBy_block_pay_text,
    },
    {
      id: 'FULL_PAY',
      name: strings.dropdown_bookingConfirmationBy_full_pay_text,
    },
  ];
};
export const bookingConfirmationBy1 = (strings: any) => {
  return [
    {
      value: 'VENUE',
      label: strings.dropdown_bookingConfirmationBy_venue_text,
    },
    {
      value: 'BLOCK_PAY',
      label: strings.dropdown_bookingConfirmationBy_block_pay_text,
    },
    {
      value: 'FULL_PAY',
      label: strings.dropdown_bookingConfirmationBy_full_pay_text,
    },
  ];
};
export const editRequestData = (strings: any) => {
  return [
    { id: 'OPEN', name: strings.dropdown_open_text },
    { id: 'IN_PROGRESS', name: strings.dropdown_inProgress_text },
    { id: 'CLOSED', name: strings.dropdown_close_text },
  ];
};
export const WeekDays = [
  { id: 'Sun', name: 'Sun' },
  { id: 'Mon', name: 'Mon' },
  { id: 'Tue', name: 'Tue' },
  { id: 'Wed', name: 'Wed' },
  { id: 'Thu', name: 'Thu' },
  { id: 'Fri', name: 'Fri' },
  { id: 'Sat', name: 'Sat' },
];

export const countryList = () => {
  return [
    { value: 'AC', label: 'AC' },
    { value: 'AD', label: 'AD' },
    { value: 'AE', label: 'AE' },
    { value: 'AF', label: 'AF' },
    { value: 'AG', label: 'AG' },
    { value: 'AI', label: 'AI' },
    { value: 'AL', label: 'AL' },
    { value: 'AM', label: 'AM' },
    { value: 'AO', label: 'AO' },
    { value: 'AR', label: 'AR' },
    { value: 'AS', label: 'AS' },
    { value: 'AT', label: 'AT' },
    { value: 'AU', label: 'AU' },
    { value: 'AW', label: 'AW' },
    { value: 'AX', label: 'AX' },
    { value: 'AZ', label: 'AZ' },
    { value: 'BA', label: 'BA' },
    { value: 'BB', label: 'BB' },
    { value: 'BD', label: 'BD' },
    { value: 'BE', label: 'BE' },
    { value: 'BF', label: 'BF' },
    { value: 'BG', label: 'BG' },
    { value: 'BH', label: 'BH' },
    { value: 'BI', label: 'BI' },
    { value: 'BJ', label: 'BJ' },
    { value: 'BL', label: 'BL' },
    { value: 'BM', label: 'BM' },
    { value: 'BN', label: 'BN' },
    { value: 'BO', label: 'BO' },
    { value: 'BQ', label: 'BQ' },
    { value: 'BR', label: 'BR' },
    { value: 'BS', label: 'BS' },
    { value: 'BT', label: 'BT' },
    { value: 'BW', label: 'BW' },
    { value: 'BY', label: 'BY' },
    { value: 'BZ', label: 'BZ' },
    { value: 'CA', label: 'CA' },
    { value: 'CC', label: 'CC' },
    { value: 'CD', label: 'CD' },
    { value: 'CF', label: 'CF' },
    { value: 'CG', label: 'CG' },
    { value: 'CH', label: 'CH' },
    { value: 'CI', label: 'CI' },
    { value: 'CK', label: 'CK' },
    { value: 'CL', label: 'CL' },
    { value: 'CM', label: 'CM' },
    { value: 'CN', label: 'CN' },
    { value: 'CO', label: 'CO' },
    { value: 'CR', label: 'CR' },
    { value: 'CU', label: 'CU' },
    { value: 'CV', label: 'CV' },
    { value: 'CW', label: 'CW' },
    { value: 'CX', label: 'CX' },
    { value: 'CY', label: 'CY' },
    { value: 'CZ', label: 'CZ' },
    { value: 'DE', label: 'DE' },
    { value: 'DJ', label: 'DJ' },
    { value: 'DK', label: 'DK' },
    { value: 'DM', label: 'DM' },
    { value: 'DO', label: 'DO' },
    { value: 'DZ', label: 'DZ' },
    { value: 'EC', label: 'EC' },
    { value: 'EE', label: 'EE' },
    { value: 'EG', label: 'EG' },
    { value: 'EH', label: 'EH' },
    { value: 'ER', label: 'ER' },
    { value: 'ES', label: 'ES' },
    { value: 'ET', label: 'ET' },
    { value: 'FI', label: 'FI' },
    { value: 'FJ', label: 'FJ' },
    { value: 'FK', label: 'FK' },
    { value: 'FM', label: 'FM' },
    { value: 'FO', label: 'FO' },
    { value: 'FR', label: 'FR' },
    { value: 'GA', label: 'GA' },
    { value: 'GB', label: 'GB' },
    { value: 'GD', label: 'GD' },
    { value: 'GE', label: 'GE' },
    { value: 'GF', label: 'GF' },
    { value: 'GG', label: 'GG' },
    { value: 'GH', label: 'GH' },
    { value: 'GI', label: 'GI' },
    { value: 'GL', label: 'GL' },
    { value: 'GM', label: 'GM' },
    { value: 'GN', label: 'GN' },
    { value: 'GP', label: 'GP' },
    { value: 'GQ', label: 'GQ' },
    { value: 'GR', label: 'GR' },
    { value: 'GT', label: 'GT' },
    { value: 'GU', label: 'GU' },
    { value: 'GW', label: 'GW' },
    { value: 'GY', label: 'GY' },
    { value: 'HK', label: 'HK' },
    { value: 'HN', label: 'HN' },
    { value: 'HR', label: 'HR' },
    { value: 'HT', label: 'HT' },
    { value: 'HU', label: 'HU' },
    { value: 'ID', label: 'ID' },
    { value: 'IE', label: 'IE' },
    { value: 'IL', label: 'IL' },
    { value: 'IM', label: 'IM' },
    { value: 'IN', label: 'IN' },
    { value: 'IO', label: 'IO' },
    { value: 'IQ', label: 'IQ' },
    { value: 'IR', label: 'IR' },
    { value: 'IS', label: 'IS' },
    { value: 'IT', label: 'IT' },
    { value: 'JE', label: 'JE' },
    { value: 'JM', label: 'JM' },
    { value: 'JO', label: 'JO' },
    { value: 'JP', label: 'JP' },
    { value: 'KE', label: 'KE' },
    { value: 'KG', label: 'KG' },
    { value: 'KH', label: 'KH' },
    { value: 'KI', label: 'KI' },
    { value: 'KM', label: 'KM' },
    { value: 'KN', label: 'KN' },
    { value: 'KP', label: 'KP' },
    { value: 'KR', label: 'KR' },
    { value: 'KW', label: 'KW' },
    { value: 'KY', label: 'KY' },
    { value: 'KZ', label: 'KZ' },
    { value: 'LA', label: 'LA' },
    { value: 'LB', label: 'LB' },
    { value: 'LC', label: 'LC' },
    { value: 'LI', label: 'LI' },
    { value: 'LK', label: 'LK' },
    { value: 'LR', label: 'LR' },
    { value: 'LS', label: 'LS' },
    { value: 'LT', label: 'LT' },
    { value: 'LU', label: 'LU' },
    { value: 'LV', label: 'LV' },
    { value: 'LY', label: 'LY' },
    { value: 'MA', label: 'MA' },
    { value: 'MC', label: 'MC' },
    { value: 'MD', label: 'MD' },
    { value: 'ME', label: 'ME' },
    { value: 'MF', label: 'MF' },
    { value: 'MG', label: 'MG' },
    { value: 'MH', label: 'MH' },
    { value: 'MK', label: 'MK' },
    { value: 'ML', label: 'ML' },
    { value: 'MM', label: 'MM' },
    { value: 'MN', label: 'MN' },
    { value: 'MO', label: 'MO' },
    { value: 'MP', label: 'MP' },
    { value: 'MQ', label: 'MQ' },
    { value: 'MR', label: 'MR' },
    { value: 'MS', label: 'MS' },
    { value: 'MT', label: 'MT' },
    { value: 'MU', label: 'MU' },
    { value: 'MV', label: 'MV' },
    { value: 'MW', label: 'MW' },
    { value: 'MX', label: 'MX' },
    { value: 'MY', label: 'MY' },
    { value: 'MZ', label: 'MZ' },
    { value: 'NA', label: 'NA' },
    { value: 'NC', label: 'NC' },
    { value: 'NE', label: 'NE' },
    { value: 'NF', label: 'NF' },
    { value: 'NG', label: 'NG' },
    { value: 'NI', label: 'NI' },
    { value: 'NL', label: 'NL' },
    { value: 'NO', label: 'NO' },
    { value: 'NP', label: 'NP' },
    { value: 'NR', label: 'NR' },
    { value: 'NU', label: 'NU' },
    { value: 'NZ', label: 'NZ' },
    { value: 'OM', label: 'OM' },
    { value: 'PA', label: 'PA' },
    { value: 'PE', label: 'PE' },
    { value: 'PF', label: 'PF' },
    { value: 'PG', label: 'PG' },
    { value: 'PH', label: 'PH' },
    { value: 'PK', label: 'PK' },
    { value: 'PL', label: 'PL' },
    { value: 'PM', label: 'PM' },
    { value: 'PR', label: 'PR' },
    { value: 'PS', label: 'PS' },
    { value: 'PT', label: 'PT' },
    { value: 'PW', label: 'PW' },
    { value: 'PY', label: 'PY' },
    { value: 'QA', label: 'QA' },
    { value: 'RE', label: 'RE' },
    { value: 'RO', label: 'RO' },
    { value: 'RS', label: 'RS' },
    { value: 'RU', label: 'RU' },
    { value: 'RW', label: 'RW' },
    { value: 'SA', label: 'SA' },
    { value: 'SB', label: 'SB' },
    { value: 'SC', label: 'SC' },
    { value: 'SD', label: 'SD' },
    { value: 'SE', label: 'SE' },
    { value: 'SG', label: 'SG' },
    { value: 'SH', label: 'SH' },
    { value: 'SI', label: 'SI' },
    { value: 'SJ', label: 'SJ' },
    { value: 'SK', label: 'SK' },
    { value: 'SL', label: 'SL' },
    { value: 'SM', label: 'SM' },
    { value: 'SN', label: 'SN' },
    { value: 'SO', label: 'SO' },
    { value: 'SR', label: 'SR' },
    { value: 'SS', label: 'SS' },
    { value: 'ST', label: 'ST' },
    { value: 'SV', label: 'SV' },
    { value: 'SX', label: 'SX' },
    { value: 'SY', label: 'SY' },
    { value: 'SZ', label: 'SZ' },
    { value: 'TA', label: 'TA' },
    { value: 'TC', label: 'TC' },
    { value: 'TD', label: 'TD' },
    { value: 'TG', label: 'TG' },
    { value: 'TH', label: 'TH' },
    { value: 'TJ', label: 'TJ' },
    { value: 'TK', label: 'TK' },
    { value: 'TL', label: 'TL' },
    { value: 'TM', label: 'TM' },
    { value: 'TN', label: 'TN' },
    { value: 'TO', label: 'TO' },
    { value: 'TR', label: 'TR' },
    { value: 'TT', label: 'TT' },
    { value: 'TV', label: 'TV' },
    { value: 'TW', label: 'TW' },
    { value: 'TZ', label: 'TZ' },
    { value: 'UA', label: 'UA' },
    { value: 'UG', label: 'UG' },
    { value: 'US', label: 'US' },
    { value: 'UY', label: 'UY' },
    { value: 'UZ', label: 'UZ' },
    { value: 'VA', label: 'VA' },
    { value: 'VC', label: 'VC' },
    { value: 'VE', label: 'VE' },
    { value: 'VG', label: 'VG' },
    { value: 'VI', label: 'VI' },
    { value: 'VN', label: 'VN' },
    { value: 'VU', label: 'VU' },
    { value: 'WF', label: 'WF' },
    { value: 'WS', label: 'WS' },
    { value: 'XK', label: 'XK' },
    { value: 'YE', label: 'YE' },
    { value: 'YT', label: 'YT' },
    { value: 'ZA', label: 'ZA' },
    { value: 'ZM', label: 'ZM' },
    { value: 'ZW', label: 'ZW' },
  ];
};

