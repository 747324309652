import { FormModel } from 'components/Form';
import dayjs from 'dayjs';
import { Validators } from 'utils/validators';

export const BookingRoomsForm = (
  roomsList: [],
  strings: any,
  roomPrice: any,
  mealPlanList: any,
  mealPrice: any,
): FormModel[] => {
  const today = dayjs(new Date());
  let array = [
    {
      label: strings.venueDetailsRoomType,
      value: {},
      size: 'small',
      type: 'drop-down',
      placeholder: '',
      field: 'roomType',
      validators: [{ check: Validators.required, message: strings.requiredField }],
      required: true,
      responsive: { xs: 12 },
      options: roomsList,
      additionalText: roomPrice,
    },
    {
      label: strings.venueDetailsFrom,
      value: '',
      size: 'small',
      autoFocus: true,
      type: 'date',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'from',
      validators: [{ check: Validators.required, message: strings.requiredField }],
      responsive: { xs: 12 },
      required: true,
      minDate: today,
    },
    {
      label: strings.venueDetailsTo,
      value: '',
      size: 'small',
      autoFocus: false,
      type: 'date',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'to',
      validators: [{ check: Validators.required, message: strings.requiredField }],
      responsive: { xs: 12 },
      required: true,
      minDate: today,
    },
    {
      label: strings.venueDetailsNoOfRooms,
      value: '',
      size: 'small',
      autoFocus: false,
      type: 'text',
      typeValue: 'number',
      variant: 'outlined',
      placeholder: '',
      field: 'numberOfRooms',
      validators: [
        { check: Validators.required, message: strings.requiredField },
        { check: Validators.noRoom, message: strings.required_valid_no },
        { check: Validators.number, message: strings.required_valid_no },
      ],
      responsive: { xs: 12 },
      required: true,
    },
  ];

  if (mealPlanList.length > 0) {
    array.push({
      label: strings.venueDetailsMealPlan,
      value: '',
      type: 'drop-down',
      placeholder: '',
      field: 'mealPlanId',
      validators: [{ check: Validators.required, message: strings.requiredField }],
      required: true,
      responsive: { xs: 12 },
      options: mealPlanList,
      size: 'small',
      additionalText: mealPrice,
    });
  }

  return array;
};
