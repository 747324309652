import { AnyAction, configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { ThunkMiddlewareFor } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import authenticationSlice from "../reducer/authentication-slice";
import costingSlice from "../reducer/costing-slice";
import notificationDetailsSlice from "../reducer/Notification-details-slice";
import searchedDataSlice from "../reducer/searched-data-slice";


const store: EnhancedStore<any, AnyAction, [ThunkMiddlewareFor<any>]> =
  configureStore({
    reducer: {
      authenticationSlice,
      notificationDetailsSlice,
      searchedDataSlice,
      costingSlice
    },
  });

export default store;
