import { InfoOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import Accessories from 'assets/images/Accessories';
import Food from 'assets/images/Food';
import Liquor from 'assets/images/Liquor';
import Others from 'assets/images/Others';
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import { ToolTip } from 'components/Tooltip/ToolTip';
import { useEffect, useState } from 'react';
import { getBaseUrl } from 'utils/validators/HelperFunctions';

const GetAddOnImage = ({ imgUrl, type }: { imgUrl: any; type: any }) => {
  if (imgUrl) return <img height={'40px'} width={'40px'} src={getBaseUrl() + imgUrl} alt="alt" />;
  else if (type === 'FOOD') return <Food />;
  else if (type === 'LIQUOR') return <Liquor />;
  else if (type === 'ACCESSORIES') return <Accessories />;
  else if (type === 'OTHERS') return <Others />;
  else return null;
};

type AddOnsTableProps = {
  item: any;
  addOnList: any;
  type: string;
  selectedVenues: any;
  setMessage: any;
  setSelectedVenues: any;
  setOpenDrawer: any;
};

export const AddOnsTable = ({
  item,
  addOnList,
  type,
  selectedVenues,
  setMessage,
  setSelectedVenues,
  setOpenDrawer,
}: AddOnsTableProps) => {
  const getVenueArray = (item: any) => {
    const idx1 = selectedVenues.findIndex((ele: any) => ele.date === item.bookingDate);
    const idx2 = selectedVenues[idx1].venues.findIndex((ele: any) => ele.id === item.id);
    const array = [...selectedVenues];
    const venuesArray = [...array[idx1].venues];
    return { array, venuesArray, idx1, idx2 };
  };
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedAddOnsLocal, setSelectedAddOnsLocal] = useState<any>([]);

  useEffect(() => {
    const { venuesArray, idx2 } = getVenueArray(item);
    setSelectedAddOnsLocal(venuesArray[idx2].addOns);
  }, []);

  const getChecked = (rowId: any) => {
    const idx = selectedAddOnsLocal.findIndex((item: any) => item.id === rowId);
    return idx !== -1 && selectedAddOnsLocal[idx].selected;
  };

  const getQuantity = (rowId: any) => {
    return selectedAddOnsLocal.find((item: any) => item.id === rowId)?.quantity;
  };

  console.log('selectedAddOnsLocal: ', selectedAddOnsLocal);
  const handleCheck = (event: { target: { checked: boolean } }, rowData: any) => {
    if (event.target.checked) {
      const array = [...selectedAddOnsLocal];
      const idx = selectedAddOnsLocal.findIndex((i: any) => rowData.id === i.id);
      if (idx === -1) array.push({ ...rowData, selected: true });
      else array[idx] = { ...array[idx], selected: true };
      setSelectedAddOnsLocal(array);
    } else {
      const idx = selectedAddOnsLocal.findIndex((i: any) => rowData.id === i.id);
      const array = [...selectedAddOnsLocal];
      array[idx] = { ...array[idx], selected: false, quantity: '' };
      setSelectedAddOnsLocal(array);
    }
  };

  const handleIncrement = (rowData: any) => {
    let quantity = getQuantity(rowData.id);
    if (quantity === undefined || quantity === '') {
      quantity = 1;
    } else {
      quantity = parseInt(quantity) + 1;
    }
    handleChange(quantity.toString(), rowData);
  };

  const handleDecrement = (rowData: any) => {
    let quantity = getQuantity(rowData.id);
    if (quantity === undefined || quantity === '0' || quantity === '') {
      quantity = 0;
    } else {
      quantity = parseInt(quantity) - 1;
    }
    handleChange(quantity.toString(), rowData);
  };

  const handleChangeHelper = (value: string, rowData: any) => {
    if (parseInt(value) > rowData.maxCount) {
      setMessage({
        display: true,
        severity: 'warning',
        message: 'You cannot add more than ' + rowData.maxCount + ' ' + rowData.name,
      });
    } else {
      const idx = selectedAddOnsLocal.findIndex((i: any) => rowData.id === i.id);
      if (idx !== -1) {
        const arr = [...selectedAddOnsLocal];
        arr[idx] = { ...arr[idx], quantity: value };
        setSelectedAddOnsLocal(arr);
      }
    }
  };

  const handleChange = (value: string, rowData: any) => {
    if (!Number.isNaN(Number(value))) {
      handleChangeHelper(value, rowData);
    }
  };

  const handleSave = () => {
    const filtered_selectedAddOnsLocal = selectedAddOnsLocal.filter((i: any) => i.selected);
    const arr = filtered_selectedAddOnsLocal
      .filter((i: any) => {
        if (i.addOnType === 'PER_QUANTITY' && (i.quantity === undefined || i.quantity === '0' || i.quantity === '')) {
          return i;
        }
      })
      .map((i: any) => `"${i.name}"`);
    if (arr.length > 0) {
      setMessage({
        display: true,
        severity: 'error',
        message: `${strings.addOnQuantityErrorMsg} ${arr.join(', ')}`,
      });
    } else {
      const { array, venuesArray, idx1, idx2 } = getVenueArray(item);
      venuesArray[idx2] = { ...venuesArray[idx2], addOns: filtered_selectedAddOnsLocal };
      array[idx1] = {
        date: selectedVenues[idx1].date,
        venues: venuesArray,
      };
      setSelectedVenues(array);
      setOpenDrawer(false);
    }
  };



  const columns = [
    {
      field: 'name',
      headerName: strings.addOnsText,
      sortable: true,
      flex: 12,
      minWidth: 250,

      renderCell: (ValueFormatterParams: any) => {
        return (
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Box
              height={'40px'}
              width={'40px'}
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // padding: '3px',
                marginRight: 1,
              }}>
              <GetAddOnImage imgUrl={ValueFormatterParams.row.imgUrl} type={type} />
            </Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getChecked(ValueFormatterParams.row.id)}
                    onChange={e => handleCheck(e, ValueFormatterParams.row)}
                  />
                }
                label={
                  <Box display={'flex'} alignItems={'center'} gap={1}>
                    {ValueFormatterParams.row.description &&
                      <ToolTip title={
                        <Typography dangerouslySetInnerHTML={{ __html: ValueFormatterParams.row.description }} />
                      }>
                        <InfoOutlined fontSize='small' />
                      </ToolTip>
                    }
                    <ToolTip title={ValueFormatterParams.row.name}>
                      <Typography>{ValueFormatterParams.row.name}</Typography>
                    </ToolTip>
                  </Box>
                }
              // sx={{ whiteSpace: screenSizeUpLg ? 'normal' : 'nowrap' }}
              />
            </FormGroup>
          </Box>
        );
      },
    },
    { field: 'addOnType', sortable: true, headerName: strings.typeText, flex: 1, minWidth: 120 },
    { field: 'formattedPrice', sortable: true, headerName: strings.priceText, flex: 1, minWidth: 100 },
    { field: 'maxCount', sortable: true, headerName: strings.maxCountText, flex: 1, minWidth: 100 },
    {
      field: 'quantity',
      headerName: strings.quantityText,
      sortable: true,
      flex: 6,
      minWidth: 150,

      renderCell: (ValueFormatterParams: any) => {
        return ValueFormatterParams.row.addOnType === 'PER_QUANTITY' ? (
          <Grid container alignItems={'center'} justifyContent="space-evenly">
            <Grid item xs={1.5}>
              <RemoveIcon
                sx={{ cursor: 'pointer', color: `${theme.palette.secondary.main} !important` }}
                onClick={() => handleDecrement(ValueFormatterParams.row)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{
                  mx: 0,
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.secondary.main,
                    borderRadius: '0%',
                  },
                  '.MuiInputBase-root': {
                    backgroundColor: theme.palette.secondary.light,
                  },
                  '.MuiInputBase-root.Mui-disabled, .MuiInputBase-input.Mui-disabled': {
                    backgroundColor: '#E7E7E7',
                    borderRadius: 0,
                    borderColor: '#9B9B9B',
                    color: '#151515',
                  },
                  'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                }}
                type="text"
                size="small"
                required
                id={ValueFormatterParams.row.id}
                // autoFocus={(ValueFormatterParams.row.id + '' + item.id + '' + venueItem) === focusedInputId}
                // onBlur={() => dispatch(setFocusedInput(false))}
                disabled={!getChecked(ValueFormatterParams.row.id)}
                value={getQuantity(ValueFormatterParams.row.id)}
                onChange={e => {
                  handleChange(e.target.value, ValueFormatterParams.row);
                }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <AddIcon
                sx={{ cursor: 'pointer', color: `${theme.palette.secondary.main} !important` }}
                onClick={() => handleIncrement(ValueFormatterParams.row)}
              />
            </Grid>
          </Grid>
        ) : (
          <span></span>
        );
      },
    },
  ];

  const getWidth = () => {
    if (screenSizeDownMd) return '40%'
    else if (screenSizeDownSm) return '100%'
    else return '20%'
  }

  return (
    <>
      {addOnList?.length > 0 ? (
        <div style={{ width: '100%' }}>
          <div style={{ minHeight: '150px', width: '100%', marginTop: 10 }}>
            <DataGrid
              sx={{
                whiteSpace: 'nowrap',
                '.MuiDataGrid-columnHeaders': {
                  backgroundColor: theme.palette.secondary.dark,
                },
                border: 0,
                overflowY: 'auto',
                '.MuiDataGrid-cellContent': {
                  textOverflow: 'clip',
                  fontSize: '0.68rem',
                  fontWeight: 500,
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#fafafa',
                },
                '& .MuiDataGrid-row.Mui-selected': {
                  backgroundColor: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: theme.palette.primary.main,
                },
                '& .MuiDataGrid-iconSeparator': {
                  color: theme.palette.secondary.light,
                },
                '& .MuiTypography-root': {
                  fontSize: '0.68rem',
                  fontWeight: 500,
                },
              }}
              disableColumnSelector
              isRowSelectable={() => false}
              rowHeight={65}
              rows={addOnList}
              autoHeight={true}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[3]}

            />
          </div>
        </div>
      ) : null}
      <PrimaryButton
        onClick={handleSave}
        sx={{ my: 2, width: getWidth() }}>
        {strings.save}
      </PrimaryButton>
    </>
  );
};
