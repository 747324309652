import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AlertColor, Card, Chip, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import Notification from 'components/Notification';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BookingRoomsTable } from 'screens/OrderSummary/AddEditBookingRooms/AddEditBookingRooms';
import { routes } from 'utils/constants/routes';
import { TabPanel } from 'utils/validators/HelperComponents';
import { EditBookingFormDialog } from '../EditBookingFormDialog';
import { AddOnsModal } from './AddOnsModal';

function a12yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface BookingDetailsCardProps {
  booking: any;
  upcomingBooking: boolean;
}

interface BookingTableInfoProps {
  bookingDetails: any;
  getIniialData: any;
}

const BookingDetailsTabs = ({ bookingDetails, getIniialData }: BookingTableInfoProps) => {
  const theme = useTheme();
  const screenSizeDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [openEditFormDialog, setOpenEditFormDialog] = React.useState<boolean>(false);
  const [openCancelFormDialog, setOpenCancelFormDialog] = React.useState<boolean>(false);
  const [openAddOnModal, setOpenAddOnModal] = React.useState<boolean>(false);
  const [addOns, setAddOns] = React.useState<any>([]);
  const [value, setValue] = React.useState(0);
  const [selectedBookingId, setSelectedBookingId] = React.useState<any>('');
  const [message, setMessage] = React.useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const BookingDetailsCard = ({ booking, upcomingBooking }: BookingDetailsCardProps) => {
    const getChipColor = (status: any) => {
      switch (status) {
        case 'CONFIRMED':
          return 'success';
        case 'CANCELLED':
          return 'error';
        case 'CANCELLATION_REQUEST':
          return 'error';
        case 'WAITLISTED':
          return 'info';
        case 'TENTATIVE':
          return 'warning';
      }
    };

    const DetailsSectionLargeScreen = ({ item, index }: { item: any; index: number }) => (
      <Grid container justifyContent={'space-between'} spacing={4}>
        <Grid item xs={12} md={7}>
          <Grid container alignItems={'center'} justifyContent={'space-between'} mb="20px">
            <Grid item>
              <Typography sx={{ color: theme.palette.primary.main }} variant="h6">
                {item.venueName}
              </Typography>
            </Grid>
            <Grid item>
              <Box display={'flex'} gap={1}>
                <CalendarMonthOutlinedIcon fontSize="small" />
                <Typography fontWeight={500} fontSize="0.75rem">
                  {dayjs(item.startDate).format('DD/MM/YYYY')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display={'flex'} gap={1}>
                <AccessTimeOutlinedIcon fontSize="small" />
                <Typography fontWeight={500} fontSize="0.75rem">
                  ({item.timeSlot.startTime} - {item.timeSlot.endTime})
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display={'flex'} gap={1}>
                <Typography fontWeight={500} fontSize="0.75rem">
                  {strings.orderSummaryCustomerPricePerPerson}:
                </Typography>
                <Typography fontWeight={500} fontSize="0.75rem">
                  {item.formattedPricePerPerson}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} justifyContent={'space-between'} my="10px">
            <Grid item>
              <Grid container gap={1} justifyContent={'space-between'}>
                <Grid item>
                  <Box display={'flex'} gap={1}>
                    <Typography fontWeight={500} fontSize="0.75rem">
                      {strings.orderSummaryCustomerGuaranteedGuests}:
                    </Typography>
                    <Typography fontWeight={500} fontSize="0.75rem">
                      {item.guaranteedGuests}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Typography fontWeight={500}>|</Typography>
                </Grid>
                <Grid item>
                  <Box display={'flex'} gap={1}>
                    <Typography fontWeight={500} fontSize="0.75rem">
                      {strings.orderSummaryCustomerExpectedGuests}:
                    </Typography>
                    <Typography fontWeight={500} fontSize="0.75rem">
                      {item.expectedGuests}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box display={'flex'} gap={1}>
                <Typography fontWeight={500} fontSize="0.75rem">
                  {strings.orderSummaryCustomerHallRental}:
                </Typography>
                <Typography fontWeight={500} fontSize="0.75rem">
                  {item.formattedHallRental}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {item.bookedAddOns.length > 0 && (
            <Grid container alignItems={'center'} justifyContent={'space-between'} my="10px">
              <Grid item>
                <Box display={'flex'} gap={1}>
                  <Typography fontWeight={500} fontSize="0.68rem" sx={{ whiteSpace: 'nowrap' }}>
                    {strings.addOnsText}:
                  </Typography>
                  <Typography fontWeight={500} mr="10px" fontSize="0.68rem">
                    {item.bookedAddOns[0].name}
                    {item.bookedAddOns[0].addOnType === 'PER_QUANTITY' && ` - ${item.bookedAddOns[0].quantity}`}
                  </Typography>
                  {item.bookedAddOns.length > 1 && (
                    <Typography fontWeight={500} mr="10px" fontSize="0.68rem">
                      {item.bookedAddOns[1].name}
                      {item.bookedAddOns[1].addOnType === 'PER_QUANTITY' && ` - ${item.bookedAddOns[1].quantity}`}
                    </Typography>
                  )}
                  {item.bookedAddOns.length > 2 && (
                    <>
                      <Typography
                        fontWeight={500}
                        mr="10px"
                        color={theme.palette.primary.main}
                        display="flex"
                        alignItems={'center'}
                        sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                        onClick={() => {
                          setOpenAddOnModal(true);
                          setAddOns(item.bookedAddOns);
                        }}
                        fontSize="0.75rem">
                        {strings.viewAllText}
                        <ArrowForwardIosOutlinedIcon fontSize="small" />
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          <Box display={'flex'} flexDirection="column" height={'100%'} justifyContent="space-between">
            <Grid container alignItems="center">
              <Grid item md={5} lg={4}>
                <Chip
                  color={getChipColor(item.status)}
                  size="small"
                  label={item.status}
                  sx={{
                    marginLeft: screenSizeDownLg ? 0 : 4,
                    marginRight: screenSizeDownLg ? 2 : 4,
                    '&.MuiChip-colorError': {
                      color: '#AA0000',
                      fontWeight: 600,
                      backgroundColor: 'rgba(255, 0, 0, 0.2)',
                    },
                    '&.MuiChip-colorSuccess': {
                      color: '#008000',
                      fontWeight: 600,
                      backgroundColor: 'rgba(0, 128, 0, 0.2)',
                    },
                    '&.MuiChip-colorInfo': {
                      color: '#286ceb',
                      fontWeight: 600,
                      backgroundColor: '#c8d9f7',
                    },
                    '&.MuiChip-colorWarning': {
                      color: '#D26003',
                      fontWeight: 600,
                      backgroundColor: 'rgba(237, 108, 2, 0.2)',
                    },
                    paddingX: 1,
                    paddingY: 2,
                    borderRadius: 0,
                    width: 'max-content',
                  }}
                />
              </Grid>
              <Grid item md={7} lg={8}>
                {upcomingBooking && !index && (
                  <Grid
                    container
                    sx={{ color: theme.palette.primary.main, mt: 0.5 }}
                    justifyContent={'flex-end'}
                    alignItems="center"
                    style={{ cursor: 'pointer', marginBottom: 5 }}
                    onClick={() => navigate(`${routes.payment_summary}/${booking.id}`)}>
                    <Typography fontWeight={500} fontSize={'0.75rem'}>
                      {strings.view_order_summary_button}
                    </Typography>
                    <ArrowForwardIosOutlinedIcon fontSize="small" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );

    const DetailsSectionSmallScreen = ({ item, index }: { item: any; index: number }) => (
      <Box p={1}>
        <Grid container flexDirection={'column'} gap={1}>
          <Grid container alignItems={'center'} gap={1}>
            <Typography sx={{ color: theme.palette.primary.main }} variant="h6">
              {item.venueName} &nbsp;
            </Typography>
            <Box display={'flex'} gap={1}>
              <CalendarMonthOutlinedIcon fontSize="small" />
              <Typography fontWeight={500} fontSize="0.75rem">
                {dayjs(item.startDate).format('DD/MM/YYYY')}
              </Typography>
            </Box>
            <Box display={'flex'} gap={1}>
              <AccessTimeOutlinedIcon fontSize="small" />
              <Typography fontWeight={500} fontSize="0.75rem">
                ({item.timeSlot.startTime} - {item.timeSlot.endTime})
              </Typography>
            </Box>
          </Grid>
          <Grid container alignItems={'center'} gap={1}>
            <Box display={'flex'} gap={1}>
              <Typography fontWeight={500} fontSize="0.75rem">
                {strings.orderSummaryCustomerPricePerPerson}:
              </Typography>
              <Typography fontWeight={500} fontSize="0.75rem">
                {item.formattedPricePerPerson} &nbsp; | &nbsp;{' '}
              </Typography>
            </Box>
            <Box display={'flex'} gap={1}>
              <Typography fontWeight={500} fontSize="0.75rem">
                {strings.orderSummaryCustomerHallRental}:
              </Typography>
              <Typography fontWeight={500} fontSize="0.75rem">
                {item.formattedHallRental}
              </Typography>
            </Box>
          </Grid>
          <Grid container alignItems={'center'} gap={1}>
            <Box display={'flex'} gap={1}>
              <Typography fontWeight={500} fontSize="0.75rem">
                {strings.orderSummaryCustomerGuaranteedGuests}:
              </Typography>
              <Typography fontWeight={500} fontSize="0.75rem">
                {item.guaranteedGuests} &nbsp; | &nbsp;
              </Typography>
            </Box>
            <Box display={'flex'} gap={1}>
              <Typography fontWeight={500} fontSize="0.75rem">
                {strings.orderSummaryCustomerExpectedGuests}:
              </Typography>
              <Typography fontWeight={500} fontSize="0.75rem">
                {item.expectedGuests}
              </Typography>
            </Box>
          </Grid>
          {item.bookedAddOns.length > 0 && (
            <Grid container alignItems={'center'} justifyContent={'space-between'} my="5px">
              <Grid item>
                <Box display={'flex'} gap={1}>
                  <Typography fontWeight={500} fontSize="0.68rem" sx={{ whiteSpace: 'nowrap' }}>
                    {strings.addOnsText}:
                  </Typography>
                  <Typography fontWeight={500} mr="10px" fontSize="0.68rem">
                    {item.bookedAddOns[0].name}
                    {item.bookedAddOns[0].addOnType === 'PER_QUANTITY' && ` - ${item.bookedAddOns[0].quantity}`}
                  </Typography>
                  {item.bookedAddOns.length > 1 && (
                    <>
                      <Typography
                        fontWeight={500}
                        mr="10px"
                        color={theme.palette.primary.main}
                        display="flex"
                        alignItems={'center'}
                        sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                        onClick={() => {
                          setOpenAddOnModal(true);
                          setAddOns(item.bookedAddOns);
                        }}
                        fontSize="0.75rem">
                        {strings.viewAllText}
                        <ArrowForwardIosOutlinedIcon fontSize="small" />
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Box display="flex" alignItems="center" mt={1}>
          <Chip
            color={getChipColor(item.status)}
            size="small"
            label={item.status}
            sx={{
              marginLeft: screenSizeDownLg ? 0 : 4,
              marginRight: screenSizeDownLg ? 2 : 4,
              '&.MuiChip-colorError': {
                color: '#AA0000',
                fontSize: '0.5rem',
                fontWeight: 600,
                backgroundColor: 'rgba(255, 0, 0, 0.2)',
              },
              '&.MuiChip-colorSuccess': {
                color: '#008000',
                fontSize: '0.5rem',
                fontWeight: 600,
                backgroundColor: 'rgba(0, 128, 0, 0.2)',
              },
              '&.MuiChip-colorInfo': {
                color: '#286ceb',
                fontSize: '0.5rem',
                fontWeight: 600,
                backgroundColor: '#c8d9f7',
              },
              '&.MuiChip-colorWarning': {
                color: '#D26003',
                fontSize: '0.5rem',
                fontWeight: 600,
                backgroundColor: 'rgba(237, 108, 2, 0.2)',
              },
              paddingY: 2,
              borderRadius: 0,
              width: 'max-content',
            }}
          />
          {upcomingBooking && !index && (
            <Grid
              container
              sx={{ color: theme.palette.primary.main }}
              alignItems="center"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(`${routes.payment_summary}/${booking.id}`)}>
              <Typography fontWeight={500} fontSize={'0.7rem'}>
                {strings.view_order_button}
              </Typography>
              <ArrowForwardIosOutlinedIcon sx={{ fontSize: '0.7rem' }} />
              {/* <ArticleIcon fontSize='small' /> */}
            </Grid>
          )}
        </Box>
      </Box>
    );

    return (
      <>
        <Card sx={{ p: 2, marginBottom: 4 }}>
          <>
            {booking.bookingLineItems.map((item: any, index: number) => {
              return (
                <>
                  {screenSizeDownSm ? (
                    <DetailsSectionSmallScreen item={item} index={index} />
                  ) : (
                    <DetailsSectionLargeScreen item={item} index={index} />
                  )}
                  {booking.bookedRooms.length > 0 && (
                    <Box mt={screenSizeDownMd ? 1 : 0}>
                      <BookingRoomsTable
                        theme={theme}
                        editRow={() => { }}
                        deleteRow={() => { }}
                        rooms={booking.bookedRooms.map((item: any) => ({
                          roomTypeId: item.roomTypeName,
                          from: dayjs(item.bookingFrom).format('DD/MM/YYYY'),
                          to: dayjs(item.bookingTo).format('DD/MM/YYYY'),
                          numberOfRooms: item.numberOfRooms,
                          mealPlanName: item.mealPlanName,
                        }))}
                      />
                    </Box>
                  )}
                  {upcomingBooking && item.status !== 'CANCELLED' && item.status !== 'CANCELLATION_REQUEST' && (
                    <Grid
                      container
                      alignItems={'center'}
                      paddingX={1}
                      spacing={2}
                      justifyContent={'flex-end'}
                      sx={{
                        [theme.breakpoints.down('md')]: {
                          justifyContent: 'center',
                          paddingX: 0,
                          mt: 1,
                        },
                      }}>
                      <Grid item xs={6} md={3}>
                        <PrimaryButton
                          onClick={() => {
                            setSelectedBookingId(item.id);
                            setOpenEditFormDialog(true);
                          }}
                          variant="outlined"
                          fullWidth
                          sx={{
                            fontWeight: 600,
                            [theme.breakpoints.down('md')]: {
                              textAlign: 'center',
                            },
                            [theme.breakpoints.up('md')]: {
                              border: 0,
                            },
                          }}>
                          <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
                          {screenSizeDownSm ? strings.editText : strings.editBooking}
                        </PrimaryButton>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <PrimaryButton
                          onClick={() => {
                            setSelectedBookingId(item.id);
                            setOpenCancelFormDialog(true);
                          }}>
                          {screenSizeDownSm ? strings.cancelText : strings.cancelBookingText}
                        </PrimaryButton>
                      </Grid>
                    </Grid>
                  )}
                  {index !== booking.bookingLineItems.length - 1 ? <Divider sx={{ marginY: 2 }} /> : null}
                </>
              );
            })}
          </>
        </Card>
      </>
    );
  };

  const getMarginLeft = () => {
    if (screenSizeDownMd) return 2
    else if (screenSizeDownSm) return 1
    else return 5
  }

  const getMarginX = () => {
    if (screenSizeDownMd) return 6
    else if (screenSizeDownSm) return 2
    else return 12
  }
  const a = () => {
    if (screenSizeDownMd) return 2
    else if (screenSizeDownSm) return 1.5
    else return 5
  }
  return (
    <>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <EditBookingFormDialog
        open={openEditFormDialog}
        setOpen={setOpenEditFormDialog}
        header={strings.editBookingHeaderText}
        subTitle={strings.editBookingSubtitleText}
        buttonText={strings.editBooking}
        bookingId={selectedBookingId}
        setMessage={setMessage}
        caseType="Edit"
        getIniialData={getIniialData}
      />
      <EditBookingFormDialog
        open={openCancelFormDialog}
        setOpen={setOpenCancelFormDialog}
        header={strings.cancelBookingHeaderText}
        subTitle={strings.cancelBookingSubtitleText}
        buttonText={strings.cancelBookingText}
        bookingId={selectedBookingId}
        setMessage={setMessage}
        caseType="Cancel"
        getIniialData={getIniialData}
      />
      <AddOnsModal open={openAddOnModal} setOpen={setOpenAddOnModal} addOns={addOns} />
      <Box sx={{ marginX: getMarginX(), marginTop: 6 }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: theme.palette.primary.main,
            borderBottomStyle: 'dashed',
            '.MuiButtonBase-root': {
              textTransform: 'capitalize',
              fontSize: '14px',
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
            },
            '.MuiButtonBase-root.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
          }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
            sx={{
              ml: a(),
              '.MuiTabScrollButton-root.Mui-disabled': {
                display: 'none',
              },
            }}>
            <Tab label={strings.upcomingBookings} {...a12yProps(0)} />
            <Tab label={strings.pastBookingsText} {...a12yProps(1)} />
          </Tabs>
        </Box>
        <Box
          display={'flex'}
          alignContent={'center'}
          sx={{ color: 'grey ' }}
          mt={1.5}
          ml={getMarginLeft()} >
          <InfoOutlinedIcon fontSize="small" />
          <Typography fontSize={'0.68rem'} ml={0.5}>
            {strings.user_profile_message2}
          </Typography>
        </Box>
        <TabPanel value={value} index={0}>
          {bookingDetails.upcomingBookings?.length > 0 ? (
            bookingDetails.upcomingBookings?.map((booking: any) => (
              <BookingDetailsCard key={booking.id} booking={booking} upcomingBooking={true} />
            ))
          ) : (
            <Typography
              ml={screenSizeDownMd ? 0 : 2}
              fontSize={'20px'}
              px={1}
              sx={{
                // color: 'red',
                fontWeight: 500,
                width: 'max-content',
              }}>
              {strings.noBookingsAvailableText}
            </Typography>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {bookingDetails.pastBookings?.length > 0 ? (
            bookingDetails.pastBookings?.map((booking: any) => (
              <BookingDetailsCard key={booking.id} booking={booking} upcomingBooking={false} />
            ))
          ) : (
            <Typography
              ml={2}
              fontSize={'20px'}
              px={1}
              sx={{
                // color: 'red',
                fontWeight: 500,
                width: 'max-content',
              }}>
              {strings.noBookingsAvailableText}
            </Typography>
          )}
        </TabPanel>
      </Box >
    </>
  );
};

export default BookingDetailsTabs;
