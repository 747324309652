import { Box, useMediaQuery, useTheme } from '@mui/material';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import ViewOrgDetails from 'screens/AuthenticatedScreens/ViewOrgDetails';
import OrganisationDetails from 'screens/OrganisationDetails';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/redux/axios';
import { defaultLanguage } from 'utils/redux/reducer/authentication-slice';
import {
  searchFormData,
  searchedData,
  setSearchFormData,
  setSearchedData,
} from '../../utils/redux/reducer/searched-data-slice';
import { fetchFromStorage } from '../../utils/storage';
import SearchBar from '../SearchBar';
import OrganisationList from './OrganisationList';
import SliderImages from './SliderImages';

const HomePage = () => {
  return (
    <>
      <SearchBar />
      <HomePageContent />
    </>
  );
};

const HomePageContent = () => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const lang = useSelector(defaultLanguage);
  const searchData = useSelector(searchedData);
  const searchedFormData = useSelector(searchFormData);
  const [data, setData] = useState<any>({});
  const [orgData, setOrgData] = useState<any>({});
  const location = useLocation();
  const dispatch = useDispatch();
  const queryStringValues = queryString.parse(location.search);
  useEffect(() => {
    if (searchData.length > 0) {
      setData(searchData);
    } else {
      setData(JSON.parse(fetchFromStorage('searchedData', true) as string));
    }
  }, [searchData, lang]);

  const setSearchedLocation = (location: any) =>
    dispatch(
      setSearchFormData({
        ...searchedFormData,
        location: [{ value: location.value, label: location.label }],
      }),
    );
  useEffect(() => {
    (async () => {
      if (queryStringValues.oid) {
        try {
          const { status, data } = await axiosInstance.get(URLS.getAvailableVenues(queryStringValues.oid));
          setSearchedLocation(data.location);
          setOrgData(data);
          dispatch(setSearchedData([]));
        } catch (error: any) {
          console.log(error);
        }
      }
    })();
  }, [lang, queryStringValues.oid]);

  const getMX = () => {
    if (screenSizeDownMd) return 3
    else if (screenSizeDownSm) return 1.5
    else return 6
  }

  return (
    <>
      {queryStringValues.oid ? (
        <Box mx={getMX()}>
          <ViewOrgDetails venueListData={orgData} />
          <OrganisationDetails />
        </Box>
      ) : (
        <>
          {data?.length > 0 ? (
            <Box
              sx={{
                paddingLeft: screenSizeDownSm ? 1 : 4,
                paddingRight: screenSizeDownSm ? 1 : 3,
              }}>
              <OrganisationList data={data} />
            </Box>
          ) : (
            <Box>
              <SliderImages />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default HomePage;
