import dayjs from 'dayjs';
import { FormModel } from '../../components/Form';
import { Validators } from '../../utils/validators';

export const SearchBarForm = (
  strings: any,
  locations: [],
  eventTypes: [],
  timeslots: [],
  isLocationDisabled: boolean,
  screenSizeDownLg: boolean,
): FormModel[] => {
  const tomorrow = dayjs(new Date()).add(1, 'day');
  return [
    {
      label: `${strings.searchBar_Text_location} *`,
      value: '',
      type: 'select',
      placeholder: '',
      field: 'location',
      validators: [{ check: Validators.required, message: strings.requiredField }],
      required: true,
      isMulti: true,
      responsive: { xs: 12, sm: 5.9, lg: screenSizeDownLg ? 5.9 : 2 },
      size: 'small',
      disabled: isLocationDisabled,
    },
    {
      label: `${strings.searchBar_Text_eventType} *`,
      value: '',
      type: 'drop-down',
      placeholder: strings.email_event_config_column_event_type,
      field: 'eventType',
      validators: [{ check: Validators.required, message: strings.requiredField }],
      required: true,
      responsive: { xs: 12, sm: 5.9, lg: screenSizeDownLg ? 5.9 : 1.5 },
      options: eventTypes,
      size: 'small',
      // sx: { height: '54px' },
    },
    {
      label: `${strings.searchBar_Text_TimeSlot} *`,
      value: {},
      type: 'drop-down',
      placeholder: '',
      field: 'timeSlot',
      validators: [{ check: Validators.required, message: strings.requiredField }],
      required: true,
      responsive: { xs: 12, sm: 5.9, lg: screenSizeDownLg ? 5.9 : 2 },
      options: timeslots,
      size: 'small',
      // sx: { height: '54px' },
    },
    {
      label: `${strings.searchBar_Text_NoOfGuest} *`,
      value: '',
      typeValue: 'number',
      type: 'text',
      placeholder: '',
      field: 'guests',
      variant: 'outlined',
      validators: [
        { check: Validators.required, message: strings.requiredField },
        { check: Validators.number, message: strings.required_valid_no },
        // { check: Validators.noRoom, message: 'No. of guest exceeded' },
      ],
      required: true,
      responsive: { xs: 12, sm: 5.9, lg: screenSizeDownLg ? 5.9 : 1.5 },
      size: 'small',
      // sx: { input: { height: '37px' } },
    },
    {
      label: `${strings.common_text_startDate} *`,
      value: '',
      autoFocus: true,
      type: 'date',
      typeValue: 'text',
      placeholder: '',
      field: 'startDate',
      validators: [{ check: Validators.required, message: strings.requiredField }],
      responsive: { xs: 12, sm: 5.9, lg: screenSizeDownLg ? 5.9 : 1.5 },
      required: true,
      minDate: tomorrow,
      size: 'small',
      // sx: { input: { height: '37px' } },
    },
    {
      label: `${strings.common_text_endDate} *`,
      value: '',
      autoFocus: false,
      type: 'date',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'endDate',
      validators: [{ check: Validators.required, message: strings.requiredField }],
      responsive: { xs: 12, sm: 5.9, lg: screenSizeDownLg ? 5.9 : 1.5 },
      required: true,
      minDate: tomorrow,
      size: 'small',
      // sx: { input: { height: '37px' } },
    },
  ];
};
