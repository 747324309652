import { createTheme, Grid, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import { useSelector } from 'react-redux';
import { defaultLanguage, getTheme } from 'utils/redux/reducer/authentication-slice';
import './App.css';
import Routes from './routes';

function App() {
  const tempTheme = {
    primary: '#9A2143',
    primaryTextColor: '#FFFFFF',
    secondaryMain: '#BFA054',
    secondaryDark: '#FFF5DB',
    secondaryLight: '#FBF8F2',
    secondaryTextColor: '#000000',
    hoverColor: '#79112E',
    //buttonGradient: 'linear-gradient(to top, #9090c5, #333366 60%, #333366)'
    headerColor: '#BFA054',
  } as any;
  const themesData = useSelector(getTheme);
  const themes = themesData?.primary ? themesData : tempTheme;
  const selectedlangLocaleCode = useSelector(defaultLanguage).value.split('_')[0];

  // const favicon = document.getElementById('favicon') as HTMLLinkElement;
  // favicon.href = getBaseUrl() + '/api/organizations/logo';

  const theme = createTheme({
    // direction: 'rtl',
    palette: {
      mode: 'light',
      primary: {
        main: themes.primary,
        contrastText: themes.primaryTextColor,
      },
      divider: '#707070',
      action: {
        hover: 'lightgrey',
      },
      secondary: {
        main: themes.secondaryMain,
        dark: themes.secondaryDark,
        light: themes.secondaryLight,
        contrastText: themes.secondaryTextColor,
      },
    },
    typography: {
      fontFamily: ['Montserrat'].join(','),
      fontSize: 12,
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            boxShadow: '0px 4px 62px rgba(0, 0, 0, 0.08)',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundImage: themes?.buttonGradient,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: themes?.headerColor ? themes?.headerColor : themes.secondaryMain,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            ':last-of-type': {
              borderRadius: 0,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            width: '100vw',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '0.75rem',
            marginLeft: 0,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={selectedlangLocaleCode}>
        <Grid
          container
          className="main-body"
          sx={{
            "div[class$='MuiDataGrid-root']": {
              border: 0,
              borderRadius: 0,
              "div[class$='MuiDataGrid-columnHeaders']": {
                borderBottom: 0,
                borderRadius: 0,
                minHeight: '35px !important',
                maxHeight: '35px !important',
              },
              '.MuiDataGrid-virtualScroller': {
                marginTop: '35px !important',
              },
              '.MuiDataGrid-virtualScrollerRenderZone': {
                '.MuiDataGrid-row': {
                  '.MuiDataGrid-cell': {
                    borderBottom: `1px dashed ${theme.palette.secondary.main}`,
                    '&:focus &:focusWithin': {
                      outline: 'none !important',
                    },
                  },
                },
              },
            },
          }}>
          <Routes />
        </Grid>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
