import ClearIcon from '@mui/icons-material/Clear';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { AlertColor, Divider, Grid, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultLanguage } from 'utils/redux/reducer/authentication-slice';
import { setSelectedRoomsCosting, setSelectedVenuesForCosting } from 'utils/redux/reducer/costing-slice';
import strings from '../../common/Translation/Translate';
import PrimaryButton from '../../components/Button/PrimaryButton';
import Form, { FormDataModel } from '../../components/Form';
import Notification from '../../components/Notification';
import PageLoader from '../../components/PageLoader';
import { routes } from '../../utils/constants/routes';
import { URLS } from '../../utils/constants/urls';
import axiosInstance from '../../utils/redux/axios';
import {
  eventTypeList,
  searchFormData,
  selectedOrg,
  setEventTypeList,
  setSearchDataSliceInitState,
  setSearchDataSliceInitStateForOid,
  setSearchFormData,
  setSearchedData,
  setSelectedOrg,
  setTimeSlotList,
  setVenueList,
  timeSlotList,
} from '../../utils/redux/reducer/searched-data-slice';
import { removeFromStorage } from '../../utils/storage';
import { createDropdownData, removeErrorFieldsFromValues } from '../../utils/validators/HelperFunctions';
import { SearchBarForm } from './SearchBarForm';

const SearchBar: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector(defaultLanguage);
  let formRef: RefObject<Form | null | undefined> = useRef();
  const [hasError, setHasError] = useState<boolean>(false);
  const [locations, setLoctions] = useState<any>([]);
  const eventTypes = useSelector(eventTypeList);
  const timeslots = useSelector(timeSlotList);
  const [selectedLocations, setSelectedLocations] = useState<any>([]);
  const [selectedEventType, setSelectedEventType] = useState<any>('');
  const [loading, setLoading] = useState<any>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  const theme = useTheme();
  const screenSizeDownLg = useMediaQuery(theme.breakpoints.down(1261));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchData, setSearchData] = useState<any>({});
  const [selectOrg, setSelectOrg] = useState<any>('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orgId = queryParams.get('oid');

  const queryStringValues = queryString.parse(location.search);
  const [isLocationDisabled, setIsLocationDisabled] = useState<boolean>(false);
  const searchFormDataStorage = useSelector(searchFormData);
  const selectedOrgStorage = useSelector(selectedOrg);

  useEffect(() => {
    if (queryStringValues.oid || location.pathname === routes.available_venues) {
      setIsLocationDisabled(true);
      queryStringValues.oid && dispatch(setSelectedOrg(parseInt(queryStringValues.oid as string)));
    }
  }, []);

  useEffect(() => {
    setSearchData(searchFormDataStorage);
    setSelectOrg(selectedOrgStorage);
    if (searchFormDataStorage?.eventType) setSelectedEventType(searchFormDataStorage.eventType);
    const array = searchFormDataStorage?.location?.map((item: any) => item.value);
    setSelectedLocations(array);
  }, [searchFormDataStorage, selectedOrgStorage]);

  const getEventTypeList = useCallback(async () => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(URLS.getEventTypeList);
      setLoading(false);
      const customData = createDropdownData(data, ['id', 'name']);
      dispatch(setEventTypeList(customData));
    } catch (error: any) {
      handleCatch(error, 'error');
    }
  }, []);

  const getLocationList = useCallback(async () => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(URLS.getLocationList);
      setLoading(false);
      const customData = createDropdownData(data, ['id', 'name']);
      setLoctions(customData);
    } catch (error: any) {
      handleCatch(error, 'error');
    }
  }, []);

  const getTimeSlotList = useCallback(async () => {
    try {
      if (selectedEventType !== '' && selectedLocations?.length > 0) {
        setLoading(true);

        const requestBody = { location: selectedLocations };

        const config: AxiosRequestConfig = {
          params: {
            oid: orgId,
          },
        };

        const { status, data } = await axiosInstance.post(URLS.getTimeSlotList(selectedEventType), requestBody, config);
        if (status === 200) {
          setLoading(false);
          const customData = createDropdownData(data, ['id', 'name']);
          if (customData.length === 0) {
            setMessage({
              display: true,
              severity: 'error',
              message: strings.time_slot_not_available,
            });
          }
          dispatch(setTimeSlotList(customData));
        }
      }
    } catch (error: any) {
      handleCatch(error, 'error');
    }
  }, [selectedLocations, selectedEventType]);

  useEffect(() => {
    (async () => {
      getEventTypeList();
      getLocationList();
    })();
  }, [getEventTypeList, getLocationList, lang]);

  useEffect(() => {
    (async () => {
      getTimeSlotList();
    })();
  }, [getTimeSlotList]);

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const handleCatch = (error: any, severity: AlertColor) => {
    setLoading(false);
    if (error.response) {
      setMessage({
        display: true,
        severity: severity,
        message: error.response?.data?.message,
      });
    } else {
      setMessage({
        display: true,
        severity: severity,
        message: error.message,
      });
    }
  };

  const handleChange = (field: string, value: any) => {
    if (field === 'location') {
      formRef.current?.handleChange('', 'timeSlot', { error: true, message: strings.requiredField });
      const array = value.map((item: any) => item.value);
      setSelectedLocations(array);
    }
    if (field === 'eventType') {
      formRef.current?.handleChange('', 'timeSlot', { error: true, message: strings.requiredField });
      setSelectedEventType(value);
    }
    if (field === 'startDate') {
      if (dayjs(value).isValid()) formRef.current?.handleChange(value, 'endDate', { error: false, message: '' });
    }
  };
  const handleFocus = (field: string, value: any) => {
    if (
      field === 'timeSlot' &&
      (selectedEventType === '' || selectedLocations === undefined || selectedLocations?.length === 0)
    ) {
      setMessage({
        display: true,
        severity: 'info',
        message: strings.time_slot_location_eventType_required_validation,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const { getFormData } = formRef.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData, isFormValid } = getFormData();
      const body = removeErrorFieldsFromValues(formData);
      console.log(formData);
      setHasError(false);
      if (isFormValid && dayjs(body.startDate as string).isValid() && dayjs(body.endDate as string).isValid()) {
        if (dayjs(body.startDate as string).isAfter(dayjs(body.endDate as string))) {
          setMessage({
            display: true,
            severity: 'error',
            message: strings.datePickerValidation,
          });
        } else {
          setLoading(true);
          const requestBody = {
            location: selectedLocations,
            eventType: body.eventType,
            bookingStartDate: dayjs(body.startDate as string).format('DD-MM-YYYY'),
            bookingEndDate: dayjs(body.endDate as string).format('DD-MM-YYYY'),
            timeSlot: body.timeSlot,
            guest: body.guests,
            layouts: [],
          };
          let url;
          if (location.pathname === routes.available_venues) {
            url = URLS.getAvailableVenues(selectOrg);
          } else {
            url = URLS.getSearchedOrganisations;
          }
          const { status, data } = await axiosInstance.post(url, requestBody);
          if (status === 200 || status === 201) {
            setLoading(false);
            if (data.length === 0) {
              setMessage({
                display: true,
                severity: 'error',
                message: strings.no_venue_Found_error_message,
              });
            }
            if (location.pathname === routes.available_venues) {
              dispatch(setVenueList(data));
              dispatch(setSelectedVenuesForCosting([]));
              dispatch(setSelectedRoomsCosting([]));
              removeFromStorage('selectedVenuesForCosting', true);
              removeFromStorage('orgDataForCosting', true);
            } else {
              dispatch(setSearchedData(data));
              setSelectedOrg(queryStringValues.oid);
              dispatch(setVenueList({}));
              dispatch(setSelectedVenuesForCosting([]));
              dispatch(setSelectedRoomsCosting([]));
              removeFromStorage('selectedVenuesForCosting', true);
              removeFromStorage('orgDataForCosting', true);
            }
            // setSearchData(body)
            dispatch(setSearchFormData(body));
            if (screenSizeDownLg) {
              setOpenModal(false);
              formRef.current?.resetForm();
            }
          }
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      handleCatch(error, 'error');
    }
  };

  const clearFilterFunc = () => {
    flushSync(() => {
      setSelectedEventType('');
      if (!location.search.includes('oid')) {
        setSelectOrg('');
        dispatch(setSearchDataSliceInitState({}));
        setSearchData({});
        setSelectedLocations([]);
        setIsLocationDisabled(false);
      } else {
        const { location } = searchData
        dispatch(setSearchDataSliceInitStateForOid({ location: location }));
        setSearchData({ location: location });
      }
    });
    formRef.current?.resetForm();
    if (!location.search.includes('oid')) {
      navigate(routes.root);
    }
  };

  const getScreenSizeWidth = () => {
    return screenSizeDownSm ? '90vw' : '80vw';
  };

  return (
    <>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Grid
        container
        py={screenSizeDownLg ? 1.5 : 2}
        sx={{
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
          top: 0,
          paddingLeft: 4,
          paddingRight: 5,
          justifyContent: 'flex-end',
          gap: 1,
          // spacing: 2,
          backgroundColor: theme.palette.secondary.light,
          zIndex: 5,
          alignItem: 'center',
          position: 'sticky',
        }}>
        {!screenSizeDownLg && (
          <Form
            dropdownData={{ location: locations }}
            hasError={hasError}
            ref={formRef as RefObject<Form>}
            model={SearchBarForm(strings, locations, eventTypes, timeslots, isLocationDisabled, screenSizeDownLg)}
            values={searchData}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        )}
        <Grid item lg={screenSizeDownLg ? 2 : 1.5}>
          {screenSizeDownLg ? (
            <PrimaryButton
              sx={{ marginTop: screenSizeDownLg ? 0 : 3, height: screenSizeDownLg ? '20px' : '39px' }}
              onClick={() => setOpenModal(true)}>
              <Box display="flex" alignItems={'center'} gap={1}>
                <SearchIcon />
                <Typography>
                  {searchData && Object.keys(searchData).length > 0
                    ? strings.bookBanquetModifyText
                    : strings.bookBanquetText}
                </Typography>
              </Box>
            </PrimaryButton>
          ) : (
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-evenly'}>
              {/* <Grid item> */}
              <PrimaryButton
                sx={{ marginTop: screenSizeDownLg ? 0 : 3, height: screenSizeDownLg ? '20px' : '39px' }}
                onClick={handleSubmit}>
                <SearchIcon />
              </PrimaryButton>
              <PrimaryButton
                sx={{ marginTop: screenSizeDownLg ? 0 : 3, height: screenSizeDownLg ? '20px' : '39px' }}
                onClick={clearFilterFunc}>
                <ClearIcon />
              </PrimaryButton>
              {/* </Grid> */}
            </Box>
          )}
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        // onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          {loading && <PageLoader />}
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 0,
              width: screenSizeDownMd ? getScreenSizeWidth() : '70vw',
              minHeight: screenSizeDownSm ? '80vh' : '35vh',
              ':focus': { border: 0 },
            }}>
            <Box display={'flex'} alignItems="center" justifyContent={'space-between'} px={3} py={2}>
              <Typography id="modal-modal-title" variant="h5" fontWeight={600} color={'primary'}>
                {strings.checkAvailabilityText}
              </Typography>
              <ClearOutlinedIcon
                onClick={() => setOpenModal(false)}
                sx={{
                  ':hover': { cursor: 'pointer' },
                  color: theme.palette.primary.main,
                }}
              />
            </Box>
            <Divider
              sx={{
                mx: 3,
                borderStyle: 'dashed',
                borderColor: theme.palette.secondary.main,
              }}
            />
            <Grid container columnSpacing={4} rowSpacing={1} px={3} py={1.5}>
              <Form
                dropdownData={{ location: locations }}
                hasError={hasError}
                ref={formRef as RefObject<Form>}
                model={SearchBarForm(strings, locations, eventTypes, timeslots, isLocationDisabled, screenSizeDownLg)}
                values={searchData}
                onChange={handleChange}
                onFocus={handleFocus}
              />
              <Grid item xs={12}>
                <Grid container pb={0} pt={2} gap={1} justifyContent={'center'}>
                  <Grid item xs={12} sm={4}>
                    <PrimaryButton fullWidth onClick={(e: any) => handleSubmit(e)}>
                      {strings.searchButtonText}
                    </PrimaryButton>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <PrimaryButton fullWidth onClick={() => clearFilterFunc()} variant={'outlined'}>
                      {strings.clear_filter}
                    </PrimaryButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      </Modal>
    </>
  );
};
export default SearchBar;
