import { AlertColor, useMediaQuery, useTheme } from '@mui/material';
import Notification from 'components/Notification';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDrawerWidth } from 'utils/validators/HelperFunctions';
import DrawerMenu from '../../../components/DrawerMenu';
import Login from '../../../screens/Authentication/Login';
import {
  openAuthenticationDrawer,
  setOpenAuthenticationDrawer,
} from '../../../utils/redux/reducer/authentication-slice';
import ForgotPassword from '../ForgotPassword';
import SignUp from '../SignUp';

const RenderedComponent = ({
  drawerState,
  setDrawerState,
  handleClose,
  setMessage
}: any) => {
  if (drawerState === 1) {
    return <Login handleClose={handleClose} setDrawerState={setDrawerState} />;
  } else if (drawerState === 2) {
    return <ForgotPassword handleClose={handleClose} setDrawerState={setDrawerState} setDrawerMessage={setMessage} />;
  } else {
    return <SignUp setDrawerState={setDrawerState} setMessage={setMessage} />;
  }
};

const AuthenticationDrawer = () => {
  const theme = useTheme();
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  // drawerState 1 = Login
  // drawerState 2 = Forgot Password
  // drawerState 1 = Sign Up
  const [drawerState, setDrawerState] = useState<1 | 2 | 3>(1);
  const openDrawer = useSelector(openAuthenticationDrawer);
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const handleClose = () => {
    setDrawerState(1);
    dispatch(setOpenAuthenticationDrawer(false));
  };
  return (
    <>
      {message && message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <DrawerMenu
        open={openDrawer}
        handleClose={handleClose}
        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screenSizeUpLg)}
        drawerState={drawerState}>
        <RenderedComponent
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          handleClose={handleClose}
          setMessage={setMessage}
        />
      </DrawerMenu>
    </>
  );
};

export default AuthenticationDrawer;
