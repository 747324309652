import { Checkbox, Divider, FormControlLabel, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import * as React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';



type ModalProps = {
  open: boolean;
  setOpen: any;
  terms: any;
  handleSave: () => void;
};

export const TermsModal: React.FC<ModalProps> = ({ open, setOpen, terms, handleSave }) => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [termsAgreed, setTermsAgreed] = React.useState<boolean>(false);

  const getWidth = () => {
    if (screenSizeDownMd) return '70vw'
    else if (screenSizeDownSm) return '90vw'
    else return '40vw'
  }

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: getWidth(),
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          maxHeight: '80vh',
          ':focus': { border: 0 },
        }}>
        <Box display={'flex'} alignItems="center" justifyContent={'space-between'} pb={2}>
          <Typography id="modal-modal-title" fontSize={'20px'} fontWeight={600} color={'primary'}>
            {'Terms And Conditions'}
          </Typography>
        </Box>
        <Divider
          sx={{
            borderStyle: 'dashed',
            borderColor: theme.palette.secondary.main,
          }}
        />
        <Scrollbar style={{ width: '100%', height: '50vh' }}>
          <Typography sx={{ p: 1 }} dangerouslySetInnerHTML={{ __html: terms }} />
        </Scrollbar>
        <FormControlLabel
          control={<Checkbox checked={termsAgreed} onChange={(e: any) => setTermsAgreed(e.target.checked)} />}
          label={<Typography fontSize={'0.75rem'}>{strings.termTextSignUp}</Typography>}
        />
        <Box
          sx={{
            [theme.breakpoints.up('sm')]: {
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
            },
          }}
          pb={0}>
          <PrimaryButton disabled={!termsAgreed} onClick={handleSave}>
            {strings.I_Agree}
          </PrimaryButton>
          <PrimaryButton variant="outlined" onClick={onClose}>
            {strings.I_Donot_Agree}
          </PrimaryButton>
        </Box>
      </Box>
    </Modal>
  );
};
