import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { ToolTip } from '../Tooltip/ToolTip';

type PopupProps = {
  Component: any;
  text: string;
  tooltipText: string;
  onClick?: () => void;
};
export default function Popup({ Component, text, tooltipText, onClick }: PopupProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClick && onClick();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ToolTip title={tooltipText}>
        <Component onClick={handleClick} data-testid='btn' />
      </ToolTip>
      <Popover
        sx={{
          width: '100vw',
          '.MuiPopover-paper': {
            background: '#1b1c1d',
            color: '#fff',
          },
          a: {
            color: '#4183c4',
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Typography sx={{ p: 2 }} dangerouslySetInnerHTML={{ __html: text }} />
      </Popover>
    </>
  );
}
