import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
  AlertColor,
  CircularProgress,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import Notification from 'components/Notification';
import Paragraph from 'components/Typography/Typography';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ForgetPasswordModal } from 'screens/Authentication/ForgotPassword/ForgetPasswordModal';
import { URLS } from 'utils/constants/urls';
import { setAuthentication, setUser } from 'utils/redux';
import axiosInstance from 'utils/redux/axios';
import { Authenticated, refreshToken } from 'utils/redux/reducer/authentication-slice';
import strings from '../../common/Translation/Translate';
import PrimaryButton from '../Button/PrimaryButton';

type DialogProps = {
  open: boolean;
  handleClose: any;
  email: string;
  resendOtpFunc: any;
  verifyOtpMailType: any;
  successMessage: any;
  header: any;
  setDrawerState?: any;
  setDrawerMessage?: any;
  caseType: 'login' | 'forget_password' | 'signup' | 'change_email';
  getInitialData?: any;
};
export const ChangeEmailFormDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  email,
  resendOtpFunc,
  verifyOtpMailType,
  header,
  successMessage,
  setDrawerState,
  setDrawerMessage,
  caseType,
  getInitialData,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = React.useState<boolean>(false);
  const [resendStatus, setResendStatus] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [verificationCode, setVerificationCode] = React.useState<string>('');
  const [openForgetPasswordModal, setOpenForgetPasswordModal] = React.useState(false);
  const isLogin = useSelector(Authenticated);
  const [message, setMessage] = React.useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const verifyOtp = async () => {
    try {
      if (verificationCode === '') {
        setHasError(true);
      } else {
        setLoading(true);
        const body = {
          email: email,
          code: verificationCode,
          mailType: verifyOtpMailType,
        };
        const { status } = await axiosInstance.post(URLS.verifyOtp, body);
        if (status === 200) {
          setLoading(false);
          onClose();
          if (caseType === 'forget_password') {
            setOpenForgetPasswordModal(true);
            setMessage({
              display: true,
              severity: 'success',
              message: successMessage,
            });
          } else if (caseType === 'login') {
            setMessage({
              display: true,
              severity: 'success',
              message: successMessage,
            });
          } else if (caseType === 'signup') {
            setDrawerState && setDrawerState(1);
            setDrawerMessage &&
              setDrawerMessage({
                display: true,
                severity: 'success',
                message: successMessage,
              });
          } else if (caseType === 'change_email') {
            isLogin && dispatch(refreshToken() as any);
            setMessage({
              display: true,
              severity: 'success',
              message: successMessage,
            });
            getInitialData();
          }
        }
      }
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.message,
      });
    }
  };

  const onClose = () => {
    handleClose();
    setVerificationCode('');
    setResendStatus(false);
    setHasError(false);
  };

  const verifyLater = () => {
    onClose();
    dispatch(setAuthentication(false));
    dispatch(setUser(null));
    setDrawerState(1);
  };

  const resendOtp = () => {
    resendOtpFunc();
    setResendStatus(true);
  };

  return (
    <div>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Dialog
        open={open}
        sx={{
          px: screenSizeDownSm ? 0 : 3,
        }}>
        <DialogTitle sx={{ color: theme.palette.primary.main }}>
          <Grid container alignItems="flex-end" justifyContent="space-between">
            <Grid item>
              <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{header}</Typography>
            </Grid>
            <Grid item>
              <ClearOutlinedIcon
                fontSize="large"
                onClick={caseType === 'change_email' ? verifyLater : onClose}
                sx={{
                  ':hover': { cursor: 'pointer' },
                  color: theme.palette.primary.main,
                }}
              />
            </Grid>
          </Grid>
          <Divider
            sx={{
              mt: 2,
              borderStyle: 'dashed',
              borderColor: theme.palette.secondary.main,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {caseType === 'login' && !resendStatus && (
              <Typography fontSize={'0.87rem'} color={'red'} mb={1}>
                {strings.emailIsNotVerifiedText}
              </Typography>
            )}
            {resendStatus && (
              <Typography fontSize={'0.87rem'} color={'green'} mb={1}>
                {strings.verificationCodeMessage}
              </Typography>
            )}
            {caseType === 'login' ? (
              <Paragraph sx={{ fontSize: '0.68rem' }}>
                {strings.loginVerificationCodeText1}
                <b>{email}</b>
                {strings.loginVerificationCodeText2}
              </Paragraph>
            ) : (
              <Paragraph sx={{ fontSize: '0.68rem' }}>
                {strings.email_verification_message_a}&nbsp;
                <b>{email}</b>. {strings.email_verification_message_b}
              </Paragraph>
            )}
          </DialogContentText>
          <Grid container flexDirection={'column'} alignItems={'stretch'} gap={1}>
            <Grid item>
              <TextField
                error={hasError}
                helperText={hasError ? strings.requiredField : ''}
                sx={{
                  mt: '28px',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.secondary.main,
                    borderRadius: 0,
                  },
                  '.MuiInputBase-root': {
                    backgroundColor: theme.palette.secondary.light,
                    height: '39px',
                  },
                }}
                fullWidth
                placeholder={strings.enterCodePlaceholder}
                value={verificationCode}
                onChange={(e: any) => {
                  setHasError(e.target.value === '');
                  setVerificationCode(e.target.value);
                }}
              />
            </Grid>
            <Grid item alignSelf={'end'}>
              <Box p={0.5} onClick={resendOtp} sx={{ cursor: 'pointer' }}>
                <Link underline={'hover'} color={theme.palette.secondary.main}>
                  <Typography fontSize={'0.75rem'} fontWeight={600}>
                    {strings.resendCodeButton}
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 3, mx: 3 }}>
          <Grid container spacing={screenSizeDownSm ? 1 : 5}>
            <Grid item xs={12} sm={6}>
              <PrimaryButton fullWidth disabled={loading} variant="contained" onClick={verifyOtp}>
                {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                {strings.verifyCodeButton}
              </PrimaryButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              {caseType !== 'forget_password' ? (
                <PrimaryButton fullWidth disabled={loading} variant="outlined" onClick={verifyLater}>
                  {strings.verifyLaterButton}
                </PrimaryButton>
              ) : (
                <PrimaryButton fullWidth disabled={loading} variant="outlined" onClick={onClose}>
                  {strings.closeText}
                </PrimaryButton>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <ForgetPasswordModal
        open={openForgetPasswordModal}
        setOpen={setOpenForgetPasswordModal}
        setDrawerState={setDrawerState}
        setDrawerMessage={setDrawerMessage}
        email={email}
      />
    </div>
  );
};
