import { Box, InputAdornment, MenuItem, Select, TextField, TextFieldProps, useTheme } from '@mui/material';
import strings from 'common/Translation/Translate';
import { FormValidators } from 'components/Form';
import Paragraph from 'components/Typography/Typography';
import {
  CountryCode,
  ParseError,
  getCountryCallingCode,
  parsePhoneNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js';
import { FC, useState } from 'react';
import { FlagList } from 'utils/validators/HelperComponents';
import { getCountryNameByCountryCode } from 'utils/validators/HelperFunctions';
import { validateInput } from 'utils/validators/Validators';

interface OwnProps {
  leftIcon?: string;
  inputValue?: any;
  field: string;
  typeValue?: string;
  textChange?: (value: any, field: string, error?: { error: boolean; message: string }) => void;
  validators: FormValidators[];
  hasError?: boolean;
  fieldError?: boolean | string;
  multiline?: boolean;
  rows?: number | string;
  minRows?: number | string;
  maxRows?: number | string;
  infoText?: any;
  defaultCountry: CountryCode;
  required: boolean;
}
const PhoneInputField: FC<OwnProps & TextFieldProps> = ({
  hasError,
  validators,
  textChange,
  field,
  inputValue,
  // placeholder,
  fieldError,
  inputProps,
  // InputProps,
  variant,
  disabled,
  label,
  size = 'medium',
  // typeValue,
  style,
  autoFocus,
  sx,
  className,
  defaultCountry,
  required,
  ...rest
}) => {
  const [error, setError] = useState<any>(null);
  const theme = useTheme();
  const handleChange = (value: string, countryCode: any) => {
    let newValue = '';
    let inputError = { error: false, message: '' };
    if (value === '' && !required) {
      setError(null);
      console.log('counryCode, value : ', inputError);

      if (textChange) {
        textChange({ countryCode: countryCode, value: newValue }, field, inputError);
      }
      return;
    }
    try {
      if (countryCode !== inputValue.countryCode) {
        newValue = '+' + getCountryCallingCode(countryCode).toString();
        newValue += value !== '' ? parsePhoneNumberWithError(value).nationalNumber : '';
        if (!Number.isNaN(Number(newValue))) {
          inputError = validateInput(validators, newValue);
          setError(inputError);
        }
      } else {
        newValue = value;
        const possibleCountries = parsePhoneNumberWithError(value).getPossibleCountries();
        if (!possibleCountries.includes(countryCode)) {
          countryCode = possibleCountries[0];
        }
        console.log(possibleCountries);
      }
      if (!parsePhoneNumber(newValue, countryCode).isValid()) {
        inputError = { error: true, message: strings.errorPhoneNumber };
        setError(inputError);
      }
    } catch (e) {
      console.log('error: ', (e as ParseError).message);
      inputError = { error: true, message: strings.errorPhoneNumber };
      setError(inputError);
    }
    if (!inputError.error) {
      setError(null);
    }
    console.log('counryCode, value : ', inputError);

    if (textChange) {
      textChange({ countryCode: countryCode, value: newValue }, field, inputError);
    }
  };
  const customMessage = () => {
    if (!error && hasError && fieldError) {
      return strings.requiredField;
    } else return '';
  };

  return (
    <>
      {label ? <Paragraph sx={{ fontSize: '0.75rem', marginBottom: '6px' }}>{label}</Paragraph> : null}
      <TextField
        value={inputValue.value || `+${getCountryCallingCode(defaultCountry)}`}
        onChange={e => handleChange(e.target.value, inputValue.countryCode)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Select
                sx={{
                  pl: 2,
                  minWidth: '60px',
                  '.MuiSelect-select > div > span': {
                    display: 'none',
                  },
                  border: 0,
                  boxShadow: 'none',
                  '&.Mui-focused ': {
                    border: 0,
                    background: 'white !important',
                  },
                }}
                variant="standard"
                disableUnderline
                value={inputValue.countryCode || defaultCountry}
                onChange={e => handleChange(inputValue.value, e.target.value)}>
                {FlagList.map((item, i) => (
                  <MenuItem key={item.value} value={item.value} sx={{ minWidth: '300px' }}>
                    <>{item.label}</>
                    <Box display="flex" alignItems="center" justifyContent="space-between" ml={1} width={'100%'}>
                      <span>{getCountryNameByCountryCode(item.value)}</span>
                      <span>+{getCountryCallingCode(item.value).toString()}</span>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
        }}
        autoFocus={autoFocus}
        sx={{
          ...sx,
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
            borderRadius: '0%',
          },
          '.MuiInputBase-root': {
            backgroundColor: 'white',
            height: '39px',
          },
          '.MuiOutlinedInput-root': {
            paddingLeft: '0px!important',
          },
        }}
        size={size}
        inputProps={inputProps}
        disabled={disabled}
        helperText={error && error.error ? error.message : customMessage()}
        error={(error && error.error) || (!error && hasError && fieldError) ? true : false}
        color="primary"
        fullWidth
        className={`input-field ${fieldError}`}
        variant={variant}
      />
    </>
  );
};

export default PhoneInputField;
