import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { AlertColor, Box, Card, CardMedia, Typography, useMediaQuery, useTheme } from '@mui/material';
import PageLoader from 'components/PageLoader';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Scrollbar } from 'react-scrollbars-custom';
import { setCostingSliceInitState } from 'utils/redux/reducer/costing-slice';
import { getBaseUrl } from 'utils/validators/HelperFunctions';
import strings from '../../common/Translation/Translate';
import PrimaryButton from '../../components/Button/PrimaryButton';
import Notification from '../../components/Notification';
import ReadMore from '../../components/ReadMore';
import { routes } from '../../utils/constants/routes';
import { setSelectedOrg, setVenueList } from '../../utils/redux/reducer/searched-data-slice';
import { removeFromStorage } from '../../utils/storage';

type OrganisationCardProps = {
  name: string;
  description: string;
  imgUrl: string;
  location: any;
  id: any;
};
const OrganisationCard = ({ org }: { org: OrganisationCardProps }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const screenSizeDownXl = useMediaQuery(theme.breakpoints.down('xl'));
  const screenSizeDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const screenSizeUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { name, description, imgUrl, location } = org;
  const dispatch = useDispatch();
  const [loader] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  useEffect(() => {
    removeFromStorage('venueList', true);
    dispatch(setVenueList({}));
    dispatch(setCostingSliceInitState({}));
  }, []);

  const handleViewDetails = async (id: any) => {
    dispatch(setSelectedOrg(id));
    navigate(routes.available_venues);
  };

  const charLength = () => {
    if (screenSizeDownXl && screenSizeUpLg) return 450;
    else if (screenSizeDownLg && screenSizeUpMd) return 250;
    else if (screenSizeDownMd && screenSizeUpSm) return 250;
    // else if(screenSizeDownSm) {console.log('hi4'); return 70;}
    else return 650;
  };

  return (
    <>
      {loader && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      {screenSizeDownSm ? (
        <Card sx={{ my: 2 }} elevation={10}>
          <Box
            sx={{
              p: 1.3,
              overflow: 'hidden',
              flex: 1.5,
              height: '205px',
            }}>
            <CardMedia
              component="img"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'fill',
              }}
              image={getBaseUrl() + imgUrl}
              alt={strings.noImageAvailableText}
            />
          </Box>

          <Box sx={{ display: 'flex', flex: 4, flexGrow: 4, p: 2 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                mx: 2,
                width: '-webkit-fill-available',
              }}>
              <Scrollbar style={{ width: '100%', height: '15vh' }}>
                <Box mr={0} sx={{ overflowY: 'auto' }}>
                  <Typography fontSize={'14px'} fontWeight={600} color={theme.palette.primary.main}>
                    <b>{name}</b>
                  </Typography>
                  <Typography sx={{ my: '12px' }} fontSize={'12px'}>
                    <ReadMore text={description} idealLength={100} />
                  </Typography>
                </Box>
              </Scrollbar>
              {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          > */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1,
                }}>
                <LocationOnOutlinedIcon color="primary" sx={{ fontSize: '14px' }} />
                <Typography ml={0.7} fontSize={'12px'} fontWeight={600} color={theme.palette.primary.main}>
                  <b>
                    <u>{location.label}</u>
                  </b>
                </Typography>
              </Box>
              <PrimaryButton
                variant="contained"
                sx={{ backgroundColor: theme.palette.primary.main, flex: 1 }}
                onClick={() => handleViewDetails(org.id)}>
                {strings.viewDetailButton}
              </PrimaryButton>
            </Box>
          </Box>
          {/* </Box> */}
        </Card>
      ) : (
        <Card sx={{ my: 2 }} elevation={10}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '205px',
              // ":hover": {
              //   "& .MuiCardMedia-img": {
              //     transform: "scale(1.1)",
              //   },
              // },
            }}>
            <Box
              sx={{
                p: 1.3,
                overflow: 'hidden',
                flex: 1.5,
                height: '100%',
              }}>
              <CardMedia
                component="img"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'fill',
                  // transition: "all 0.4s linear",
                  // overflowClipMargin: "content-box",
                  // overflow: "clip",
                }}
                image={getBaseUrl() + imgUrl}
                alt={strings.noImageAvailableText}
              />
            </Box>

            <Box sx={{ display: 'flex', flex: 4, flexGrow: 4, p: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  mx: 2,
                  width: '-webkit-fill-available',
                }}>
                <Scrollbar style={{ width: '100%', height: '50vh' }}>
                  <Box mr={screenSizeDownMd ? 0 : 18} sx={{ overflowY: 'auto' }}>
                    <Typography fontSize={'14px'} fontWeight={600} color={theme.palette.primary.main}>
                      <b>{name}</b>
                    </Typography>
                    <Typography sx={{ my: '12px' }} fontSize={'12px'}>
                      <ReadMore text={description} idealLength={charLength()} />
                    </Typography>
                  </Box>
                </Scrollbar>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <LocationOnOutlinedIcon color="primary" sx={{ fontSize: '14px' }} />
                    <Typography ml={0.7} fontSize={'12px'} fontWeight={600} color={theme.palette.primary.main}>
                      <b>
                        <u>{location.label}</u>
                      </b>
                    </Typography>
                  </Box>
                  <PrimaryButton
                    variant="contained"
                    sx={{ backgroundColor: theme.palette.primary.main, flex: 1 }}
                    onClick={() => handleViewDetails(org.id)}>
                    {strings.viewDetailButton}
                  </PrimaryButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </>
  );
};

type OrganisationListProps = {
  data: any;
};
const OrganisationList = ({ data }: OrganisationListProps) => {
  return data.map((item: any) => <OrganisationCard org={item} key={item.id} />);
};

export default OrganisationList;
