export const storageSchema: any = {
  type: 'object',
  properties: {
    //access_token: { type: "string", nullable: true },
    privilegeList: {
      type: 'array',
      items: {
        type: 'string',
      },
      nullable: true,
    },
    //refresh_token: { type: "string", nullable: true },
    preview: { type: 'string', nullable: true },
    userDetails: { type: 'object', nullable: true },
    languages: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          text: { type: 'string' },
          value: { type: 'string' },
        },
      },
      nullable: true,
    },
    theme: {
      type: 'object',
      properties: {
        primary: { type: 'string' },
        primaryTextColor: { type: 'string' },
        secondaryMain: { type: 'string' },
        secondaryDark: { type: 'string' },
        secondaryLight: { type: 'string' },
        secondaryTextColor: { type: 'string' },
        hoverColor: { type: 'string' },
        buttonGradiant: { type: 'string', nullable: true },
        headerColor: { type: 'string', nullable: true },
      },
    },
    selectedLanguage: {
      type: 'object',
      properties: {
        value: { type: 'string' },
      },
    },
    initialData: {
      type: 'object',
      properties: {
        orgId: { type: 'integer', nullable: true },
        platform: { type: 'boolean' },
        htmlHeader: { type: 'string', nullable: true },
        htmlFooter: { type: 'string', nullable: true },
        logoUrl: { type: 'string' },
      },
    },
    selectedVenuesForCosting: { type: 'array', nullable: true },
    selectedRoomsForCosting: { type: 'array', nullable: true },
    searchedData: { type: 'array', nullable: true },
    searchFormData: {
      type: 'object',
      properties: {
        location: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              value: { type: 'integer' },
              label: { type: 'string' },
            },
          },
        },
        eventType: { type: 'integer' },
        timeSlot: { type: 'integer' },
        guests: { type: 'string' },
        startDate: { type: 'string' },
        endDate: { type: 'string' },
      },
      nullable: true,
    },
    venueList: { type: 'object', nullable: true },
    selectedOrg: { type: 'string', nullable: true },
    eventTypeList: { type: 'array', nullable: true },
    timeSlotList: { type: 'array', nullable: true },
  },
};
