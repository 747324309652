import { Box, useTheme } from '@mui/material';
import strings from 'common/Translation/Translate';
import { FC, useEffect, useState } from 'react';

const ReadMore: FC<any> = ({ text, idealLength = 50 }: { text: any; idealLength: number }) => {
  const theme = useTheme();
  const [renderedText, setRenderedText] = useState(text.substring(idealLength));
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (toggle) setRenderedText(text.substring(0, idealLength));
    else setRenderedText(text);
  }, [toggle]);

  return (
    <Box onClick={() => setToggle(!toggle)}>
      {idealLength < text.length ? (
        <>
          {renderedText}
          <b style={{ color: theme.palette.primary.main, cursor: 'pointer' }}>
            {toggle ? strings.readMoreText : strings.showLessText}
          </b>
        </>
      ) : (
        <>{text}</>
      )}
    </Box>
  );
};
export default ReadMore;
