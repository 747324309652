import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import strings from "../../common/Translation/Translate";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import PrimaryButton from "components/Button/PrimaryButton";
import { URLS } from "utils/constants/urls";
import axiosInstance from "utils/redux/axios";


type DialogProps = {
    open: boolean;
    setOpen: any;
    subTitle: string;
    buttonText: string;
    header: string;
    bookingId: any;
    setMessage: any;
    caseType: 'Edit' | 'Cancel';
    getIniialData: any
};
export const EditBookingFormDialog: React.FC<DialogProps> = ({
    open,
    setOpen,
    header,
    subTitle,
    buttonText,
    bookingId,
    setMessage,
    caseType,
    getIniialData,
}) => {
    const theme = useTheme();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [reason, setReason] = React.useState<string>('');
    const [hasError, setHasError] = React.useState<boolean>(false);

    const onClose = () => {
        setOpen(false);
        setReason('');
        setHasError(false)
    }

    const handleClick = async (id: any) => {
        try {
            if (reason === '') {
                setHasError(true)
            }
            else {
                setLoading(true);
                const body = caseType === 'Edit' ? {
                    "status": "EDIT_BOOKING_REQUEST",
                    "requestedChanges": reason,
                    "id": id
                } : {
                    "status": "CANCELLED",
                    "cancelReason": reason,
                    "id": id
                }
                const { status } = await axiosInstance.put(URLS.editBooking(id), body);
                if (status === 200) {
                    setLoading(false);
                    onClose();
                    setMessage({
                        display: true,
                        severity: "success",
                        message: caseType === 'Edit' ? strings.editBookingSuccessText : strings.cancelBookingSuccessText,
                    });
                    getIniialData();
                }
            }
        } catch (error: any) {
            setLoading(false);
            onClose();
            setMessage({
                display: true,
                severity: "error",
                message: error.response.data.message,
            });
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={onClose}
                sx={{
                    px: 3,
                    // '.MuiDialog-paper': { width: '60vw' }
                }}>
                <DialogTitle sx={{ color: theme.palette.primary.main }}>
                    <Grid container alignItems='flex-start' justifyContent='space-between'>
                        <Grid item xs={11}>
                            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{header}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <ClearOutlinedIcon
                                onClick={onClose}
                                sx={{
                                    ":hover": { cursor: "pointer" },
                                    color: theme.palette.primary.main,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{
                        mt: 2,
                        borderStyle: "dashed",
                        borderColor: theme.palette.secondary.main,
                    }}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography fontSize={'0.75rem'}>
                            {subTitle}
                        </Typography>
                    </DialogContentText>
                    <Grid container flexDirection={'column'} alignItems={'stretch'} gap={1}>
                        <Grid item>
                            <TextField
                                multiline={true}
                                minRows={2}
                                maxRows={4}
                                error={hasError}
                                helperText={hasError ? strings.requiredField : ''}
                                sx={{
                                    mt: 1,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '.MuiInputBase-root': {
                                        backgroundColor: theme.palette.secondary.light
                                    }
                                }}
                                fullWidth
                                value={reason}
                                onChange={(e: any) => {
                                    setHasError(e.target.value === '')
                                    setReason(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Grid container gap={1}>
                        <Grid item>
                            <PrimaryButton
                                disabled={loading}
                                variant="contained"
                                onClick={() => handleClick(bookingId)}
                            >
                                {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                                {buttonText}
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};
