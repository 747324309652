export const URLS = {
  login: "/oauth/token",
  logout: "/authenticated/user/logout",
  forgot: "/api/users/password/forgot",
  reset: "/api/users/password/reset",
  verify: "/api/users/verify-code",
  resend: "/api/users/resend-code",
  verifyCode: '/api/users/currentUser/mail/code/verification',
  resendCode: '/api/users/resend-code',
  changePassword: '/authenticated/users/current-user/password',
  refresh_token: '/api/refresh-token',
  tarnslation: (language: string) => `/api/customer-translations/${language}`,
  getTheme: `api/organizations/theme`,
  getLanguageList: 'api/languages',
  getInitialData: 'api/organizations/initial-data',
  getSliderImages: 'api/parent-organization/slider-images',
  getEventTypeList: 'api/event-types',
  getLocationList: 'api/locations',
  getTimeSlotList: (id: any) => `api/event-type/${id}/timeslots`,
  getSearchedOrganisations: 'api/organizations',
  getAvailableVenues: (id: any) => `api/organizations/${id}/available-venues`,
  getCountryList: 'api/countries',
  getStateList: (selectedCountry: any) => `api/countries/${selectedCountry}/states`,
  getCityList: (selectedState: any) => `api/states/${selectedState}/cities`,
  getBookings: '/authenticated/currentuser/bookings/page',
  getUserDetails: '/authenticated/users/me',
  updateEmail: 'authenticated/user/update-email',
  resendOtp: 'api/users/currentUser/mail/code/resend',
  verifyOtp: 'api/users/currentUser/mail/code/verification',
  signUp: '/api/users',
  terms: '/api/terms-conditions',
  updateUser: (id: any) => `/authenticated/user/${id}/update-profile`,
  forgetPassword: '/api/users/password/forgot',
  resetPassword: 'api/users/password',
  editBooking: (id: any) => `/authenticated/user/booking-items/${id}/status`,
  submitRfq: '/api/request-for-quote',
  getRoomsList: (id: any) => `/api/organizations/${id}/room-types`,
  getEventType: (id: any) => `/api/event-types/${id}`,
  getTimeSlot: (id: any) => `/api/timeslots/${id}`,
  getMealPlanList: (orgId: any, id: any) => `/api/organizations/${orgId}/room-types/${id}/meal-plans`,
  getCostingDetails: (orgId: any) => `api/organizations/${orgId}/costing`,
  getPaymentSummary: '/authenticated/bookings/summary',
  getCostingDetailsById: (id: any) => `/authenticated/bookings/${id}/costing`,
  getPaymentSummaryById: (id: any) => `/authenticated/bookings/${id}/summary`,
  completeBooking: (id: any) => `/authenticated/bookings/${id}/status`,
  getPricePackgeDesc: (id: any) => `/api/price-packages/${id}`
};