import { useTheme } from "@mui/material";


const DrawerTopElement = () => {
    const defaultTheme = useTheme();
    return (
        <svg width="110" height="110" viewBox="0 0 140 146" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_427_29)">
                <path d="M37.8638 135C62.3608 123.208 84.9454 99.6382 98.6921 68.2957C107.744 47.6571 111.721 26.4823 111.121 7.00013L9.57013 36.0322L37.8638 135Z" fill={defaultTheme.palette.primary.main} />
            </g>
            <g filter="url(#filter1_d_427_29)">
                <path d="M19.9049 122.853C45.2323 114.87 70.3644 94.9609 88.0471 66.0455C99.6908 47.0052 106.506 26.6741 108.66 7.33011L6.45295 20.8027L19.9049 122.853Z" fill={defaultTheme.palette.primary.main} />
            </g>
            <g filter="url(#filter2_d_427_29)">
                <path d="M6 110.062C31.4638 105.461 58.2566 89.0176 79.0046 62.678C92.6668 45.3338 101.832 26.0802 106.373 7.1958L6 7.1958L6 110.062Z" fill={defaultTheme.palette.primary.main} />
            </g>
            <defs>
                <filter id="filter0_d_427_29" x="3.57013" y="0" width="119.61" height="146" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="2" />
                    <feGaussianBlur stdDeviation="4.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.42 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_427_29" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_427_29" result="shape" />
                </filter>
                <filter id="filter1_d_427_29" x="0.452942" y="0.330078" width="120.207" height="133.523" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="2" />
                    <feGaussianBlur stdDeviation="4.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.42 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_427_29" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_427_29" result="shape" />
                </filter>
                <filter id="filter2_d_427_29" x="0" y="0.195801" width="118.373" height="120.867" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="2" />
                    <feGaussianBlur stdDeviation="4.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.42 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_427_29" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_427_29" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export default DrawerTopElement;