export const en_IN = {
  searchBar_Text_location: "Location",
  searchBar_Text_eventType: "Event Type",
  searchBar_Text_TimeSlot: "Time Slot",
  searchBar_Text_NoOfGuest: "No of Guest",
  common_text_startDate: "Start Date",
  common_text_endDate: "End Date",

  loginText: "Login",
  resetCaptcha: 'Reset Captcha',
  optionalDetailsText: 'Optional Details',
  selectAddons: 'Select Addons',
  addOnQuantityErrorMsg: 'Please add valid quantity for',

  registartionFirstName: "First Name",
  registartionLastName: "Last Name",
  registartionEmailId: "Email Id",
  registartionPhoneNo: "Phone No.",
  registartionDesignation: "Designation",
  registartionCity: "City",
  registartionAddress: "Address",
  registartionPincode: "Pincode",
  registartionState: "State",
  registartionCountry: "Country",
  registartionCompanyName: "Company Name",
  companyText: "Company",
  individualText: "Individual",
  registartionGSTNo: "GST No.",
  registartionSIGNUP: "SIGN UP",
  registartionPassword: "Password",
  HaveAnAccount: "Have an account ?",
  LoginHeretext: "Login Here",
  submitTextCode: "Submit Code",
  registerText: "Register",
  dontHaveAccountText: "Don't have an account?",
  signUp: "Sign Up",
  I_Agree: "I Agree",
  I_Donot_Agree: "I Don't Agree",
  enterCodePlaceholder: "Enter code",
  enterCodeValid: "Enter valid code",
  enterCodeText: "Enter code",
  noText: "No",
  forgotSuccessMessageHeading: "Successfully",
  forgotSuccessMessage: "Now you can change Your email and password",
  no_venue_alternate_date:
    "Venues are not available for your selected dates. Please check the availability for alternate below",
  registerHereText: "Register Here",
  registrationText: "REGISTRATION",
  registrationTextLower: "Registration",
  registrationSuccessText: "Registration Successful",
  emaiChangeText: "Email Change Successful",
  okText: "Ok",
  submitCodeText: "Submit Code",
  registartionLanguage: "Language",
  forgotPasswordText: "Forgot Password",
  forgotPasswordCodeText: "Forgot Password Code",
  submitText: "SUBMIT",
  captchaValidation: "Captcha is required",
  searchButtonText: "Search",
  bookBanquetModifyText: "Modify Search",
  eventTypeplaceholderText: "Event type",
  searchStartDatePlaceholder: "Start date",
  LocationplaceholderText: "Location",
  searchEndDatePlaceholder: "End date",
  searchTimeSlotPlaceholder: "Time slot",
  searchNoOfGuestPlaceholder: "No. of guest",
  forgotPasswordLinkconfirmPasswordError: "Please enter the valid password",
  newPasswordPlaceholderText: "New Password",
  confirmPasswordPlaceholderText: "Confirm Password",
  changePasswordLinkButton: "Change Password",
  enterPasswordPlaceholderText: "Enter password",
  reEnterPasswordPlaceholderText: "Re-enter password",
  forgotPasswordLinkmatchPasswordError: "Password do not match",
  forgotPasswordLinkHeading: "Forgot Password Link",
  verifyCodeError: "The verification code entered by you is incorrect",
  resetPasswordHeaderText: "Reset Password",
  closeText: "Close",
  resetPasswordSuccessText: "Password successfully changed",
  forgotPasswordCodeMessage: "Code successfully verified",
  chargesSummaryText: "Charges Summary",
  allFieldsMandatory: "* fields are mandatory",
  datePickerValidation: "Check in date cannot be greater than check out date",
  datePickerValidationRfq:
    "Preferred start date cannot be greater than Preferred end Date",
  venueDetailsAddButton: "Add",
  venueDetailsNoOfRooms: "No. of room",
  venueDetailsFrom: "From",
  venueDetailsTo: "To",
  venueDetailsRoomType: "Room Type",
  orderSummaryCustomerName: "Customer Information",
  orderSummaryCustomerFullName: "Customer Name",
  orderSummaryCustomerEmailId: "Email Id",
  orderSummaryCustomerPhoneNumber: "Phone Number",
  orderSummaryCustomerCompanyName: "Company Name",
  orderSummaryCustomerDesignation: "Designation",
  orderSummaryCustomerGst: "Gst",
  orderSummaryCustomerAddress: "Address",
  orderSummaryCustomerCity: "City",
  orderSummaryCustomerState: "State",
  orderSummaryCustomerCountry: "Country",
  orderSummaryCustomerPinCode: "PinCode",
  orderSummaryCustomerStatus: "Status",
  orderSummaryCustomerBookingInfo: "Booking Information",
  orderSummaryCustomerStartDate: "Start Date",
  orderSummaryCustomerEndDate: "End Date",
  orderSummaryCustomerDate: "Date",
  orderSummaryCustomerGuaranteedGuests: "Guaranteed Guests",
  orderSummaryCustomerExpectedGuests: "Expected Guests",
  orderSummaryCustomerServiceCharge: "Service Charge",
  orderSummaryCustomerHallRental: "Hall Rental",
  orderSummaryCustomerPricePerPerson: "Price Per Person",
  orderSummaryCustomerTimeSlotText: "TimeSlot",
  orderSummaryCustomerPerStatus: "Status",
  orderSummaryCustomerTimeSlot: "Time",
  orderSummaryAvailableRooms: "Available Rooms",
  tentativeSuccessMessage: "The requested venue(s) have been reserved for you on a tentative basis!",
  waitlistedSuccessMessage:
    "Your request has been successfully added to the wait list.",
  paymentSuccessMessage: 'Payment Successful',
  paymentFailedMessage: 'Payment Failed',
  paymentIdText: 'Payment ID',
  successText: 'Success!',
  confirmBookingSuccessMessage: "Congratulations! Your booking is successful!",
  viewDetailButton: "View Details",
  novenues: "No venues available",
  venuesListTabText: "VENUES LIST",
  aboutTabText: "About",
  verifyCodeButton: " Verify Code",
  verifyLaterButton: "Verify Later",
  resendCodeButton: "Resend code",
  selectedVenueListHeaderText: "Selected Venue List",
  NoVenuesSelected: "No venues selected",
  availableVenuesRoomTypeText: "Rooms",
  resetPasswordButton: "Reset Password",
  signInHeader: "Sign in to access account",
  email: "Email",
  userName: "UserName",
  layouts: "Layouts",
  password: "Password",
  login: "LOGIN",
  emptyEmailError: "Email is empty",
  invalidEmailError: "Invalid email",
  emptyPasswordError: "Password is empty",
  invalidEmail: "Invalid email",
  invalidPassword: "Invalid password",
  save: "Save",
  mandatoryText: "This field is Mandatory",
  requiredField: "This field is required",
  emailField: "Please enter a valid email address",
  errorPhoneNumber: "Please enter a valid Phone Number",
  errorDecimalField: "Please enter valid Amount",
  errorGstField: "Please enter valid GST NO.",
  errorPinCodeField: "Please enter valid Pin Code",
  errorAlphaBets: "Please enter only alphabets",
  maxValueError: "No. of guest exceeded",
  minValueError: "Please enter valid No.",
  futureDateError: "Please enter current or a future date",
  roomError: "No. of rooms exceeded",
  responseErrorText: "Something went wrong",
  bookBanquetText: "Search Venues",
  checkAvailabilityText: "Check Availability",
  verificationCodeMessage: "Verification code sent successfully",
  selectedDateMessage:
    "You have not selected venues for all dates. Do you want to continue?",
  areYouSureText: "Are you sure?",
  continueText: "Continue",
  widthText: "Width",
  lengthText: "Length",
  heightText: "Height",
  floorPlan: "Floor Plan",
  settingStyleText: "SEATING STYLE",
  venueAvailableText: "AVAILABLE",
  venueWaitlistedText: "WAITLISTED",
  checkAvailableVenuesText: "Check available venues",
  venuesNoAvailableText:
    " We are sorry there are no venues available right now. Would you like to check for an alternate date and time?",
  pricePerPerson: "Price Per Person",
  bookingPolicyText: "Booking Policy",
  termsConditionText: "Terms & Conditions",
  signInText: "Sign In",
  emailIsNotVerifiedText: "Your Email is not Verified",
  verifyLaterText: "Verify Later",
  verifyOtp: "Verify OTP",
  roomTypeText: "Room type",
  datesText: "Dates",
  noOfRoomsText: "No. of rooms",
  actionsText: "Actions",
  bookTentativeButton: "Book Tentative",
  bookButton: "Book to Confirm",
  makePayment: "Make Payment",
  addToWaitlistText: "Add to Waitlist",
  noImageAvailableText: "No Image Available",
  billingText: "Billing",
  multipleVenuesAvailable: "Venues available",
  singleVenuesAvailable: "Venue available",
  roomLayout: "Room Layout",
  addOnsText: "Add ons",
  typeText: "Type",
  priceText: "Price",
  maxCountText: "Max Count",
  quantityText: "Quantity",
  logout: "Logout",
  proceed: "Proceed To Pay",
  bookNowText: "Book Now",
  cancelBookingText: "Cancel Booking",
  removeText: "Remove",
  userProfileText: "User Profile",
  upcomingBookings: "Upcoming Bookings",
  pastBookingsText: "Past Bookings",
  PER_GUEST: "PER_GUEST",
  PER_QUANTITY: "PER_QUANTITY",
  availablePackageText: "Available Packages",
  perPersonText: "Per Person",
  layoutPlaceholder: "Layouts (optional)",
  sameDateError: "Checkout date cannot be the same as check-in date",
  areaText: "Area",
  termsText:
    "I have read and I agree to the terms and condtions for the below venue(s)",
  termTextSignUp:
    "I have read and I agree to the terms and condtions",
  requiredFieldsText: "All fields are required unless specified optional.",
  userExpText: "Click here to Experience our venues!",
  editBooking: "Edit Booking",
  oldPasswordText: "Old Password",
  update_profile_title_text: "Update Profile",
  update_profile_update_btn: "Update",
  breadcrumb_text_venueList: "Venue List",
  breadcrumb_text_payment: "Payment",
  breadcrumb_text_orderSummary: "Order Summary",
  totalText: "Total",
  validInputError: "Please enter valid value",
  rfq_modal_text_event_type: "Purpose of the Event",
  rfq_modal_text_event_time: "Time of the Event",
  rfq_modal_text_guests: "Number of Guests",
  rfq_modal_text_venue: "Venue",
  rfq_modal_text_price_package: "Price Package",
  rfq_modal_text_date: "Preferred Start Date",
  rfq_modal_text_end_date: "Preferred End Date",
  rfq_modal_text_full_name: "Full Name",
  rfq_modal_text_mobile: "Mobile",
  rfq_modal_text_email: "Email",
  date_field_error: "Select Date",

  additional_comments_text: "Additional Comments",
  additional_comments_placerholder_text: 'Add Additional Comments Here ...',
  search_filter_multiday: "MultiDay",
  add_room_text: "Add Room",
  menuPlanText: "Food &Beverage/ Audio Visual Selection",
  rfq_modal_text_title_name: "Request For Quote",
  cancel_text: "Cancel",
  enter_valid_no_validation: "Enter valid No.",
  hallRental: "Hall Rental",
  GuestPercentageGuaranteedGuestText: "Guaranteed Guests",
  GuestPercentageExpectedGuestText: "Expected Guests",
  common_back_btn: "Back",
  selectText: "select",
  email_verification_message_a: "You will receive a verification code at",
  email_verification_message_b:
    "Please enter the code below to complete your email verification. Email verification is mandatory before login.",
  user_profile_message:
    "Please visit the Order Summary page to confirm your booking (if tentatively booked) or to make additional payments",
  user_profile_message2:
    "Visit the Order Summary page to confirm your reservation (if provisionally booked) or to make additional payments",
  view_order_summary_button: "View Order Summary",
  edit_profile_button: "Edit Profile",
  changePasswordText: "Change Password",
  change_email_text: "Change Email",
  update_email_text: "Update Email",
  time_slot_not_available: "The timeslots are not available for this event type",
  time_slot_location_required_validation: "Please select location first",
  time_slot_location_eventType_required_validation:
    "Please select Location and Event type first",
  no_venue_Found_error_message:
    "We could not find any matching venues. Please try again by changing your search options.",
  add_on_message: "Add On Quantity Cannot Be Zero",
  required_password_msg:
    "Password length should be between 8 and 15 characters and must contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character",
  countryCodeText: "Country Code",
  knowMoreText: "Know More",
  somethingWentWrongText: "Oops! something went wrong",
  returnToHomePageText: "Return to Home Page",
  loginEmailText: "Your email",
  loginPasswordText: "Your password",
  common_button_save: "Save",
  common_button_back: "Save",
  updateProfile_title_text: 'User Profile',
  logoutText: 'Logout',
  updateProfileSuccess: 'Profile update successfully',
  viewAllText: 'View all',
  editBookingHeaderText: 'Edit Your Booking',
  editBookingSubtitleText: 'Please list down your required changes for the booking',
  cancelBookingHeaderText: 'Cancel Your Booking',
  cancelBookingSubtitleText: 'Enter reason for cancellation',
  editBookingSuccessText: 'Edit Booking Request successfully sent!',
  cancelBookingSuccessText: 'Cancel Booking Request successfully sent!',
  addOnsHeaderText: "Add Ons",
  perGuestText: 'Per Guest',
  noBookingsAvailableText: 'No bookings available',
  rfqSuccessMessage: 'Form successfully submitted.',
  AddRoom: 'Add Room',
  editRoom: 'Edit Room',
  required_valid_no: 'Please enter a valid No.',
  venueDetailsMealPlan: 'Meal Plan',
  meal_plan_error: 'Please select Room Type before selecting Meal Plan',
  noteText: 'Note',

  addOnError: 'Addon quantity must be greater than 0',
  multiSelectText: 'selected',

  date_format_error: 'Please enter a valid date',

  loginVerificationCodeText1: 'Please enter the verification code sent to ',
  loginVerificationCodeText2: ' during registration or request a new one.',
  readMoreText: ' ... Read More',
  showLessText: ' ... Show Less',
  view_less_text: 'View Less',
  view_more_text: 'View More',
  bbrLogoText: "BBR Logo",

  orderIdText: 'Order ID : ',
  transactionIdText: 'Transaction ID : ',

  addOnCategoryFood: 'Food and Beverages',
  addOnCategoryAccessories: 'Accessories',
  addOnCategoryLiquor: 'Liquor',
  addOnCategoryOthers: 'Others',

  pricePackageRequiredText: 'Please select price package for',
  noOfGuestRequiredText: 'Please enter valid no of Guaranteed Guests for',
  editText: 'Edit',
  cancelText: 'Cancel',
  view_order_button: "Order Summary",
  clear_filter: 'Clear Filter',

  min_date_error: 'Please enter date greater than {0}',
  max_date_error: 'Please enter date less than {0}',

  venue_text_unit: "Sq. ft.",
  venue_text_length_unit: "ft.",

  loginFailed: 'Oops! Login expired',
  loginAgain: 'Please login again to continue your booking process.',
  backHome: 'Back to home'
};
