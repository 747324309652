import { useTheme } from "@mui/material";


const Accessories = () => {
    const theme = useTheme();
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9934 49.3848L24.8477 44.5596H35.0205L39.8747 49.3848C40.0726 49.5806 40.2296 49.8137 40.3367 50.0705C40.4439 50.3272 40.499 50.6026 40.499 50.8808C40.499 51.1589 40.4439 51.4344 40.3367 51.6911C40.2296 51.9479 40.0726 52.1809 39.8747 52.3768C39.6785 52.5743 39.4451 52.7311 39.1879 52.838C38.9307 52.945 38.6549 53.0001 38.3763 53.0001C38.0976 53.0001 37.8218 52.945 37.5646 52.838C37.3074 52.7311 37.074 52.5743 36.8778 52.3768L32.0446 47.5305V50.8808C32.0446 51.4396 31.8223 51.9756 31.4265 52.3707C31.0307 52.7659 30.4938 52.9879 29.9341 52.9879C29.3743 52.9879 28.8375 52.7659 28.4417 52.3707C28.0459 51.9756 27.8235 51.4396 27.8235 50.8808V47.5305L22.9904 52.3768C22.7942 52.5743 22.5608 52.7311 22.3036 52.838C22.0464 52.945 21.7705 53.0001 21.4919 53.0001C21.2133 53.0001 20.9374 52.945 20.6803 52.838C20.4231 52.7311 20.1896 52.5743 19.9934 52.3768C19.7956 52.1809 19.6386 51.9479 19.5315 51.6911C19.4243 51.4344 19.3691 51.1589 19.3691 50.8808C19.3691 50.6026 19.4243 50.3272 19.5315 50.0705C19.6386 49.8137 19.7956 49.5806 19.9934 49.3848Z" fill="white" />
            <rect x="1" y="8" width="57.6533" height="36.5581" rx="2.45769" fill="white" />
            <rect x="5.81299" y="12.7119" width="48.0273" height="27.2394" rx="1.22884" fill={theme.palette.primary.main} />
            <path d="M10.8306 19.8799H28.956" stroke="white" stroke-width="3.27692" stroke-linecap="round" />
            <path d="M10.8306 27.3555H22.5046" stroke="white" stroke-width="3.27692" stroke-linecap="round" />
        </svg>

    )
}

export default Accessories;