import { FormModel } from "components/Form";
import { CountryCode } from 'libphonenumber-js';
import dayjs from "dayjs";
import { Validators } from "utils/validators";

export const RfqForm = (strings: any, defaultCountryCode: CountryCode): FormModel[] => {
    const tomorrow = dayjs(new Date()).add(1, 'day');
    const arrayFields = [
        {
            label: strings.rfq_modal_text_event_type,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "eventType",
            validators: [],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,
            disabled: true

        },
        {
            label: strings.rfq_modal_text_event_time,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "timeSlot",
            validators: [],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,
            disabled: true

        },
        {
            label: strings.rfq_modal_text_guests,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "guests",
            validators: [
                { check: Validators.required, message: strings.requiredField },
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,
            disabled: true

        },
        {
            label: strings.rfq_modal_text_venue,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "venue",
            validators: [
                { check: Validators.required, message: strings.requiredField },
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,
            disabled: true

        },
        {
            label: `${strings.rfq_modal_text_date} *`,
            value: '',
            autoFocus: true,
            type: 'date',
            typeValue: 'text',
            placeholder: '',
            field: 'startDate',
            validators: [{ check: Validators.required, message: strings.requiredField }],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,
            minDate: tomorrow,
            size: 'small',
        },
        {
            label: `${strings.rfq_modal_text_end_date} *`,
            value: '',
            autoFocus: false,
            type: 'date',
            typeValue: 'text',
            variant: 'outlined',
            placeholder: '',
            field: 'endDate',
            validators: [{ check: Validators.required, message: strings.requiredField }],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,
            minDate: tomorrow,
            size: 'small',
        },
        {
            label: `${strings.rfq_modal_text_full_name} *`,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "name",
            validators: [
                { check: Validators.required, message: strings.requiredField },
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,

        },
        {
            label: `${strings.rfq_modal_text_mobile} *`,
            value: {},
            size: "small",
            autoFocus: false,
            type: "phoneInput",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "mobile",
            validators: [
                { check: Validators.required, message: strings.requiredField },
                { check: Validators.mobile, message: strings.errorPhoneNumber }
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,
            defaultCountry: defaultCountryCode
        },
        {
            label: `${strings.rfq_modal_text_email} *`,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "email",
            validators: [
                { check: Validators.required, message: strings.requiredField },
                { check: Validators.email, message: strings.emailField }
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,

        },
        {
            label: strings.registartionCompanyName,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "companyName",
            validators: [],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: false,

        },]
    return arrayFields;
}
