export const routes: { [key: string]: string } = {
  root: '/',
  login: '/login',
  sign_up: '/sign-up',
  privacy_policy: '/privacy-policy',
  forgot_password: '/forgot-password',
  reset_password: '/reset-password',
  available_venues: '/organizations/available-venues',
  not_found: '/error',
  order_summary: '/organizations/venuesDetails',
  user_profile: '/organizations/user-profile',
  payment_summary: '/organizations/orderSummary',
};
