import { Validators } from "../../../utils/validators";
import { FormModel } from "../../../components/Form";

export const ForgotPasswordForm = (strings: any): FormModel[] => {
    return [
        {
            label: `${strings.loginEmailText}`,
            value: '',
            typeValue: 'text',
            type: 'text',
            placeholder: '',
            field: 'email',
            variant: 'outlined',
            validators: [
                { check: Validators.required, message: strings.requiredField},
                { check: Validators.email, message: strings.emailField }
              ],
            required: true,
            responsive: { xs: 12 },
            size: 'small'
        },  
    ];
}
