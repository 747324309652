import { Grid } from "@mui/material";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";

type RazorPayProps = {
    responseValue: any;
    callback: any;
    isChecked: any;
}

const Razorpay = ({ responseValue, callback, isChecked }: RazorPayProps) => {
    console.log(responseValue);
    const loadScript = (src: any) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    const displayRazorpay = async () => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            alert("Razorpay SDK failed to load.");
            return;
        }
        try {
            if (responseValue) {
                const options = {
                    key: responseValue.key,
                    amount: responseValue.amount.toString(),
                    currency: responseValue.currency,
                    name: 'BBR',
                    description: ``,
                    order_id: responseValue.id,
                    handler: async function (response: any) {
                        callback(true, response.razorpay_payment_id);
                    },
                    prefill: {
                        name: responseValue.customer.name,
                        email: responseValue.customer.email,
                        contact: responseValue.customer.contact,
                    },
                    notes: responseValue.notes,

                };
                const paymentObject = new (window as any).Razorpay(options);
                paymentObject.open();
            }
        } catch (e: any) {
            console.log(e)
        }
    }
    return (
        <Grid item xs={12} sm={6} md={4} lg={2.5}>
            <PrimaryButton onClick={displayRazorpay} disabled={!isChecked}>
                {strings.makePayment}
            </PrimaryButton>
        </Grid>
    )
}

export default Razorpay;