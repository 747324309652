import { Validators } from "../../../utils/validators";
import { FormModel } from "../../../components/Form";

export const UserProfileForm = (strings: any, countryList: any, stateList: any, cityList: any, changeValueFunc: any, isCompany: boolean): FormModel[] => {
    console.log("isCompany: ", isCompany)
    const arrayFields = [
        {
            label: strings.registartionFirstName,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "firstName",
            validators: [
                { check: Validators.required, message: strings.requiredField },
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,

        },
        {
            label: strings.registartionLastName,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "lastName",
            validators: [
                {
                    check: Validators.required, message: strings.requiredField
                },
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,

        },
        {
            label: strings.registartionEmailId,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "changeValue",
            variant: "outlined",
            placeholder: "",
            field: "email",
            validators: [
                {
                    check: Validators.required, message: strings.requiredField
                },
                { check: Validators.email, message: strings.emailField }
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,
            changeValueFunc: changeValueFunc,
            disabled: true
        },
        {
            label: strings.registartionPhoneNo,
            value: {},
            size: "small",
            autoFocus: false,
            type: "phoneInput",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "phone",
            validators: [
                {
                    check: Validators.required, message: strings.requiredField
                },
                { check: Validators.mobile, message: strings.errorPhoneNumber }
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: true,
            // defaultCountry: 'IN'
        },

        {
            label: strings.registartionAddress,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "address",
            validators: [],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: false,

        },
        {
            label: strings.registartionPincode,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "pinCode",
            validators: [
                { check: Validators.pinCode, message: strings.errorPinCodeField }
            ],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: false,

        },
        {
            label: strings.registartionCountry,
            value: '',
            type: 'drop-down',
            placeholder: '',
            field: 'country',
            validators: [],
            required: false,
            responsive: { xs: 12, sm: 6, lg: 4 },
            options: countryList,
            size: 'small',

        },
        {
            label: strings.registartionState,
            value: '',
            type: 'drop-down',
            placeholder: '',
            field: 'state',
            validators: [],
            required: false,
            responsive: { xs: 12, sm: 6, lg: 4 },
            options: stateList,
            size: 'small',

        },
        {
            label: strings.registartionCity,
            value: '',
            type: 'drop-down',
            placeholder: '',
            field: 'city',
            validators: [],
            required: false,
            responsive: { xs: 12, sm: 6, lg: 4 },
            options: cityList,
            size: 'small',

        },
    ];
    const companySpecificFields = [
        {
            label: strings.registartionDesignation,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "designation",
            validators: [],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: false,

        },
        {
            label: strings.registartionCompanyName,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "companyName",
            validators: [],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: false,

        },
        {
            label: strings.registartionGSTNo,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "gst",
            validators: [],
            responsive: { xs: 12, sm: 6, lg: 4 },
            required: false,

        }
    ]
    if (isCompany) {
        arrayFields.splice(9, 0, ...companySpecificFields)
    }
    console.log('array: ', arrayFields, companySpecificFields)
    return arrayFields;
}
