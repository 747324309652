import { createSlice } from '@reduxjs/toolkit';
import { clearStorage, fetchFromStorage, removeFromStorage, saveToStorage } from '../../storage';
import { identifiers } from '../../constants/identifiers';
import { URLS } from '../../constants/urls';
import axiosInstance from '../axios';
import strings from '../../../common/Translation/Translate';

const fromStorage = fetchFromStorage(identifiers.access_token) as any;
const link = fetchFromStorage(identifiers.preview);
interface AuthenticationState {
  user: any;
  authenticated: boolean;
  privilege: any;
  translation: any;
  priviewLink: string | null;
  languageList: [] | null;
  selectedlanguage: any;
  orgInfo: null;
  theme: {
    primary: string;
    primaryTextColor: string;
    secondaryMain: string;
    secondaryDark: string;
    secondaryLight: string;
    secondaryTextColor: string;
    hoverColor: string;
    buttonGradiant?: string;
    headerColor?: string;
  };
  openAuthenticationDrawer: boolean;
  initialData: any;
}
const authenticationSlice = createSlice({
  name: 'authenticationSlice',
  initialState: {
    orgInfo: {},
    authenticated: fromStorage ? true : false,
    theme: fetchFromStorage(identifiers.theme)
      ? JSON.parse(fetchFromStorage(identifiers.theme) as any)
      : {
        primary: '#9A2143',
        primaryTextColor: "#FFFFFF",
        secondaryMain: "#BFA054",
        secondaryDark: 'FFF5DB',
        secondaryLight: '#FBF8F2',
        secondaryTextColor: "#000000",
        hoverColor: '#79112E'
      },
    user: fetchFromStorage(identifiers.userDetails)
      ? JSON.parse(fetchFromStorage(identifiers.userDetails) as string)
      : null,
    priviewLink: link || '',
    selectedlanguage: fetchFromStorage(identifiers.selectedLanguage)
      ? JSON.parse(fetchFromStorage(identifiers.selectedLanguage) as any)
      : { value: '', text: '' },
    privilege: fetchFromStorage(identifiers.privilegeList),
    translation: fetchFromStorage(identifiers.translation),
    languageList: fetchFromStorage(identifiers.languages) && JSON.parse(fetchFromStorage(identifiers.languages) as any),
    initialData: fetchFromStorage(identifiers.initialData)
      ? JSON.parse(fetchFromStorage(identifiers.initialData) as any)
      : null,
    openAuthenticationDrawer: false,
  },
  reducers: {
    setAuthentication(state, action) {
      if (action.payload) {
        const userDetails = JSON.stringify({ username: action.payload.username, email: action.payload.email });
        saveToStorage(identifiers.access_token, action.payload.access_token);
        saveToStorage(identifiers.privilegeList, action.payload.privileges);
        saveToStorage(identifiers.refresh_token, action.payload.refresh_token);
        saveToStorage(identifiers.preview, action.payload.previewLink);
        state.priviewLink = action.payload.priviewLink;
        state.authenticated = true;
        state.user = { username: action.payload.username, email: action.payload.email };
        saveToStorage(identifiers.userDetails, userDetails);
        state.privilege = action.payload.privileges;
      } else {
        removeFromStorage(identifiers.access_token);
        state.authenticated = false;
      }
      console.log('state: ', action.payload);
    },
    logout(state) {
      clearStorage('local');
      state.authenticated = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setOrgInfo(state, action) {
      state.orgInfo = action.payload;
    },
    setTranslation(state, action) {
      state.translation = action.payload;
    },
    setLanguageList(state, action) {
      saveToStorage(identifiers.languages, JSON.stringify(action.payload));
      state.languageList = action.payload;
    },
    setTheme(state, action) {
      console.log("#err-theme-action-payload: ", action.payload)
      saveToStorage(identifiers.theme, JSON.stringify(action.payload));
      state.theme = action.payload;
    },
    setSelectedLanguage(state, action) {
      saveToStorage(identifiers.selectedLanguage, JSON.stringify(action.payload));
      state.selectedlanguage = action.payload;
    },
    setIniatalData(state, action) {
      saveToStorage(identifiers.initialData, JSON.stringify(action.payload));
      state.initialData = action.payload;
    },
    setOpenAuthenticationDrawer(state, action) {
      state.openAuthenticationDrawer = action.payload;
    },
  },
});

export const refreshToken = (refreshtoken?: string) => async (dispatch: any) => {
  const body = new URLSearchParams();
  const token = fetchFromStorage(identifiers.refresh_token);

  body.append('refresh_token', refreshtoken || token || '');
  body.append('scope', 'webclient');
  body.append('grant_type', 'refresh_token');
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    auth: {
      username: process.env.REACT_APP_CLIENT,
      password: process.env.REACT_APP_SECRET,
    },
  };

  // return axiosInstance.post(URLS.login, body, config as any).then(({ data, status }) => {
  //   try {
  //     if (status === 200) {
  //       // removeFromStorage(identifiers.token);
  //       dispatch(setAuthentication(data));
  //     }
  //   } catch (e: any) {
  //     console.log(e);
  //   }

  try {
    const { status, data } = await axiosInstance.post(URLS.login, body, config as any);
    if (status === 200) {
      ;
      dispatch(setAuthentication(data));
    }
  } catch (e) {
    return e;
  }
};

export const handleLanguageChange = (lang: any) => async (dispatch: any) => {
  try {
    const { status, data } = await axiosInstance.get(URLS.tarnslation(lang.value));
    if (status === 200) {
      dispatch(setSelectedLanguage(lang));
      dispatch(setTranslation(data));
      strings.setContent({
        [lang]: data,
      });
    }
  } catch (e) {
    return e;
  }
};

export const handleLanguageOnChange = (lang: any) => async (dispatch: any) => {
  dispatch(handleLanguageChange(lang) as any);
  window.location.reload();
};


export default authenticationSlice.reducer;

export const {
  setAuthentication,
  setOrgInfo,
  logout,
  setUser,
  setTranslation,
  setLanguageList,
  setTheme,
  setSelectedLanguage,
  setIniatalData,
  setOpenAuthenticationDrawer,
} = authenticationSlice.actions;

export const User = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.user;
export const getTheme = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.theme;
export const Privilege = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.privilege;
export const translation = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.translation;
export const languages = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.languageList;
export const organizationInfo = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.orgInfo;
export const defaultLanguage = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.selectedlanguage;
export const Authenticated = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.authenticated;
export const priviewLink = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.priviewLink;
export const openAuthenticationDrawer = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.openAuthenticationDrawer;

export const initData = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.initialData;
