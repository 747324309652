import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AlertColor,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import DrawerMenu from 'components/DrawerMenu';
import Form, { FormDataModel } from 'components/Form';
import Notification from 'components/Notification';
import PageLoader from 'components/PageLoader';
import dayjs from 'dayjs';
import { Dispatch, MouseEvent, RefObject, useRef, useState } from 'react';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/redux/axios';
import { fetchFromStorage } from 'utils/storage';
import { removeErrorFieldsFromValues } from 'utils/validators';
import { getDrawerWidth } from 'utils/validators/HelperFunctions';
import { BookingRoomsForm } from './BookingRoomForm';
import { RoomsFormComponent } from './RoomsFormComponent';

type AccordianComponentProps = {
  theme: any;
  editRow: any;
  deleteRow: any;
  isEditableDataDrid?: boolean;
  rooms?: any;
};
const BookingDetailAccordianComponent = ({
  editRow,
  deleteRow,
  theme,
  isEditableDataDrid,
  rooms,
}: AccordianComponentProps) => {
  return (
    <Accordion sx={{ mb: 2, overflowX: 'auto' }} defaultExpanded={true}>
      <AccordionSummary
        sx={{
          color: theme.palette.primary.main,
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
            translate: '0 0.25em',
            borderBottom: `solid 0.2em ${theme.palette.primary.main}`,
          },
        }}
        expandIcon={<ArrowRightRoundedIcon sx={{ mr: 1, fontSize: '2.5em' }} color={'primary'} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{strings.availableVenuesRoomTypeText}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <BookingRoomsTable
          theme={theme}
          editRow={editRow}
          deleteRow={deleteRow}
          isEditableDataDrid={isEditableDataDrid}
          rooms={rooms}
        />
      </AccordionDetails>
    </Accordion>
  );
};



export const BookingRoomsTable = ({
  editRow,
  deleteRow,
  theme,
  isEditableDataDrid,
  rooms,
}: AccordianComponentProps) => {
  const actionColumn = {
    field: 'Action',
    headerName: 'Action',
    sortable: false,
    minWidth: 100,
    renderCell: (ValueFormatterParams: any) => {
      return (
        <Stack direction="row" spacing={2}>
          <ModeEditIcon fontSize="small" onClick={() => editRow(ValueFormatterParams.row.id)} />
          <DeleteIcon fontSize="small" onClick={() => deleteRow(ValueFormatterParams.row.id)} />
        </Stack>
      );
    },
    flex: 1,
  };
  const columnsHelper = [
    { field: 'roomTypeId', headerName: strings.venueDetailsRoomType, flex: 1, minWidth: 180 },
    { field: 'from', headerName: strings.venueDetailsFrom, flex: 1, minWidth: 100 },
    { field: 'to', headerName: strings.venueDetailsTo, flex: 1, minWidth: 100 },
    { field: 'numberOfRooms', headerName: strings.venueDetailsNoOfRooms, flex: 1, minWidth: 100 },
    { field: 'mealPlanName', headerName: strings.venueDetailsMealPlan, flex: 1, minWidth: 180 },
  ];
  const columns = isEditableDataDrid ? [...columnsHelper, actionColumn] : [...columnsHelper];
  const value =
    rooms?.length > 0 ? (
      <div style={{ width: '100%' }}>
        <DataGrid
          sx={{
            '.MuiDataGrid-cellContent': {
              textOverflow: 'clip',
              fontSize: '0.68rem',
              fontWeight: 500,
            },
            'div[class$="MuiDataGrid-columnHeaders"]': {
              backgroundColor: theme.palette.secondary.light,
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#fafafa',
            },
            '& .MuiSvgIcon-root': {
              color: theme.palette.primary.main,
            },
            '& .MuiDataGrid-iconSeparator': {
              color: theme.palette.secondary.light,
            },
            '& .MuiDataGrid-columnHeaders': {
              maxHeight: '30px',
              minHeight: '30px',
            },
          }}
          rowHeight={60}
          autoHeight={true}
          rows={rooms.map((item: any, idx: any) => ({
            ...item,
            id: idx,
          }))}
          isRowSelectable={() => false}
          columns={columns}
          pageSize={3}
          rowsPerPageOptions={[10]}
        />
      </div>
    ) : null;
  return value;
};

type AddEditBookingRoomsProps = {
  rooms: any;
  setRooms: Dispatch<any>;
  roomsList: any;
  isEditableDataDrid?: boolean;
};

export const AddEditBookingRooms: React.FC<AddEditBookingRoomsProps> = ({
  rooms,
  setRooms,
  roomsList,
  isEditableDataDrid,
}) => {
  const selectedOrgId = JSON.parse(fetchFromStorage('selectedOrg', true) as string);
  const theme = useTheme();
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isEditDrawer, setIsEditDrawer] = useState<boolean>(false);
  const [roomPrice, setRoomPrice] = useState<string>('');
  const [mealPrice, setMealPrice] = useState<string>('');
  const [roomsPageResponse, setRoomsPageResponse] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<any>(null);
  const [mealPlanList, setMealPlanList] = useState<any>([]);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  let formRef: RefObject<Form | null | undefined> = useRef();

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };
  const getMealPlanList = async (selectedRoomId: any) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.getMealPlanList(selectedOrgId, selectedRoomId));
      const filter = data.mealPlans?.map((item: any) => {
        return {
          label: item.name,
          value: item.mealPlanConfigId,
          formattedMealPlanPrice: item.formattedMealPlanPrice,
          price: item.price,
        };
      });
      setMealPlanList(filter);
      setLoading(false);
      return filter;
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data.message,
      });
    }
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setRoomPrice('');
    setMealPrice('');
    setRoomsPageResponse({});
    formRef.current?.resetForm();
    setIsEditDrawer(false);
    setSelectedRowId(null);
    setHasError(false);
    setMealPlanList([]);
  };

  const handleSave = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { getFormData } = formRef.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    let { formData, isFormValid } = getFormData();
    const data = removeErrorFieldsFromValues(formData);
    setHasError(false);
    let flag = false;
    if (formData.mealPlanIdError && mealPlanList.length < 1) {
      formData.mealPlanIdError = false;
    }
    for (const key in formData) {
      if (key.includes('Error')) {
        flag = formData[key] as boolean;
        if (flag) break;
      }
    }
    isFormValid = flag ? false : true;
    if (isFormValid) {
      if (dayjs(data.to as string).isBefore(dayjs(data.from as string))) {
        setMessage({
          display: true,
          severity: 'warning',
          message: strings.datePickerValidation,
        });
      } else {
        const array = [...rooms];
        const formattedData = {
          ...data,
          from: dayjs(data.from as string).format('DD-MM-YYYY'),
          to: dayjs(data.to as string).format('DD-MM-YYYY'),
          roomTypeId: roomsList.find((i: any) => i.value === data.roomType)?.label,
          mealPlanName: mealPlanList.find((i: any) => i.value === data.mealPlanId)?.label,
          mealPlanPrice: mealPlanList.find((i: any) => i.value === data.mealPlanId)?.price,
        };
        if (selectedRowId !== null) {
          array[selectedRowId] = { ...array[selectedRowId], ...formattedData };
          setSelectedRowId(null);
        } else {
          array.push(formattedData);
        }
        setRooms(array);
        handleClose();
      }
    } else {
      setHasError(true);
    }
  };
  const handleChange = (field: string, value: any) => {
    if (field === 'from') {
      formRef.current?.handleChange('', 'to', { error: true, message: strings.requiredField });
    }
    if (field === 'roomType') {
      const text = roomsList.find((i: any) => i.value === value)?.price;
      setRoomPrice(text);
      getMealPlanList(value);
      formRef.current?.handleChange('', 'mealPlanId', { error: true, message: strings.requiredField });
      setMealPrice('');
    }
    if (field === 'mealPlanId') {
      const text = mealPlanList.find((i: any) => i.value === value)?.formattedMealPlanPrice;
      setMealPrice(text);
    }
  };
  const handleFocus = (field: string, value: string | boolean, formData: FormDataModel) => {
    if (field === 'mealPlanId') {
      const { getFormData } = formRef.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData } = getFormData();
      if (formData.roomType === '') {
        setMessage({
          display: true,
          severity: 'warning',
          message: strings.meal_plan_error,
        });
      }
    }
  };

  const deleteRow = (idx: any) => {
    const deleteRow = JSON.parse(JSON.stringify(rooms));
    const deleteValue = deleteRow.filter((item: any, i: any) => i !== idx);
    setRooms(deleteValue);
  };

  const editRow = (idx: any) => {
    setSelectedRowId(idx);
    const editRow = JSON.parse(JSON.stringify(rooms));
    const editValue = editRow.find((item: any, i: any) => i === idx);
    console.log('editValue: ', editValue);
    getMealPlanList(editValue.roomType).then((mealPlans: any) => {
      setRoomsPageResponse({
        ...editValue,
        from: Date.parse(editValue.from.split('-').reverse().join('-')),
        to: Date.parse(editValue.to.split('-').reverse().join('-')),
      });
      const roomPriceText = roomsList.find((i: any) => i.value === editValue.roomType)?.price;
      const mealPriceText = mealPlans.find((i: any) => i.value === editValue.mealPlanId)?.formattedMealPlanPrice;
      console.log('mealPriceText: ', mealPlans);
      setRoomPrice(roomPriceText);
      setMealPrice(mealPriceText);
      setIsEditDrawer(true);
      setOpenDrawer(true);
    });
  };

  const getMl = () => {
    if (screenSizeDownMd) return 5;
    else if (screenSizeDownSm) return 3;
    else return 8;
  };

  return (
    <>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
          autoHideDuration={2000}
        />
      )}
      {isEditableDataDrid && (
        <Button variant="text" onClick={() => setOpenDrawer(true)} sx={{ mt: 2, fontWeight: 500, fontSize: '17px' }}>
          <span style={{ marginRight: '10px' }}> {strings.add_room_text}</span>
          <AddCircleOutlineIcon fontSize="small" />
        </Button>
      )}
      {rooms.length ? (
        <BookingDetailAccordianComponent
          theme={theme}
          editRow={editRow}
          deleteRow={deleteRow}
          isEditableDataDrid={isEditableDataDrid}
          rooms={rooms}
        />
      ) : null}
      <DrawerMenu
        open={openDrawer}
        handleClose={handleClose}
        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screenSizeUpLg)}>
        <>
          <Box display={'flex'} justifyContent={'flex-end'} px={5} pt={2}>
            <ClearOutlinedIcon
              onClick={handleClose}
              sx={{
                ':hover': { cursor: 'pointer' },
                color: theme.palette.primary.main,
              }}
            />
          </Box>
          <Typography fontWeight={'500'} ml={getMl()} mb={1} color={'primary'} fontSize={'24px'}>
            {isEditDrawer ? strings.editRoom : strings.AddRoom}
          </Typography>
          <Box mx={getMl()} bgcolor={theme.palette.secondary.light}>
            <RoomsFormComponent
              data-testid='btn'
              hasError={hasError}
              formRef={formRef}
              roomsPageResponse={roomsPageResponse}
              handleSave={handleSave}
              roomsList={roomsList}
              handleChange={handleChange}
              roomPrice={roomPrice}
              mealPlanList={mealPlanList}
              handleFocus={handleFocus}
              mealPrice={mealPrice}
            />
          </Box>
        </>
      </DrawerMenu>
    </>
  );
};
