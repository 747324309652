import { Validators } from "../../../utils/validators";
import { FormModel } from "../../../components/Form";

export const SignUpForm = (strings: any): FormModel[] => {
    return [
        {
            label: strings.registartionFirstName,
            value: "",
            size: "mdall",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "firstName",
            validators: [
                { check: Validators.required, message: strings.requiredField },
            ],
            responsive: { xs: 12, md: 6 },
            required: true,
        },
        {
            label: strings.registartionLastName,
            value: "",
            size: "mdall",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "lastName",
            validators: [
                { check: Validators.required, message: strings.requiredField },
            ],
            responsive: { xs: 12, md: 6 },
            required: true,
        },
        {
            label: strings.registartionPhoneNo,
            value: {},
            size: "mdall",
            autoFocus: false,
            type: "phoneInput",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "phone",
            validators: [
                { check: Validators.required, message: strings.requiredField },
                { check: Validators.mobile, message: strings.errorPhoneNumber }
            ],
            responsive: { xs: 12, md: 6 },
            required: true,
            //defaultCountry: 'IN'
        },
        {
            label: strings.registartionEmailId,
            value: "",
            size: "mdall",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "email",
            validators: [
                { check: Validators.required, message: strings.requiredField },
                { check: Validators.email, message: strings.emailField }
            ],
            responsive: { xs: 12, md: 6 },
            required: true,
        },
        {
            label: strings.loginPasswordText,
            value: '',
            typeValue: 'password',
            type: 'text',
            placeholder: '',
            field: 'password',
            variant: 'outlined',
            validators: [
                { check: Validators.required, message: strings.requiredField },
            ],
            required: true,
            responsive: { xs: 12, md: 6 },
            size: 'mdall',
        },

    ];
}
