import { Box, useTheme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import HeaderBackground from 'assets/images/HeaderBackground';
import { FC } from 'react';
import DrawerBottomElement from '../../assets/images/DrawerBottomElement';
import DrawerTopElement from '../../assets/images/DrawerTopElement';

type DrawerProps = {
  open: boolean;
  handleClose: () => void;
  children: any;
  width?: number | string;
  dropBack?: boolean;
  drawerState?: any;
};
const DrawerMenu: FC<DrawerProps> = ({ open, handleClose, children, dropBack = true, width = 500, drawerState }) => {
  const theme = useTheme();

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: width,
        },
      }}
      anchor="right"
      open={open}
      onClose={dropBack ? handleClose : () => {}}>
      <Box
        height={'100vh'}
        sx={{ backgroundColor: drawerState && drawerState === 3 ? theme.palette.secondary.light : 'white' }}>
        <Box position={'relative'}>
          <span
            style={{
              overflow: 'hidden',
              position: 'absolute',
            }}>
            <HeaderBackground height={'85px'} width={width} />
          </span>
          <span
            style={{
              position: 'absolute',
              top: '-8px',
              left: '-8px',
            }}>
            <DrawerTopElement />
          </span>
        </Box>
        <Box mt={10}>
          {children}
          <span
            style={{
              height: '120px',
              position: 'fixed',
              bottom: '-30px',
              right: '-20px',
              borderTopLeftRadius: '10rem',
              overflow: 'hidden',
            }}>
            <DrawerBottomElement />
          </span>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;
