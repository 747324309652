import { useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  Link,
  Breadcrumbs,
  Grid,
  Divider,
  useMediaQuery,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import "react-image-gallery/styles/css/image-gallery.css";
import Modal from "@mui/material/Modal";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import { getBaseUrl } from "utils/validators/HelperFunctions";

type OrganisationDetailsProps = {
  venueListData: any,
}
const ViewOrgDetails = ({ venueListData }: OrganisationDetailsProps) => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<boolean>(false);
  const [openViewExpModal, setOpenViewExpModal] = useState<boolean>(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" href="/">
      <HomeRoundedIcon color={"action"} />
    </Link>,
    <Link underline="none" key="2" color="primary">
      <Typography fontWeight="bold" sx={{ [theme.breakpoints.down('sm')]: { fontSize: '11px' } }}>
        {strings.breadcrumb_text_venueList}
      </Typography>
    </Link>,
    <Typography key="3" fontWeight="bold" sx={{ [theme.breakpoints.down('sm')]: { fontSize: '11px' } }}>
      {strings.breadcrumb_text_orderSummary}
    </Typography>,
    <Typography key="4" fontWeight="bold" sx={{ [theme.breakpoints.down('sm')]: { fontSize: '11px' } }}>
      {strings.breadcrumb_text_payment}
    </Typography>,
  ];

  return (
    <>
      <Box
        sx={{
          // paddingLeft: "2%",
          // paddingRight: "2%",
          position: "relative",
          marginTop: 2,
        }}
      >
        {!venueListData.videoBanner ? (
          <img
            height={screenSizeDownSm ? '400vh' : "540vh"}
            width={"100%"}
            src={getBaseUrl() + venueListData.imgUrl}
          />
        ) : null}
        <Grid container
          sx={{
            position: "absolute",
            width: screenSizeDownMd && !screenSizeDownSm ? "55%" : "100%",
            // justifyContent: "space-between",
            paddingX: screenSizeDownMd ? 3 : 5,
            paddingY: 1,
            background: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(5px)",
            alignItems: "center",
            bottom: "0.5em",
            left: screenSizeDownMd && !screenSizeDownSm ? "25%" : "0em"
          }}
        >
          <Grid item xs={12} md={6} lg={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: screenSizeDownMd && !screenSizeDownSm ? 'center' : 'start',
              color: "white",
            }}
          >
            <Typography variant="h6">
              <b>{venueListData.name}</b>
            </Typography>
            <Box
              sx={{
                paddingTop: 0.5,
                display: "flex",
                alignItems: "center"
              }}
            >
              <Grid container gap={1}>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: 5,
                    }}
                  >
                    <LocationOnOutlinedIcon sx={{ fontSize: '0.75rem' }} />
                    {venueListData.latitude && venueListData.longitude ?
                      <Link href={`https://www.google.com/maps/search/?api=1&query=${venueListData.latitude},${venueListData.longitude}`} target="_blank" color='inherit'>
                        <Typography ml={1} fontSize={'12px'} fontWeight="bold">
                          {venueListData?.location?.label}
                        </Typography>
                      </Link>
                      : <Typography ml={1} fontSize={'12px'} fontWeight="bold">
                        <u>{venueListData?.location?.label}</u>
                      </Typography>
                    }
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize={'12px'}
                      fontWeight="bold"
                      onClick={() => setOpen(true)}
                      sx={{
                        ":hover": { cursor: "pointer" },
                      }}
                    >
                      <u>{strings.knowMoreText}</u>
                    </Typography>
                    <Modal
                      open={open}
                      onClose={() => setOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          position: "absolute" as "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "40vw",
                          bgcolor: "background.paper",
                          boxShadow: 24,
                          p: 3,
                          ":focus": { border: 0 },
                        }}
                      >
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          px={1}
                          mb={1}
                          color={theme.palette.primary.main}
                        >
                          <Grid item>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              {strings.aboutTabText}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ClearOutlinedIcon
                              onClick={() => setOpen(false)}
                              sx={{ ":hover": { cursor: "pointer" } }}
                            />
                          </Grid>
                        </Grid>
                        <Divider
                          sx={{
                            borderStyle: "dashed",
                            borderColor: theme.palette.secondary.main,
                          }}
                        />
                        <Typography
                          id="modal-modal-description"
                          sx={{ mt: 2, px: 1, fontSize: '0.75rem' }}
                        >
                          {venueListData.description}
                        </Typography>
                      </Box>
                    </Modal>
                    <NavigateNextIcon />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {venueListData.showStreetViewExperience && (
              <>
                <PrimaryButton
                  variant="contained"
                  sx={{ backgroundColor: theme.palette.primary.main, flex: 1, marginY: screenSizeDownMd ? 1 : 0 }}
                  onClick={() => {
                    setOpenViewExpModal(true);
                  }}
                >
                  {strings.userExpText}
                </PrimaryButton>
                <Modal
                  open={openViewExpModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  onClose={() => {
                    setOpenViewExpModal(false);
                  }}
                  sx={{
                    padding: "5%",
                    iframe: {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <>
                    <iframe
                      title="venue"
                      src={venueListData.streetViewUrl}
                    />
                    <Box display="flex" justifyContent="flex-end">
                      <PrimaryButton
                        fullWidth
                        variant="contained"
                        sx={{ backgroundColor: theme.palette.primary.main }}
                        onClick={() => {
                          setOpenViewExpModal(false);
                        }}
                      >
                        {strings.closeText}
                      </PrimaryButton>
                    </Box>
                  </>
                </Modal>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box pt={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
    </>
  )
}


export default ViewOrgDetails;
