import { Box, CircularProgress, Grid, Link, Typography, useTheme } from '@mui/material';
import { ChangeEmailFormDialog } from 'components/Dialog/ChangeEmailFormDialog';
import { FC, MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import strings from '../../../common/Translation/Translate';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import Captcha from '../../../components/Captcha/Captcha';
import Form, { FormDataModel } from '../../../components/Form';
import { URLS } from '../../../utils/constants/urls';
import axiosInstance from '../../../utils/redux/axios';
import { setNotificationMessage } from '../../../utils/redux/reducer/Notification-details-slice';
import { languages } from '../../../utils/redux/reducer/authentication-slice';
import { removeErrorFieldsFromValues } from '../../../utils/validators';
import { createDropdownData } from '../../../utils/validators/HelperFunctions';
import { SignUpForm } from './SignUpForm';
import { SignUpFormOptional } from './SignUpFormOptional';
import { TermsModal } from './TermsModal';

const SignUp: FC<any> = ({ setDrawerState, setMessage }: { setDrawerState: any; setMessage: any }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  let signUpForm: RefObject<Form | null | undefined> = useRef();
  let signUpFormOptional: RefObject<Form | null | undefined> = useRef();
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<any>([]);
  const [stateList, setStateList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const [isCompany] = useState<boolean>(false);
  const [captchaRes, setCaptchaRes] = useState<string>('');
  const [captaFlag, setCaptaFlag] = useState<boolean>(false);
  const [stateDisabled, setStateDisabled] = useState<boolean>(true);
  const [cityDisabled, setCityDisabled] = useState<boolean>(true);
  const [changeEmailFormDialogOpen, setChangeEmailFormDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [openTermsPopup, setOpenTermsPopup] = useState<boolean>(false);
  const [terms, setTerms] = useState<any>(null);
  const [signUpFormData, setSignUpFormData] = useState<any>(null);
  const languageList = createDropdownData(useSelector(languages), ['value', 'text']);
  const verifyCallback = (response: string, captchaFlag: boolean) => {
    setCaptaFlag(captchaFlag);
    setCaptchaRes(response);
  };

  const getCountry = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.getCountryList);
      const filter = createDropdownData(data, ['id', 'name']);
      setCountryList(filter);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCountry();
  }, [getCountry]);

  const getState = async (selectedCountry: any) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.getStateList(selectedCountry));
      const filter = createDropdownData(data, ['id', 'name']);
      setStateList(filter);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const getCity = async (selectedState: any) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.getCityList(selectedState));
      const filter = createDropdownData(data, ['id', 'name']);
      setCityList(filter);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const handleOptionalFormChange = (field: string, value: any) => {
    if (field === 'country') {
      setStateDisabled(false);
      setCityDisabled(true);
      signUpFormOptional.current?.handleChange('', 'state', {
        error: false,
        message: '',
      });
      signUpFormOptional.current?.handleChange('', 'city', {
        error: false,
        message: '',
      });
      setCityList([]);
      getState(value);
    }
    if (field === 'state') {
      if (value !== '') setCityDisabled(false);
      signUpFormOptional.current?.handleChange('', 'city', {
        error: false,
        message: '',
      });
      getCity(value);
    }
  };
  const resendOtpFunc = async () => {
    try {
      setLoading(true);
      const body = {
        email: email,
        mailType: 'REGISTRATION',
      };
      await axiosInstance.post(URLS.resendOtp, body);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };
  const handleSignUpHelper = async (body: any) => {
    try {
      setOpenTermsPopup(false);
      setEmail(body.email as string);
      setLoading(true);
      const { status } = await axiosInstance.post(URLS.signUp, body);
      if (status === 200) {
        setLoading(false);
        setChangeEmailFormDialogOpen(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: 'error',
            message: error.response.data.message,
          }),
        );
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: 'error',
            message: error.message,
          }),
        );
      }
    }
  };

  const handleSignUp = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const signupFormData = signUpForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const signUpFormData = signupFormData.getFormData();
    const formData1 = signUpFormData.formData;
    const isFormValid1 = signUpFormData.isFormValid;
    const bodyData1 = removeErrorFieldsFromValues(formData1);

    const signupFormDataOptional = signUpFormOptional.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const signUpFormDataOptional = signupFormDataOptional.getFormData();
    const formData2 = signUpFormDataOptional.formData;
    const isFormValid2 = signUpFormDataOptional.isFormValid;
    const bodyData2 = removeErrorFieldsFromValues(formData2);

    const body = {
      firstName: bodyData1.firstName,
      lastName: bodyData1.lastName,
      phone: bodyData1.phone,
      address: bodyData2.address,
      city: bodyData2.city,
      companyName: bodyData2.companyName,
      country: bodyData2.country,
      designation: bodyData2.designation,
      company: isCompany,
      email: bodyData1.email,
      gst: bodyData2.gst,
      password: bodyData1.password,
      pinCode: bodyData2.pinCode,
      state: bodyData2.state,
      language: bodyData2.language,
      captchaResponse: {
        captchaRes: captchaRes,
      },
    };
    try {
      setHasError(false);
      if (isFormValid1 && isFormValid2) {
        if (!captchaRes) {
          setCaptaFlag(true);
          return false;
        }
        const termsRes = await axiosInstance.get(URLS.terms);
        if (termsRes.data === null || termsRes.data === '') {
          handleSignUpHelper(body);
        } else {
          // dispatch(setOpenAuthenticationDrawer(false))
          setSignUpFormData(body);
          setTerms(termsRes.data);
          setOpenTermsPopup(true);
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: 'error',
            message: error.response.data.message,
          }),
        );
      }
    }
  };

  return (
    <>
      <Box bgcolor={theme.palette.secondary.light}>
        <Grid container py={2} px={5} alignItems={'center'} justifyContent={'space-between'}>
          <Grid item>
            <Typography fontWeight={'bold'} color={'primary'} fontSize={'20px'}>
              {strings.signUp}
            </Typography>
          </Grid>
          {/* <Grid item>
            <Grid container columnSpacing={1}>
              <Grid item>
                <PrimaryButton
                  variant={isCompany ? "outlined" : "contained"}
                  onClick={() => setIsCompany(false)}
                >
                  {strings.individualText}
                </PrimaryButton>
              </Grid>
              <Grid item>
                <PrimaryButton
                  variant={!isCompany ? "outlined" : "contained"}
                  onClick={() => setIsCompany(true)}
                >
                  {strings.companyText}
                </PrimaryButton>
              </Grid>
            </Grid>
          </Grid>*/}
        </Grid>
        <Grid container mt={1} px={4} bgcolor={theme.palette.secondary.light} spacing={1} flexDirection={'column'}>
          <Grid item>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Form hasError={hasError} ref={signUpForm as RefObject<Form>} model={SignUpForm(strings)} values={{}} />
            </Grid>
          </Grid>
          <Grid item>
            <Typography color={'secondary'} fontWeight={'bold'} my={1}>
              {strings.optionalDetailsText}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Form
                hasError={hasError}
                ref={signUpFormOptional as RefObject<Form>}
                model={SignUpFormOptional(
                  strings,
                  countryList,
                  stateList,
                  languageList,
                  cityList,
                  isCompany,
                  stateDisabled,
                  cityDisabled,
                )}
                onChange={handleOptionalFormChange}
                values={{}}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Captcha verify={verifyCallback} />
            <Typography variant="subtitle2" color="error">
              {captaFlag ? strings.captchaValidation : ''}
            </Typography>
          </Grid>
          <Grid item xs={5.2}>
            <PrimaryButton disabled={loading} sx={{ mt: 1, py: 1 }} onClick={handleSignUp} type="submit">
              {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              {strings.signUp}
            </PrimaryButton>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Box display={'flex'}>
              <Typography fontSize={'0.75rem'}>{strings.HaveAnAccount}&nbsp;</Typography>
              <Link
                sx={{ cursor: 'pointer' }}
                color={theme.palette.secondary.main}
                onClick={() => setDrawerState(1)}
                underline={'hover'}>
                <Typography fontWeight={500} fontSize={'0.75rem'}>
                  {strings.LoginHeretext}
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ChangeEmailFormDialog
        open={changeEmailFormDialogOpen}
        handleClose={setChangeEmailFormDialogOpen}
        email={email}
        resendOtpFunc={resendOtpFunc}
        verifyOtpMailType={'REGISTRATION'}
        successMessage={strings.registrationSuccessText}
        header={strings.registrationTextLower}
        setDrawerState={setDrawerState}
        setDrawerMessage={setMessage}
        caseType="signup"
      />
      <TermsModal
        open={openTermsPopup}
        setOpen={setOpenTermsPopup}
        terms={terms}
        handleSave={() => handleSignUpHelper(signUpFormData)}
      />
    </>
  );
};

export default SignUp;
