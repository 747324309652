import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openPanel, setOpenPanel } from 'utils/redux/reducer/costing-slice';
import { VenueWiseAddOns } from './VenueWiseAddOns';

type AccordianComponentProps = {
  selectedVenues: any;
  setSelectedVenues: any;
  setCallAPi: any;
};

export const AddOnAccordian = ({ selectedVenues, setSelectedVenues, setCallAPi }: AccordianComponentProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const openPanelFromStore = useSelector(openPanel);
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    openPanelFromStore !== undefined
      ? setExpanded(openPanelFromStore)
      : dispatch(setOpenPanel(selectedVenues[0]?.date));
  }, [openPanelFromStore, selectedVenues]);

  useEffect(() => {
    return () => {
      dispatch(setOpenPanel(undefined));
    };
  }, []);

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    dispatch(setOpenPanel(isExpanded ? panel : false));
  };

  return selectedVenues.map((venueItem: any) => (
    <Accordion
      key={venueItem.id}
      expanded={expanded === venueItem.date}
      onChange={handleAccordionChange(venueItem.date)}
      sx={{ mb: 2, overflowX: 'auto' }}>
      <AccordionSummary
        sx={{
          color: theme.palette.primary.main,
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
            translate: '0 0.25em',
            borderBottom: `solid 0.2em ${theme.palette.primary.main}`,
          },
        }}
        expandIcon={<ArrowRightRoundedIcon sx={{ mr: 1, fontSize: '2.5em' }} color={'primary'} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{venueItem.date}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0 }}>
        <VenueWiseAddOns
          selectedVenues={selectedVenues}
          venueItem={venueItem}
          setSelectedVenues={setSelectedVenues}
          setCallAPi={setCallAPi}
        />
      </AccordionDetails>
    </Accordion>
  ));
};
