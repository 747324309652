import { Validators } from "../../../utils/validators";
import { FormModel } from "../../../components/Form";

export const SignUpFormOptional = (strings: any, countryList: any, stateList: any, languageList: any, cityList: any, isCompany: boolean, stateDisabled: any, cityDisabled: any): FormModel[] => {

    const arrayFields = [
        {
            label: strings.registartionCountry,
            value: {},
            type: 'drop-down',
            placeholder: '',
            field: 'country',
            validators: [],
            required: false,
            responsive: { xs: 12, md: 6 },
            options: countryList,
            size: 'small'
        },
        {
            label: strings.registartionState,
            value: {},
            type: 'drop-down',
            placeholder: '',
            field: 'state',
            validators: [],
            required: false,
            responsive: { xs: 12, md: 6 },
            options: stateList,
            size: 'small',
            disabled: stateDisabled
        },
        {
            label: strings.registartionCity,
            value: {},
            type: 'drop-down',
            placeholder: '',
            field: 'city',
            validators: [],
            required: false,
            responsive: { xs: 12, md: 6 },
            options: cityList,
            size: 'small',
            disabled: cityDisabled
        },
        {
            label: strings.registartionAddress,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "address",
            validators: [],
            responsive: { xs: 12, md: 6 },
            required: false,
        },
        {
            label: strings.registartionPincode,
            value: "",
            size: "small",
            autoFocus: false,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "pinCode",
            validators: [
                { check: Validators.pinCode, message: strings.errorPinCodeField }
            ],
            responsive: { xs: 12, md: 6 },
            required: false,
        },
        {
            label: strings.registartionLanguage,
            value: {},
            type: 'drop-down',
            placeholder: '',
            field: 'language',
            validators: [],
            required: false,
            responsive: { xs: 12, md: 6 },
            options: languageList,
            size: 'small'
        },
    ];

    return arrayFields;
}


