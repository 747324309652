import { useTheme } from '@emotion/react';
import { FormControl, FormHelperText, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import strings from 'common/Translation/Translate';
import { FC, useState } from 'react';
import { validateInput } from '../../utils/validators/Validators';
import { FormValidators } from '../Form';
import Paragraph from '../Typography/Typography';

interface OwnProps {
  inputValue?: string;
  field?: string;
  typeValue?: string;
  textChange?: (value: string, field: string, error?: { error: boolean; message: string }) => void;
  handleFocus?: (value: string | boolean, field: string, deleted?: any) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: boolean | string;
  options: { label: string; value: string; icon?: string }[];
  additionalText?: any;
}

const DropDownInput: FC<OwnProps & SelectProps> = ({
  hasError,
  validators,
  textChange,
  handleFocus,
  field,
  inputValue,
  options,
  fieldError,
  inputProps,
  disabled,
  className,
  id,
  labelId,
  label,
  style,
  placeholder,
  sx,
  size,
  additionalText,
}) => {
  const theme: any = useTheme();

  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);

  const handleChange = (value: string) => {
    const inputError = validateInput(validators as FormValidators[], value);
    setError(inputError);
    if (textChange) {
      textChange(value, field as string, inputError);
    }
  };

  const customMessage = () => (!error && hasError && fieldError ? strings.requiredField : '');

  return (
    <>
      {label ? <Paragraph sx={{ fontSize: '0.75rem', marginBottom: '6px' }}>{label}</Paragraph> : null}
      <FormControl
        size={size}
        error={!!((error && error.error) || (!error && hasError && fieldError))}
        className={`dropdown ${className}`}
        fullWidth>
        {/* <InputLabel id={label}>{label}</InputLabel> */}
        <Select
          sx={{
            '& svg.MuiSelect-icon': {
              color: theme.palette.primary.main,
            },
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.secondary.main,
            },
            '&.MuiInputBase-root': {
              backgroundColor: 'white',
              borderRadius: '0%',
              borderWidth: '5px',
              height: '39px',
            },
            ...sx,
          }}
          style={style}
          inputProps={inputProps}
          disabled={disabled}
          labelId={labelId}
          id={id}
          value={inputValue}
          label={label}
          placeholder={placeholder}
          onChange={event => {
            handleChange(event.target.value);
          }}
          notched={false}
          onOpen={event => {
            const { value } = event.target as any;
            if (handleFocus) {
              handleFocus(value, field as string);
            }
          }}>
          {options.map(item => (
            <MenuItem
              sx={{
                '&.Mui-selected': {
                  color: 'white',
                  backgroundColor: theme.palette.primary.main,
                  ':hover': {
                    color: 'white',
                    backgroundColor: theme.palette.action.hover,
                  },
                },
                // ':hover' : {
                //   color: "white"
                // }
              }}
              key={item.value}
              value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error && error.error ? error.message : customMessage()}</FormHelperText>
      </FormControl>
      {additionalText && <Typography color="secondary">{additionalText}</Typography>}
    </>
  );
};
export default DropDownInput;
