import { Box } from '@mui/material';
import PageLoader from 'components/PageLoader';
import { useCallback, useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { URLS } from '../../utils/constants/urls';
import axiosInstance from '../../utils/redux/axios';

const SliderImages = () => {
  const [sliderImages, setSliderImages] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getSliderImages = useCallback(async () => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(URLS.getSliderImages);
      if (status === 200) {
        setSliderImages(data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, [setSliderImages]);

  useEffect(() => {
    (async () => {
      getSliderImages();
    })();
  }, [getSliderImages]);

  return (
    <>
      {loading && <PageLoader />}
      {sliderImages.length > 0 ? (
        <Box
          sx={{
            // maxHeight: '100vh',
            '.image-gallery-slide .image-gallery-image': {
              objectFit: 'fill',
            },
          }}>
          <ImageGallery
            showThumbnails={false}
            showPlayButton={false}
            autoPlay
            slideInterval={10000}
            slideDuration={2000}
            showFullscreenButton={false}
            items={sliderImages.map((item: any) => ({
              original: item.original,
            }))}
          />
        </Box>
      ) : (
        <Box height={'80vh'} width={'100vw'}></Box>
      )}
    </>
  );
};

export default SliderImages;
