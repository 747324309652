import { Grid } from "@mui/material";
import strings from "common/Translation/Translate";
import PrimaryButton from "components/Button/PrimaryButton";
import { RefObject } from "react";
import { BookingRoomsForm } from "./BookingRoomForm";
import Form from "components/Form";

type formComponentProps = {
  hasError: any;
  handleSave: (e: any) => void;
  formRef: any;
  roomsPageResponse: any;
  roomsList: any;
  handleChange: any;
  roomPrice: any;
  mealPlanList: any;
  handleFocus: any;
  mealPrice: any;
};
export const RoomsFormComponent = ({
  hasError,
  formRef,
  roomsPageResponse,
  handleSave,
  roomsList,
  handleChange,
  roomPrice,
  mealPlanList,
  handleFocus,
  mealPrice,
}: formComponentProps) => {
  return (
    <>
      <Grid container spacing={1} mt={1} px={4} py={1}>
        <Form
          hasError={hasError}
          ref={formRef as RefObject<Form>}
          model={BookingRoomsForm(roomsList, strings, roomPrice, mealPlanList, mealPrice)}
          values={roomsPageResponse}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        <Grid item sx={{ mb: 1 }} xs={12}>
          <PrimaryButton data-testid='btn' fullWidth onClick={e => handleSave(e)}>
            {strings.common_button_save}
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};