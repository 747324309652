import { createSlice } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material';

interface NotificationDetailsState {
  display: boolean;
  severity: AlertColor | null;
  message: string;
}

const notificationDetailsSlice = createSlice({
  name: 'notificationDetailsSlice',
  initialState: {
    display: false,
    severity: null,
    message: '',
  },
  reducers: {
    setNotificationMessage(state, action) {
      state.display = action.payload.display;
      state.severity = action.payload.severity || null;
      state.message = action.payload.message || '';
    },
  },
});

export default notificationDetailsSlice.reducer;

export const { setNotificationMessage } = notificationDetailsSlice.actions;

export const NotificationDisplay = (state: {
  notificationDetailsSlice: NotificationDetailsState;
}) => state.notificationDetailsSlice.display;

export const NotificationSeverity = (state: {
  notificationDetailsSlice: NotificationDetailsState;
}) => state.notificationDetailsSlice.severity;

export const NotificationMessage = (state: {
  notificationDetailsSlice: NotificationDetailsState;
}) => state.notificationDetailsSlice.message;
