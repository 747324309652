import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { URLS } from 'utils/constants/urls';
import { getBaseUrl } from 'utils/validators/HelperFunctions';
import { identifiers } from '../../constants/identifiers';
import { fetchFromStorage, removeFromStorage, saveToStorage } from '../../storage';

const baseUrl = process.env.REACT_APP_BASE_API_URL || `${window.location.protocol}//${window.location.hostname}`
const axiosInstance = Axios.create({
  baseURL: baseUrl,
  headers: { 'Content-Type': 'application/json' },
});
axiosInstance.defaults.timeout = 300000;
const token = fetchFromStorage(identifiers.refresh_token);

const refreshToken = () => {
  const body = new URLSearchParams();
  body.append('refresh_token', token || '');
  body.append('scope', 'webclient');
  body.append('grant_type', 'refresh_token');
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    auth: {
      username: process.env.REACT_APP_CLIENT,
      password: process.env.REACT_APP_SECRET,
    },
  };
  Axios.post(baseUrl + URLS.login, body, config as any)
    .then(({ data, status }) => {
      if (status === 200) {
        removeFromStorage(identifiers.token);
        const userDetails = JSON.stringify({ username: data.username, email: data.email });
        saveToStorage(identifiers.access_token, data.access_token);
        saveToStorage(identifiers.privilegeList, data.privileges);
        saveToStorage(identifiers.refresh_token, data.refresh_token);
        saveToStorage(identifiers.preview, data.previewLink);
        saveToStorage(identifiers.userDetails, userDetails);
        window.location.reload();
      }
    })
    .catch((error: any) => {
      console.log(error);
      removeFromStorage(identifiers.access_token);
      window.location.reload();
    });
};

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = fetchFromStorage(identifiers.access_token);
  const lang = fetchFromStorage(identifiers.selectedLanguage)
    ? JSON.parse(fetchFromStorage(identifiers.selectedLanguage) as any)
    : '';
  const clonedConfig: any = config;

  if (token) {
    clonedConfig.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  clonedConfig.params = {
    lang: lang ? lang.value : 'en_IN',
    ...config.params,
  };
  return clonedConfig;
});

axiosInstance.interceptors.response.use(
  (config: AxiosResponse) => {
    return config;
  },
  (error: any) => {
    if (error.response && error.response.status === 401) {
      if (error.response.data?.error_description === 'Email not verified') {
        return Promise.reject(error);
      } else if (token) refreshToken();
      else {
        return Promise.reject(error);
      }
    } else if (error.response && error.response.status === 503) {
      window.location.href = '#/errorPage';
    } else {
      return Promise.reject(error);
    }
  },
);

export default axiosInstance;
