import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';

type CcAvenueFormProps = {
  responseValue: any;
  isChecked: any;
};
const CcAvenueForm = ({ responseValue, isChecked }: CcAvenueFormProps) => {
  return (
    <div>
      {responseValue.paymentGatewayInfo && (
        <form className="form-horizontal" action={responseValue.paymentGatewayInfo.url} method="post">
          <input type="hidden" name="encRequest" value={`${responseValue.paymentGatewayInfo.encRequest}`} />
          <input type="hidden" name="access_code" value={`${responseValue.paymentGatewayInfo.accessCode}`} />
          <PrimaryButton disabled={!isChecked} type="submit">
            {strings.makePayment}
          </PrimaryButton>
        </form>
      )}
    </div>
  );
};
export default CcAvenueForm;
