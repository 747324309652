import { RefObject } from 'react';
import { ToolTip } from '../../components/Tooltip/ToolTip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Form from '../../components/Form';
import strings from '../../common/Translation/Translate';
import { countryList } from 'utils/constants/data';

type ActionColumnProps = {
  editRow: (idx: any) => void;
  deleteRow: (idx: any) => void;
  index: any;
};
export const ActionColumn = ({ editRow, deleteRow, index }: ActionColumnProps) => (
  <>
    <ToolTip title="Edit">
      <IconButton sx={{ p: 0, mr: 1 }} onClick={() => editRow(index)} aria-label="edit">
        <EditIcon fontSize="small" />
      </IconButton>
    </ToolTip>
    <ToolTip title="Delete">
      <IconButton sx={{ p: 0 }} onClick={() => deleteRow(index)} aria-label="delete">
        <DeleteOutlineOutlinedIcon fontSize="small" />
      </IconButton>
    </ToolTip>
  </>
);

type formComponentProps = {
  hasError: any;
  handleSave: (e: any) => void;
  formRef: any;
  pageResponse: any;
  dropdownValue?: any;
  formModel: any;
};
export const FormComponent = ({
  hasError,
  formRef,
  pageResponse,
  handleSave,
  dropdownValue = {},
  formModel,
}: formComponentProps) => {
  const { common_button_save } = strings as any;

  return (
    <>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Form
          hasError={hasError}
          ref={formRef as RefObject<Form>}
          model={formModel()}
          values={pageResponse}
          dropdownData={dropdownValue}
          // onChange={handleChange}
        />
      </Grid>
      <Grid container sx={{ my: 1 }}>
        <Button variant="contained" sx={{ color: '#ffffff' }} onClick={e => handleSave(e)}>
          {common_button_save}
        </Button>
      </Grid>
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const theme = useTheme();
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: screenSizeDownSm ? 0 : 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const FlagList = countryList().map((c: any) => ({
  ...c,
  label: (
    <Box display="flex" alignItems={'center'} gap={1.5}>
      <img
        alt="Flag_image"
        height={'20px'}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${c.value}.svg`}
      />
    </Box>
  ),
}));
