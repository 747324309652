import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';

type AirpayFormProps = {
  responseValue: any;
  isChecked: any;
};
const AirPayForm = ({ responseValue, isChecked }: AirpayFormProps) => {
  return (
    <div>
      {responseValue.paymentGatewayInfo && (
        <form className="form-horizontal" action={responseValue.paymentGatewayInfo.url} method="post">
          <input type="hidden" name="privatekey" value={`${responseValue.paymentGatewayInfo.privateKey}`} />
          <input type="hidden" name="checksum" value={`${responseValue.paymentGatewayInfo.checksum}`} />
          <input type="hidden" name="mercid" value={`${responseValue.paymentGatewayInfo.merchantId}`} />
          <input type="hidden" name="buyerEmail" value={`${responseValue.paymentGatewayInfo.buyerEmail}`} />
          <input type="hidden" name="buyerPhone" value={`${responseValue.paymentGatewayInfo.buyerPhone}`} />
          <input type="hidden" name="buyerFirstName" value={`${responseValue.paymentGatewayInfo.buyerFirstName}`} />
          <input type="hidden" name="buyerLastName" value={`${responseValue.paymentGatewayInfo.buyerLastName}`} />
          <input type="hidden" name="orderid" value={`${responseValue.paymentGatewayInfo.orderid}`} />
          <input type="hidden" name="amount" value={`${responseValue.paymentGatewayInfo.amount}`} />
          <input type="hidden" name="currency" value={`${responseValue.paymentGatewayInfo.currency}`} />
          <input type="hidden" name="isocurrency" value={`${responseValue.paymentGatewayInfo.isocurrency}`} />
          <PrimaryButton disabled={!isChecked} type="submit">
            {strings.makePayment}
          </PrimaryButton>
        </form>
      )}
    </div>
  );
};
export default AirPayForm;
