import { createSlice } from "@reduxjs/toolkit";
import { removeFromStorage, saveToStorage } from "../../storage";

interface SelectedVenuesState {
  selectedVenuesForCosting: any;
  selectedRoomsForCosting: any;
  openPanel: any;
  focusedInput: any;
  openPanelAddon: any;
}
const costingSlice = createSlice({
  name: "costingSlice",
  initialState: {
    selectedVenuesForCosting: [],
    selectedRoomsForCosting: [], 
    openPanel: undefined,
    focusedInput: false,
    openPanelAddon: undefined
  },
  reducers: {
    setSelectedVenuesForCosting(state, action) {
      state.selectedVenuesForCosting = action.payload;
      saveToStorage('selectedVenuesForCosting', JSON.stringify(action.payload), true);
    },
    setSelectedRoomsCosting(state, action) {
      state.selectedRoomsForCosting = action.payload;
      saveToStorage('selectedRoomsForCosting', JSON.stringify(action.payload), true);
    },
    setOpenPanel(state, action) {
      state.openPanel = action.payload;
    },
    setOpenPanelAddon(state, action) {
      state.openPanelAddon = action.payload;
    },
    setFocusedInput(state, action) {
      state.focusedInput = action.payload;
    },
    setCostingSliceInitState(state, action) {
      state.selectedVenuesForCosting = [];
      removeFromStorage('selectedVenuesForCosting', true); 
      state.selectedRoomsForCosting = [];
      removeFromStorage('selectedRoomsForCosting', true); 
      state.openPanel = undefined; 
      state.focusedInput = false;   
    }
  },

});

export default costingSlice.reducer;
export const { setSelectedVenuesForCosting, setSelectedRoomsCosting, setOpenPanel, setFocusedInput, setCostingSliceInitState, setOpenPanelAddon } = costingSlice.actions;

export const selectedVenuesForCosting = (state: { costingSlice: SelectedVenuesState }) =>
  state.costingSlice.selectedVenuesForCosting;
export const selectedRoomsForCosting = (state: { costingSlice: SelectedVenuesState }) =>
  state.costingSlice.selectedRoomsForCosting;
export const openPanel = (state: { costingSlice: SelectedVenuesState }) =>
  state.costingSlice.openPanel;
export const openPanelAddon = (state: { costingSlice: SelectedVenuesState }) =>
  state.costingSlice.openPanelAddon;
export const focusedInput = (state: { costingSlice: SelectedVenuesState }) =>
  state.costingSlice.focusedInput;


