import React, { useState, FC, MouseEvent, ChangeEvent } from "react";
import "./input.css";
import { validateInput } from "../../utils/validators/Validators";
import {
  TextField,
  IconButton,
  TextFieldProps,
  useTheme,
  Link,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { FormValidators } from "../Form";
import Paragraph from "../Typography/Typography";
import strings from "../../common/Translation/Translate";

interface OwnProps {
  leftIcon?: string;
  inputValue?: string;
  field?: string;
  typeValue?: string;
  textChange?: (
    value: string,
    field: string,
    error?: { error: boolean; message: string }
  ) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: boolean | string;
  multiline?: boolean;
  rows?: number | string;
  sx?: any;
  forgetPassword?: () => void | undefined;
  endIcon?: any,
  changeValueFunc?: any
}

const InputField: FC<OwnProps & TextFieldProps> = ({
  hasError,
  validators,
  textChange,
  field,
  inputValue,
  placeholder,
  fieldError,
  inputProps,
  InputProps,
  variant = "outlined",
  disabled,
  label,
  size = "medium",
  typeValue,
  style,
  autoFocus,
  sx,
  className,
  multiline = false,
  rows = 0,
  forgetPassword,
  changeValueFunc,
  ...rest
}) => {
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const [pass, setPass] = useState(false);
  const theme = useTheme();

  const handleChangeHelper = (value: string) => {
    const inputError = validateInput(validators as FormValidators[], value);
    setError(inputError);
    if (textChange) {
      textChange(value, field as string, inputError);
    }
  };
  const handleTogglePassword = () => {
    setPass(!pass);
  };
  const handleChange = (value: string) => {
    if (typeValue === 'number') {
      if (!Number.isNaN(Number(value))) {
        handleChangeHelper(value)
      }
    }
    else {
      handleChangeHelper(value)
    }
  }


  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const endIcon = () => {
    switch (typeValue) {
      case "password":
        return {
          ...InputProps,
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleTogglePassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              color={"primary"}
            >
              {pass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }
      case "changeValue":
        return {
          ...InputProps,
          endAdornment: (
            <IconButton
              aria-label="change value"
              onClick={changeValueFunc}
              edge="end"
              color={"primary"}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          ),
        }
      default: return { ...InputProps, notched: true }
    }
  }
  const getType = () => {
    if (pass || typeValue === 'number')
      return 'text'
    return typeValue
  }
  const customMessage = () =>
    !error && hasError && fieldError ? strings.requiredField : "";
  return (
    <>
      {label ? <Paragraph sx={{ fontSize: '0.75rem', marginBottom: '6px' }}>{label}</Paragraph> : null}
      <TextField
        data-testid={'inputField'}
        autoFocus={autoFocus}
        sx={{
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.secondary.main,
            borderRadius: "0%",
          },
          ".MuiInputBase-root": {
            backgroundColor: "white",
            height: '39px'
          },
          '.MuiInputBase-root.Mui-disabled, .MuiInputBase-input.Mui-disabled': {
            backgroundColor: '#E7E7E7',
            borderRadius: 0,
            borderColor: '#9B9B9B',
            color: '#151515'
          },
          ...sx,
        }}
        size={size}
        autoComplete="off"
        inputProps={inputProps}
        InputProps={endIcon()}
        style={style}
        disabled={disabled}
        type={getType()}
        helperText={error && error.error ? error.message : customMessage()}
        error={!!((error && error.error) || (!error && hasError && fieldError))}
        color="primary"
        fullWidth
        className={`input-field ${fieldError}`}
        value={inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          textChange ? handleChange(e.target.value) : e
        }
        // placeholder={""}
        // label={label}
        variant={variant as any}
        {...rest}
        multiline={multiline}
        rows={rows}
      />
      {forgetPassword && typeValue === "password" && (
        <Link
          sx={{ cursor: "pointer" }}
          color={theme.palette.secondary.main}
          onClick={forgetPassword}
          underline={"hover"}
        >
          <Typography fontWeight={500} fontSize={'0.75rem'}>{strings.forgotPasswordText}?</Typography>
        </Link>
      )}
    </>
  );
};
export default InputField;
