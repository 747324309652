import { AlertColor, Card, CardContent, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEmailFormDialog } from 'components/Dialog/ChangeEmailFormDialog';
import { FormDialog } from 'components/Dialog/FormDialog';
import Notification from 'components/Notification';
import PageLoader from 'components/PageLoader';
import { FC, MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ChangePasswordModal } from 'screens/AuthenticatedScreens/ChangePasswordModal';
import { refreshToken } from 'utils/redux/reducer/authentication-slice';
import strings from '../../../common/Translation/Translate';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import Form, { FormDataModel } from '../../../components/Form';
import { URLS } from '../../../utils/constants/urls';
import axiosInstance from '../../../utils/redux/axios';
import { removeErrorFieldsFromValues } from '../../../utils/validators';
import { createDropdownData } from '../../../utils/validators/HelperFunctions';
import BookingDetailsTabs from '../BookingDetailsTabs';
import { UserProfileForm } from './UserProfileForm';

const UserProfile: FC<any> = ({ handleClose, setDrawerState }: { handleClose: any; setDrawerState: any }) => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  let formRef: RefObject<Form | null | undefined> = useRef();
  const [hasError, setHasError] = useState<any>({ err: false, msg: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [isCompany, setIsCompany] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<any>([]);
  const [stateList, setStateList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [bookingDetails, setBookingDetails] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updatedEmail, setUpdatedEmail] = useState<string>('');
  const [changeEmailFormDialogOpen, setChangeEmailFormDialogOpen] = useState<boolean>(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setUpdatedEmail(userDetails.email);
  };
  const changeValueFunc = () => setOpenDialog(true);

  const resendOtpFunc = async () => {
    try {
      setLoading(true);
      const body = {
        email: updatedEmail,
        mailType: 'REGISTRATION',
      };
      await axiosInstance.post(URLS.resendOtp, body);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };
  const getCountry = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.getCountryList);
      const filter = createDropdownData(data, ['id', 'name']);
      setCountryList(filter);
    } catch (error: any) { }
  }, []);

  const getInitialData = useCallback(async () => {
    try {
      setLoading(true);
      getCountry();
      const userDetailsResponse = await axiosInstance.get(URLS.getUserDetails);
      const bookingDetailsResponse = await axiosInstance.get(URLS.getBookings);
      if (userDetailsResponse.status === 200 && bookingDetailsResponse.status === 200) {
        const userDetail = {
          ...userDetailsResponse.data,
          country: userDetailsResponse.data.country?.id,
          state: userDetailsResponse.data.state?.id,
          city: userDetailsResponse.data.city?.id,
        };
        setUserDetails(userDetail);
        setUpdatedEmail(userDetail.email);
        setIsCompany(userDetail.company);
        setStateList(createDropdownData(userDetail.states, ['id', 'name']));
        setCityList(createDropdownData(userDetail.cities, ['id', 'name']));
        setBookingDetails(bookingDetailsResponse.data);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.log('error: ', error.response.data);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data.message,
      });
    }
  }, [getCountry]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    getCountry();
  }, [getCountry]);

  const getState = async (selectedCountry: any) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.getStateList(selectedCountry));
      const filter = createDropdownData(data, ['id', 'name']);
      setStateList(filter);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const getCity = async (selectedState: any) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.getCityList(selectedState));
      const filter = createDropdownData(data, ['id', 'name']);
      setCityList(filter);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const handleOptionalFormChange = (field: string, value: any) => {
    if (field === 'country') {
      formRef.current?.handleChange('', 'state');
      formRef.current?.handleChange('', 'city');
      setCityList([]);
      getState(value);
    }
    if (field === 'state') {
      formRef.current?.handleChange('', 'city');
      getCity(value);
    }
  };

  const updateProfileBtnOnClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { getFormData } = formRef.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const bodyData = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        const body = {
          ...bodyData,
          pinCode: bodyData.pinCode === '' ? null : bodyData.pinCode,
          company: userDetails.company,
        };
        setLoading(true);
        const { status } = await axiosInstance.put(URLS.updateUser(userDetails.id), body);
        if (status === 200) {
          setLoading(false);
          setMessage({
            display: true,
            severity: 'success',
            message: strings.updateProfileSuccess,
          });
          dispatch(refreshToken() as any);
          getInitialData();
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setMessage({
          display: true,
          severity: 'error',
          message: error.response.data.error_description,
        });
      }
    }
  };

  const updateEmailBtnOnClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      if (!hasError.err) {
        setLoading(true);
        const { status } = await axiosInstance.put(URLS.updateEmail, { email: updatedEmail });
        if (status === 200) {
          setOpenDialog(false);
          setChangeEmailFormDialogOpen(true);
        }
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.message,
      });
    }
  };
  const emailRegex = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}])|(([a-zA-Z\\-\\d]+\\.)+[a-zA-Z]{2,}))$',
  );
  const validateEmail = (val: any) => {
    let error = { err: false, msg: '' };
    if (val === '') {
      error = { err: true, msg: strings.requiredField };
    } else {
      const result = emailRegex.test(val);
      if (!result) {
        error = { err: true, msg: strings.emailField };
      }
    }
    setHasError(error);
  };

  const changePasswordBtnOnClick = () => {
    setOpenChangePasswordModal(true);
  };
  const a = () => {
    if (screenSizeDownMd) return 10
    else if (screenSizeDownSm) return 5
    else return 16
  }
  const c = () => {
    if (screenSizeDownMd) return 2
    else if (screenSizeDownSm) return 1
    else return 3
  }
  const b = () => {
    if (screenSizeDownMd) return 8
    else if (screenSizeDownSm) return 3
    else return 14
  }
  return (
    <>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      {userDetails ? (
        <>
          <Typography
            color={'primary'}
            fontWeight={'600'}
            fontSize="20px"
            sx={{
              marginX: a(),
              marginTop: 2,
            }}>
            {strings.update_profile_title_text}
          </Typography>
          <Card
            sx={{
              marginTop: 2,
              marginX: b(),
              paddingX: c(),
            }}>
            <CardContent>
              <Grid container columnSpacing={4} rowSpacing={3}>
                <Form
                  hasError={hasError}
                  ref={formRef as RefObject<Form>}
                  model={UserProfileForm(strings, countryList, stateList, cityList, changeValueFunc, isCompany)}
                  onChange={handleOptionalFormChange}
                  values={userDetails}
                />
                <FormDialog
                  open={openDialog}
                  handleClose={handleCloseDialog}
                  title={strings.change_email_text}
                  contentText={strings.registartionEmailId}
                  loading={loading}
                  handleSave={updateEmailBtnOnClick}
                  buttonText={strings.update_email_text}>
                  <TextField
                    error={hasError.err}
                    helperText={hasError.msg}
                    sx={{
                      mt: 1,
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '.MuiInputBase-root': {
                        backgroundColor: theme.palette.secondary.light,
                      },
                    }}
                    fullWidth
                    value={updatedEmail}
                    onChange={(e: any) => {
                      validateEmail(e.target.value);
                      setUpdatedEmail(e.target.value);
                    }}
                  />
                </FormDialog>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems={'center'}
                    paddingX={screenSizeDownMd ? 0 : 1}
                    spacing={2}
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        justifyContent: 'center',
                        paddingX: 0,
                      },
                    }}>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <PrimaryButton
                        onClick={updateProfileBtnOnClick}
                        sx={{
                          minHeight: '44px',
                        }}>
                        {strings.update_profile_update_btn}
                      </PrimaryButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <PrimaryButton
                        onClick={changePasswordBtnOnClick}
                        variant="outlined"
                        fullWidth
                        sx={{
                          minHeight: '44px',
                          fontWeight: 600,
                          whiteSpace: 'nowrap',
                          [theme.breakpoints.down('md')]: {
                            textAlign: 'center',
                          },
                          border: 0.5,
                        }}>
                        {strings.changePasswordText}
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <BookingDetailsTabs bookingDetails={bookingDetails} getIniialData={getInitialData} />
        </>
      ) : (
        <Box height={'80vh'} width={'100vw'}></Box>
      )}
      <ChangeEmailFormDialog
        open={changeEmailFormDialogOpen}
        handleClose={setChangeEmailFormDialogOpen}
        email={updatedEmail}
        resendOtpFunc={resendOtpFunc}
        verifyOtpMailType={'EMAIL_CHANGE'}
        successMessage={strings.emaiChangeText}
        header={strings.change_email_text}
        caseType="change_email"
        getInitialData={getInitialData}
      />
      <ChangePasswordModal open={openChangePasswordModal} setOpen={setOpenChangePasswordModal} />
    </>
  );
};

export default UserProfile;
