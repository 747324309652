import {
  Card,
  CardMedia,
  Grid,
  Typography,
  useTheme,
  Link,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import strings from "../../common/Translation/Translate";
import PrimaryButton from "../../components/Button/PrimaryButton";
import ReadMore from "../../components/ReadMore";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { PricePackageCard } from "./PricePackageCard";
import ImageGallery from "react-image-gallery";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedVenuesForCosting, setSelectedVenuesForCosting } from "utils/redux/reducer/costing-slice";
import { fetchFromStorage } from "utils/storage";
import { getBaseUrl } from "utils/validators/HelperFunctions";
import HelpIcon from '@mui/icons-material/Help';
import { ToolTip } from "components/Tooltip/ToolTip";


const VenueLayout = ({ layouts }: { layouts: any }) => {
  const theme = useTheme();
  const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid container spacing={2}>
      {layouts.map((item: any) => (
        <Grid item key={item.id} mt={1}>
          <Card
            sx={{
              //width: "9vw",
              border: `1px solid ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={1}
              pr={1}
            >
              <Grid item>
                <CardMedia
                  component="img"
                  sx={{
                    borderRight: `6px solid ${theme.palette.primary.main}`,
                    height: screenSizeUpMd ? '4vw' : '6vh',
                    width: screenSizeUpMd ? '6vw' : '10vw',
                    objectFit: "fill",
                  }}
                  image={getBaseUrl() + item.imgUrl}
                  alt={''}
                />
              </Grid>
              <Grid item>
                <Typography fontWeight={"bold"} fontSize={'0.62rem'}>
                  {item.maxCapacity}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

type VenueDescriptionProps = {
  description: any;
  width: any;
  length: any;
  height: any;
  area: any;
  layouts: any;
  floorPlan: any;
};

export const VenueDescription =
  ({
    description,
    width,
    length,
    height,
    area,
    layouts,
    floorPlan,
  }: VenueDescriptionProps) => {
    const theme = useTheme();
    const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const getVenueAreaDetails = () => {
      const arr = [];
      if (width) {
        arr.push(`${strings.widthText}: ${width} ${strings.venue_text_length_unit}`)
      }
      if (length) {
        arr.push(`${strings.lengthText}: ${length} ${strings.venue_text_length_unit}`)
      }
      if (height) {
        arr.push(`${strings.heightText}: ${height} ${strings.venue_text_length_unit}`)
      }
      if (area) {
        arr.push(`${strings.areaText}: ${area} ${strings.venue_text_unit}`)
      }
      if (floorPlan) {
        arr.push('')
      }
      return arr.join(' | ')
    }
    return (
      <>
        {description && <Typography fontSize='0.75rem'>
          <ReadMore text={description} idealLength={screenSizeUpMd ? 550 : 170} />
        </Typography>}
        {(width || length || height || area || floorPlan) && (
          <Box
            bgcolor={theme.palette.secondary.light}
            p={1}
            my={1}
            border={`solid 1px ${theme.palette.secondary.main}`}
          >
            <Typography fontSize='0.65rem'>
              {getVenueAreaDetails()}
              {floorPlan && (
                <Link
                  target={"_blank"}
                  href={getBaseUrl() + floorPlan}
                  underline="hover"
                  color={"secondary"}
                >
                  {strings.floorPlan}
                </Link>
              )}
            </Typography>
          </Box>
        )}
        {layouts && <VenueLayout layouts={layouts} />}
      </>
    );
  }
const AvailablePackagesComponent = ({
  menuPlanUrl,
  prices,
  setSelectedPricePackage,
  selectedVenues,
  date,
  venueData,
  selectedPricePackage
}: any) => {
  const theme = useTheme();
  const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch()
  const handlePricePackageCardClick = (id: any) => {
    setSelectedPricePackage(id);
    const arr = selectedVenues.map((item: any) => {
      if (item.date === date) {
        const arr1 = item.venues.map((vItem: any) => {
          if (vItem.id === venueData.id) {
            return { ...vItem, pricePackageId: id }
          }
          return vItem;
        })
        return { ...item, venues: arr1 };
      }
      else return item;
    })
    dispatch(setSelectedVenuesForCosting(arr))
  };
  return (
    <>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item>
          <Typography variant="subtitle1" fontWeight={500} color={"secondary"} ml={screenSizeUpMd ? 2 : 0}>
            {strings.availablePackageText}
          </Typography>
        </Grid>
        {menuPlanUrl && (
          <Grid item>
            <Link
              p={0.5}
              href={getBaseUrl() + menuPlanUrl}
              underline="none"
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
                border: `1px solid ${theme.palette.secondary.main}`,
                ":hover": {
                  cursor: "pointer",
                  boxShadow: "0 5px 5px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <FormatListBulletedOutlinedIcon
                sx={{ mr: 1, fontSize: '0.87rem' }}
                color={"secondary"}
              />
              <Typography color={"secondary"} fontSize={'0.75rem'} >
                {strings.menuPlanText}
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} mt={0.25}>
        {prices.map((priceItem: any) => (
          <PricePackageCard
            key={priceItem.pricePackageId}
            priceItem={priceItem}
            selectedItem={selectedPricePackage}
            handleClick={handlePricePackageCardClick}
          />
        ))}
      </Grid>
    </>
  );
};

const BookOrRemoveBtn = ({ venueData, selectedPricePackage, date }: any) => {
  const dispatch = useDispatch();
  const selectedVenues = useSelector(selectedVenuesForCosting);
  const searchData = JSON.parse(
    fetchFromStorage("searchFormData", true) as string
  );

  const handleBookNow = () => {
    let array = [...selectedVenues];
    const obj = {
      ...venueData,
      bookingDate: date,
      layout: "",
      addOns: [],
      addOnList: venueData.addOns,
      pricePackageId: selectedPricePackage,
      guaranteedGuests: searchData.guests,
      expectedGuests: Math.round(parseInt(searchData.guests == '' ? '0' : searchData.guests) * (1 + venueData.excessGuestsPercentage / 100))
    };
    let idx = array.findIndex((item: any) => item.date === date);
    if (idx != -1) {
      array[idx] = { date: date, venues: [...array[idx].venues, obj] };
    }
    else {
      array.push({
        date: date,
        venues: [obj]
      });
    }
    dispatch(setSelectedVenuesForCosting(array));
  };
  const handleRemove = () => {
    const array = selectedVenues.map((item: any) => {
      if (item.date === date) {
        const a = item.venues.filter((v: any) => v.id !== venueData.id)
        return { date: item.date, venues: a }
      }
      return item;
    }).filter((item: any) => item.venues.length > 0);
    console.log('array: ', array)
    dispatch(setSelectedVenuesForCosting(array));
  };

  return !selectedVenues?.find(
    (item: any) => item.date === date && item.venues.find((v: any) => v.id === venueData.id)
  ) ? (
    <PrimaryButton onClick={handleBookNow}>
      {strings.bookNowText}
    </PrimaryButton>
  ) : (
    <PrimaryButton onClick={handleRemove}>{strings.removeText}</PrimaryButton>
  );
};


type VenueDetailsProps = {
  venueData: any;
  date: any;
  onRfqModalOpen: any;
};
export const VenueDetails = ({ venueData, date, onRfqModalOpen }: VenueDetailsProps) => {
  const theme = useTheme();
  const selectedVenues = useSelector(selectedVenuesForCosting);
  const abc = selectedVenues.find((item: any) => item.date === date)?.venues.find((vItem: any) => vItem.id === venueData.id)?.pricePackageId || null
  console.log('selectedVenues:', selectedVenues);
  const [selectedPricePackage, setSelectedPricePackage] = useState<number | null>(abc);





  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            "img[class=image-gallery-image]": {
              width: "-webkit-fill-available",
            },
          }}
        >
          {venueData.media.otherImageUrls.length >= 1 ? (
            <ImageGallery
              showThumbnails={venueData.media.otherImageUrls.length > 1}
              showPlayButton={false}
              autoPlay
              showNav={false}
              slideInterval={10000}
              items={venueData.media.otherImageUrls.map((item: any) => ({
                original: getBaseUrl() + item,
                thumbnail: getBaseUrl() + item,
              }))}
            />
          ) : (
            <Box sx={{
              backgroundColor: 'lightgrey',
              height: '160px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Typography>{strings.noImageAvailableText}</Typography>
            </Box>
          )}
          {venueData.terms ? (
            <Link
              href={getBaseUrl() + venueData.terms}
              underline="hover"
              color="secondary"
              alignItems="center"
            >
              <Grid
                container
                alignItems={"flex-start"}
                justifyContent={"center"}
                my={1}
              >
                <Grid item mr={1}>
                  <DescriptionOutlinedIcon />
                </Grid>
                <Grid item>
                  <Typography>{strings.termsConditionText}</Typography>
                </Grid>
              </Grid>
            </Link>
          ) : null}
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2} justifyContent="space-between" mb={2}>
            <Grid item>
              <Typography fontWeight="bold" color="primary" variant="h6">
                {venueData.name}
              </Typography>
              {venueData.bookingStatus === 'WAITLISTED' &&
                <Box mt={1} display={'flex'} gap={1} alignItems={'center'}>
                  <Box sx={{ border: `1px solid ${theme.palette.primary.main}`, bgcolor: theme.palette.secondary.light }} py={0.25} px={0.5}>
                    <Typography fontSize={'10px'} sx={{ letterSpacing: 1 }}>{strings.venueWaitlistedText}</Typography>
                  </Box>
                  <ToolTip title={venueData.waitListInfo}>
                    <HelpIcon fontSize="small" color="primary" />
                  </ToolTip>
                </Box>
              }
              {venueData.bookingStatus === 'AVAILABLE' &&
                <Box mt={1} display={'flex'} gap={1} alignItems={'center'}>
                  <Box sx={{ border: `1px solid ${theme.palette.primary.main}`, bgcolor: theme.palette.secondary.light }} py={0.25} px={0.5}>
                    <Typography fontSize={'10px'} sx={{ letterSpacing: 1 }}>{strings.venueAvailableText}</Typography>
                  </Box>
                </Box>
              }
            </Grid>
            {!venueData.onlyRFQ ? (
              <Grid item>
                <BookOrRemoveBtn
                  venueData={venueData}
                  selectedPricePackage={selectedPricePackage}
                  date={date}
                />
              </Grid>
            ) : (
              <Grid item>
                <PrimaryButton onClick={() => {
                  const selectedVenueForRfq = { id: venueData.id, name: venueData.name }
                  onRfqModalOpen(selectedVenueForRfq);
                }}>
                  {strings.rfq_modal_text_title_name}
                </PrimaryButton>
              </Grid>
            )}
          </Grid>
          <VenueDescription
            description={venueData.description}
            width={venueData.width}
            length={venueData.length}
            height={venueData.height}
            area={venueData.area}
            layouts={venueData.layouts}
            floorPlan={venueData.floorPlan}
          />
        </Grid>
      </Grid>
      <AvailablePackagesComponent
        menuPlanUrl={venueData.menuPlanUrl}
        prices={venueData.prices}
        setSelectedPricePackage={setSelectedPricePackage}
        selectedVenues={selectedVenues}
        date={date}
        venueData={venueData}
        selectedPricePackage={selectedPricePackage}
      />
    </>
  );
};
