import { FC } from 'react';
import Recaptcha from 'react-recaptcha';
import { identifiers } from '../../utils/constants/identifiers';
import { RefreshRounded } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { color } from '@mui/system';
import strings from 'common/Translation/Translate';

let recaptchaInstance: { reset: () => void };
interface CaptchaProps {
  verify: (response: string, captchaFlag: boolean) => void;
  testId?: string;
}
const Captcha: FC<CaptchaProps> = ({ verify, testId }) => {
  const verifyCallback = (response: any) => {
    verify(response, false);
  };

  const expiredCallback = () => {
    recaptchaInstance.reset();
    verify('', true);
  };

  return (
    <Box className="captcha" >
      <Recaptcha
        data-testid="captcha"
        hl={'en_IN'}
        data-test={testId}
        sitekey={identifiers.REACT_APP_CAPTCHA}
        render="explicit"
        size="normal"
        verifyCallback={verifyCallback}
        expiredCallback={expiredCallback}
        onloadCallback={() => { }}
      />
      <Box>
        <Button onClick={() => { (window as any).grecaptcha.reset(); verify('', true); }} sx={{ color: '#000000', px: 0, textTransform: 'capitalize' }} >
          <Grid container alignItems={"center"} gap={0.5}>
            <RefreshRounded fontSize='small' />
            {strings.resetCaptcha}
          </Grid>
        </Button>
      </Box>
    </Box >
  );
};

export default Captcha;
