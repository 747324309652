import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import PrimaryButton from "components/Button/PrimaryButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { routes } from "utils/constants/routes";
import { clearStorage } from "utils/storage";
import strings from "common/Translation/Translate";

const NotAuthenticatedPage = () => {
    const navigate = useNavigate();

    const handleBackHome = () => {
        clearStorage('all');
        navigate(routes.root);
        window.location.reload();
    }

    return (
        <Box sx={{
            p: 6,
            height: '70vh',
        }}>
            <Typography variant="h3" fontWeight={600}>
                {strings.loginFailed}
            </Typography>
            <Typography my={1}>
                {strings.loginAgain}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 4 }}>
                <PrimaryButton sx={{ width: 'max-content' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} onClick={handleBackHome}
                        data-testId='btn'>
                        <ArrowBackIcon />
                        <Typography>{strings.backHome}</Typography>
                    </Box>
                </PrimaryButton>
            </Box>
        </Box>
    )
}

export default NotAuthenticatedPage;