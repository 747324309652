import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AlertColor,
  Avatar,
  Badge,
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Captcha from 'components/Captcha/Captcha';
import Form, { FormDataModel } from 'components/Form';
import PageLoader from 'components/PageLoader';
import dayjs from 'dayjs';
import queryString from 'query-string';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';
import ViewOrgDetails from 'screens/AuthenticatedScreens/ViewOrgDetails';
import { selectedVenuesForCosting, setSelectedVenuesForCosting } from 'utils/redux/reducer/costing-slice';
import { removeErrorFieldsFromValues } from 'utils/validators';
import strings from '../../common/Translation/Translate';
import PrimaryButton from '../../components/Button/PrimaryButton';
import Notification from '../../components/Notification';
import { routes } from '../../utils/constants/routes';
import { URLS } from '../../utils/constants/urls';
import axiosInstance from '../../utils/redux/axios';
import { searchFormData, setVenueList, venueList } from '../../utils/redux/reducer/searched-data-slice';
import { fetchFromStorage } from '../../utils/storage';
import SearchBar from '../SearchBar';
import { RfqForm } from './RfqForm';
import { VenueDetails } from './VenueDetails';

const SelectedVenuesHelperComponent = ({ selectedVenues }: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        [theme.breakpoints.down('md')]: {
          overflowY: 'auto',
          pr: 3,
        },
      }}>
      {selectedVenues.map((item: any, idx: any) => (
        <>
          <Typography color={theme.palette.primary.main} fontWeight={600}>
            {item.date}
          </Typography>
          {item.venues.map((v: any) => (
            <Box
              key={v.id}
              display={'flex'}
              justifyContent="space-between"
              my={1}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                p: 1,
              }}>
              <Typography color={theme.palette.primary.main}>{v.name}</Typography>
              <ClearOutlinedIcon
                fontSize="small"
                sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                onClick={() => {
                  const array = selectedVenues
                    .map((venue: any) => {
                      if (venue.date === item.date) {
                        const a = venue.venues.filter((iv: any) => iv.id !== v.id);
                        return { date: item.date, venues: a };
                      }
                      return venue;
                    })
                    .filter((venue: any) => venue.venues.length > 0);
                  dispatch(setSelectedVenuesForCosting(array));
                }}
              />
            </Box>
          ))}
          {idx !== selectedVenues?.length - 1 ? (
            <Divider
              sx={{
                my: 1,
                borderStyle: 'dashed',
                borderColor: theme.palette.secondary.main,
              }}
            />
          ) : null}
        </>
      ))}
    </Box>
  );
};

const SelectedVenuesComponent = ({ selectedVenues, handleSubmit }: any) => {
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <>
      {selectedVenues.length > 0 ? (
        <Grid item xs={12} md={3}>
          <Box position={'sticky'} top={screenSizeUpLg ? '150px' : '30px'}>
            {screenSizeDownMd ? null : (
              <Typography fontWeight="bold" variant="h6" color={'primary'}>
                {strings.selectedVenueListHeaderText}
              </Typography>
            )}
            <Card sx={{ py: 1, px: 2, my: 1 }}>
              {screenSizeDownMd ? (
                <Scrollbar style={{ width: '100%', height: '30vh' }}>
                  <SelectedVenuesHelperComponent selectedVenues={selectedVenues} />
                </Scrollbar>
              ) : (
                <SelectedVenuesHelperComponent selectedVenues={selectedVenues} />
              )}
              <PrimaryButton onClick={handleSubmit} sx={{ my: 1 }}>
                {strings.continueText}
              </PrimaryButton>
            </Card>
          </Box>
        </Grid>
      ) : null}
    </>
  );
};

const OrganisationDetails = () => {
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search);
  const theme = useTheme();
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const venueListData = useSelector(venueList);
  const selectedVenues = useSelector(selectedVenuesForCosting);
  const searchData = useSelector(searchFormData);
  const selectedOrgId = queryStringValues.oid || JSON.parse(fetchFromStorage('selectedOrg', true) as string);
  const eventTypes = JSON.parse(fetchFromStorage('eventTypeList', true) as string);
  const timeSlots = JSON.parse(fetchFromStorage('timeSlotList', true) as string);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);
  const [openRfqModal, setOpenRfqModal] = useState<boolean>(false);
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [captchaRes, setCaptchaRes] = useState<string>('');
  const [captaFlag, setCaptaFlag] = useState<boolean>(false);
  const [rfqPageResponse, setRfqPageResponse] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  let rfqFormRef: RefObject<Form | null | undefined> = useRef();
  const openPop = Boolean(anchorEl);

  useEffect(() => {
    if (!selectedOrgId) {
      navigate(routes.root);
    }
  }, []);

  const verifyCallback = (response: string, captchaFlag: boolean) => {
    setCaptaFlag(captchaFlag);
    setCaptchaRes(response);
  };

  const onRfqModalClose = () => {
    setOpenRfqModal(false);
    setHasError(false);
    rfqFormRef?.current?.resetForm();
  };

  const onRfqModalOpen = (selectedVenueForRfq: any) => {
    const obj = {
      ...searchData,
      venue: selectedVenueForRfq.name,
      termsText: venueListData.rfqTerms,
      venueId: selectedVenueForRfq.id,
      eventType: eventTypes?.filter((item: any) => item.value === searchData.eventType)[0].label,
      timeSlot: timeSlots?.filter((item: any) => item.value === searchData.timeSlot)[0].label,
    };
    setRfqPageResponse(obj);
    setOpenRfqModal(true);
  };

  const rfqOnSubmit = async (e: any) => {
    e.preventDefault();
    const { getFormData } = rfqFormRef.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const bodyData = removeErrorFieldsFromValues(formData);
    const obj = {
      ...rfqPageResponse,
      name: bodyData.name,
      email: bodyData.email,
      mobile: bodyData.mobile,
      companyName: bodyData.companyName,
    };
    setRfqPageResponse(obj);
    try {
      setHasError(false);
      if (isFormValid) {
        if (!captchaRes) {
          setCaptaFlag(true);
          return false;
        }
        const body = {
          prefferedStartDate: dayjs(bodyData.startDate as string).format('DD-MM-YYYY'),
          prefferedEndDate: dayjs(bodyData.endDate as string).format('DD-MM-YYYY'),
          name: bodyData.name,
          guests: bodyData.guests,
          eventId: searchData.eventType,
          timeSlotId: searchData.timeSlot,
          email: bodyData.email,
          mobile: bodyData.mobile,
          venueId: obj.venueId,
          pricePackageId: null,
          companyName: bodyData.companyName,
          captchaResponse: {
            captchaRes: captchaRes,
          },
        };
        setLoading(true);
        const { status } = await axiosInstance.post(URLS.submitRfq, body);
        if (status === 200) {
          setLoading(false);
          onRfqModalClose();
          setMessage({
            display: true,
            severity: 'success',
            message: strings.rfqSuccessMessage,
          });
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data.message,
      });
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const getVenueDetails = async () => {
    const requestBody = {
      location: searchData.location?.map((item: any) => item.value),
      eventType: searchData.eventType,
      bookingStartDate: dayjs(searchData.startDate as string).format('DD-MM-YYYY'),
      bookingEndDate: dayjs(searchData.endDate as string).format('DD-MM-YYYY'),
      timeSlot: searchData.timeSlot,
      guest: searchData.guests,
      layouts: [],
    };
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.post(URLS.getAvailableVenues(selectedOrgId), requestBody);
      if (status === 200) {
        dispatch(setVenueList(data));
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.message,
      });
    }
  };

  useEffect(() => {
    if (searchData.eventType) getVenueDetails();
    else dispatch(setVenueList({}));
  }, [searchData]);

  useEffect(() => {
    if (venueListData?.venuesByDates?.length > 0)
      setExpanded(venueListData.venuesByDates[0].venues.length > 0 ? venueListData.venuesByDates[0].date : false);
  }, [venueListData]);

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    isExpanded && document.getElementById('date')?.scrollIntoView({ behavior: 'smooth' });
  };

  const AccordionCard = useCallback(
    ({ venue }: { venue: any }) => {
      return (
        <Card
          sx={{
            marginY: 2,
          }}>
          <Accordion expanded={expanded === venue.date} onChange={handleAccordionChange(venue.date)}>
            <AccordionSummary
              sx={{
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(90deg)',
                  translate: '0 0.25em',
                  borderBottom: `solid 0.2em ${theme.palette.primary.main}`,
                },
              }}
              expandIcon={<ArrowRightRoundedIcon sx={{ mr: 1, fontSize: '2.5em' }} color={'primary'} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
              <Typography sx={{ fontWeight: 'bold', mr: 5 }} id="date">
                {venue.date}
              </Typography>
              {venue.venues.length > 0 ? (
                expanded !== venue.date && (
                  <Typography sx={{ color: 'green' }} fontSize={'14px'} fontWeight={500}>
                    {strings.checkAvailableVenuesText}
                  </Typography>
                )
              ) : (
                <Typography sx={{ color: 'red' }} gridAutoRows={2}>
                  {strings.venuesNoAvailableText}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {venue.venues.map((venueData: any, idx: number) => {
                return (
                  <>
                    <VenueDetails venueData={venueData} date={venue.date} onRfqModalOpen={onRfqModalOpen} />
                    {idx !== venue.venues?.length - 1 ? <Divider sx={{ my: 2 }} /> : null}
                  </>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </Card>
      );
    },
    [expanded, searchData],
  );

  const handleSubmit = () => {
    const arr = selectedVenues.map((item: any) => item.venues.filter((vItem: any) => vItem.pricePackageId === null));
    const arr2 = [...new Set(arr.flat(1).map((i: any) => i.name))];
    console.log('arr: ', arr, arr2);
    if (arr2.length > 0) {
      setMessage({
        display: true,
        severity: 'error',
        message: `${strings.pricePackageRequiredText} ${arr2.join(', ')}`,
      });
    } else {
      const selectedDates = selectedVenues.map((item: any) => item.date);
      if (selectedDates.length !== venueListData.venuesByDates.length) {
        setOpenWarningModal(true);
      } else {
        handleSubmitHelper();
      }
    }
  };

  const handleSubmitHelper = async () => {
    setOpenWarningModal(false);
    if (location.search.includes('oid')) {
      navigate(`${routes.order_summary}${location.search}`);
    } else {
      navigate(routes.order_summary);
    }
  };
  const getMX = () => {
    if (screenSizeDownMd) return 3
    else if (screenSizeDownSm) return 1.5
    else return 6
  }
  const getWidth = () => {
    if (screenSizeDownMd) return '60vw'
    else if (screenSizeDownSm) return '85vw'
    else return '40vw'
  }

  return (
    <>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      {queryStringValues.oid ? null : <SearchBar />}
      {Object.keys(venueListData).length > 0 ? (
        <>
          <Box mx={getMX()} py={2}>
            {queryStringValues.oid ? null : <ViewOrgDetails venueListData={venueListData} />}
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                {venueListData.alternativeDates && venueListData.alternativeDates.length > 0 && (
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.secondary.main}`,
                      display: 'flex',
                      gap: 1,
                      backgroundColor: theme.palette.secondary.light,
                      my: 1,
                      p: 1,
                      alignItems: 'center',
                    }}>
                    <ErrorIcon color={'error'} sx={{ fontSize: '1.75em' }} />
                    <Typography fontSize={'0.8em'}>{strings.no_venue_alternate_date}</Typography>
                  </Box>
                )}
                {venueListData.hasOwnProperty('venuesByDates') &&
                  venueListData.venuesByDates.map((item: any) => <AccordionCard venue={item} key={item.id} />)}
                {venueListData.hasOwnProperty('alternativeDates') &&
                  venueListData.alternativeDates.map((item: any) => <AccordionCard venue={item} key={item.id} />)}
              </Grid>
              {screenSizeDownMd ? (
                <>
                  <Avatar
                    sx={{
                      overflow: 'visible',
                      filter: `drop-shadow(0 0 0.75rem ${theme.palette.secondary.light})`,
                      position: 'fixed',
                      bottom: 15,
                      right: 20,
                      backgroundColor: theme.palette.primary.main,
                      width: '50px',
                      height: '50px',
                    }}
                    onClick={(event: any) => setAnchorEl(event.currentTarget)}>
                    <Badge
                      badgeContent={selectedVenues.reduce((sum: any, item: any) => sum + item.venues.length, 0)}
                      color="secondary">
                      {/* // sx={{color: theme.palette.secondary.light}}> */}
                      <ShoppingCartIcon
                        sx={{
                          color: theme.palette.secondary.light,
                          fontSize: '30px',
                        }}
                      />
                    </Badge>
                  </Avatar>
                  <Popover
                    open={openPop}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      style: { width: screenSizeDownSm ? '80%' : '50%' },
                    }}>
                    <Box>
                      <SelectedVenuesComponent
                        selectedVenues={selectedVenues}
                        handleSubmit={handleSubmit}
                      />
                    </Box>
                  </Popover>
                </>
              ) : (
                <SelectedVenuesComponent
                  selectedVenues={selectedVenues}
                  handleSubmit={handleSubmit}
                />
              )}
            </Grid>
            <Modal
              open={openWarningModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: getWidth(),
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 3,
                  ':focus': { border: 0 },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <ErrorOutlineOutlinedIcon color="error" sx={{ fontSize: screenSizeDownSm ? '4em' : '5em' }} />
                <Typography variant={screenSizeDownSm ? 'h6' : 'h4'} mt={1} fontWeight={'bold'}>
                  {strings.areYouSureText}
                </Typography>
                <Typography fontSize={screenSizeDownSm ? '12px' : '16px'} mt={1}>
                  {strings.selectedDateMessage}
                </Typography>
                <Box display={'flex'} my={2} gap={1} justifyContent={'flex-end'} width={'100%'}>
                  <PrimaryButton
                    onClick={() => {
                      setOpenWarningModal(false);
                    }}
                    variant="outlined"
                    sx={{
                      ':hover': { backgroundColor: '#f7e2e6' },
                    }}>
                    {strings.cancel_text}
                  </PrimaryButton>
                  <PrimaryButton onClick={handleSubmitHelper}>{strings.okText}</PrimaryButton>
                </Box>
              </Box>
            </Modal>
          </Box>
          <Modal open={openRfqModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 0,
                width: screenSizeDownMd ? '100vw' : '70vw',
                minHeight: screenSizeDownMd ? '100vh' : '95vh',
                ':focus': { border: 0 },
              }}>
              {loading && <PageLoader />}
              <Box display={'flex'} alignItems="center" justifyContent={'space-between'} px={3} py={2}>
                <Typography id="modal-modal-title" variant="h5" fontWeight={600} color={'primary'}>
                  {strings.rfq_modal_text_title_name}
                </Typography>
                <ClearOutlinedIcon
                  onClick={onRfqModalClose}
                  sx={{
                    ':hover': { cursor: 'pointer' },
                    color: theme.palette.primary.main,
                  }}
                />
              </Box>
              <Divider
                sx={{
                  mx: 3,
                  borderStyle: 'dashed',
                  borderColor: theme.palette.secondary.main,
                }}
              />
              <Scrollbar style={{ width: '100%', height: '85vh' }}>
                <Grid container columnSpacing={4} rowSpacing={1} px={3} py={1.5}>
                  <Form
                    hasError={hasError}
                    ref={rfqFormRef as RefObject<Form>}
                    model={RfqForm(strings, venueListData?.defaultCountryCode || 'US')}
                    values={rfqPageResponse}
                  />
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox onChange={(e: any) => setTermsAgreed(e.target.checked)} />}
                      label={<Typography fontSize={'0.75rem'}>{rfqPageResponse.termsText}</Typography>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Captcha verify={verifyCallback} />
                    <Typography variant="subtitle2" color="error">
                      {captaFlag ? strings.captchaValidation : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        [theme.breakpoints.up('sm')]: {
                          display: 'flex',
                          justifyContent: 'flex-end',
                        },
                      }}
                      pb={0}>
                      <PrimaryButton disabled={!termsAgreed} onClick={(e: any) => rfqOnSubmit(e)}>
                        {strings.submitText}
                      </PrimaryButton>
                    </Box>
                  </Grid>
                </Grid>
              </Scrollbar>
            </Box>
          </Modal>
        </>
      ) : (
        <>{queryStringValues.oid ? null : <Box height={'80vh'} width={'100vw'}></Box>}</>
      )}
    </>
  );
};

export default OrganisationDetails;
